import { useEffect } from 'react';

import LandingWrapper from '../components/LandingWrapper';

const Terms = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <LandingWrapper>
            <div className="terms">
                <h1>
                    <strong>Teorem Terms of Service</strong>
                </h1>
                <h2>For Users</h2>
                <p>
                    Please read these Terms of Service (hereinafter – the “Terms”) carefully before using the Teorem Marketplace and/or Services (as
                    defined below). If you do not accept these Terms in their entirety, including the agreement to arbitrate on an individual basis
                    any claims between you and Teorem Ltd. (referred to as “Teorem”, “we” and “us”), a corporation registered and organized under
                    English law, or any of its subsidiaries, you must not access or use the Teorem Marketplace.
                </p>
                <ul>
                    <h3>1. GENERAL INFORMATION</h3>
                </ul>
                <ul>
                    <h3>1.1. Terms of Service</h3>
                </ul>
                <p>
                    These Terms outline the terms and conditions that govern your access and use of the website, located at
                    <a href={'/'}> www.teorem.co</a>, and its sub-domains (collectively referred to as the "Website"), related mobile device
                    application, and software (together with the Website, the "Teorem Marketplace”).
                </p>
                <p>
                    Your use of the Services is subject to these Terms and any other rules or policies published on the Website or otherwise provided
                    to you by Teorem.
                </p>
                <p>
                    By creating an account on the Teorem Marketplace, you understand that you are entering into a legally binding contract with
                    Teorem, effective as of the date of account creation, and indicate your unconditional acceptance of these Terms.
                </p>
                <p>
                    These Terms, as well as Refund and Payment Policy, Privacy Policy, Cookies Policy, and any service-specific terms as made
                    available in the Legal Center, are legally binding agreements governing the relationship between Teorem and any User (as defined
                    below) or visitor of the Teorem Marketplace. As a User or visitor of the Teorem Marketplace, the collection, use, and sharing of
                    your personal data are subject to the Privacy Policy and Cookie Policy, as may be amended from time to time.
                </p>
                <ul>
                    <h3>1.2. Teorem Services</h3>
                </ul>
                <p>You can utilize the Teorem Marketplace as: </p>
                <ul>
                    a) A registered user seeking online tutoring services by using the Teorem Marketplace or other tools made available by Teorem (the
                    “Student”);
                </ul>
                <ul>
                    b) A registered user providing online tutoring services via the Teorem Marketplace or other tools provided by Teorem (the
                    “Tutor”);
                </ul>
                <ul>c) A visitor of the Website.</ul>
                <p>
                    Throughout these Terms, the terms “you” or “your” may apply to the Student, the Tutor, or a visitor accessing or using any of our
                    Services (each referred to as the “User”).
                </p>
                <p>
                    Teorem services include access to the Teorem Marketplace for Students and Tutors, to connect for online teaching and tutoring
                    services, facilitation of payments between Students and Tutors, and customer support and related services (referred to as the
                    “Services”). Teorem does not provide teaching or tutoring services. All available teaching and tutoring services on the Teorem
                    Marketplace are provided according to the services’ specific terms outlined in the <a href={'/en/legal-center'}>Legal Center</a>.
                </p>
                <p>
                    You are responsible for obtaining and paying for any equipment and Internet service necessary to access the Services. We may
                    alter, suspend, or discontinue the Teorem Marketplace or the Services in whole or in part, at any time and for any reason, without
                    notice. The Teorem Marketplace may also become unavailable periodically due to maintenance or malfunction of computer equipment or
                    other reasons. We may provide access to third party services and products from time to time or to our own products or Services.
                    You acknowledge that the Teorem Marketplace is evolving and that the form and nature of the Services may change from time to time
                    without notice to you.
                </p>
                <ul>
                    <h3>1.3. Amendments to these Terms</h3>
                </ul>
                <p>
                    We reserve the right to amend or modify Terms at any time, by posting a revised version on the Website and by notifying you
                    through the Teorem Marketplace, or via the email address associated with you in case of material change hereto. The latest version
                    is published on this page.
                </p>
                <p>
                    We encourage you to check this page regularly. If you do not agree with the amendments to these Terms, you have the right to
                    terminate these Terms by discontinuing your use of the Services and providing a termination notice to Teorem or deleting your
                    account. By continuing to use the Services following the amendment of these Terms, you consent to be bound by the Terms as
                    amended.
                </p>
                <ul>
                    <h3>2. OBLIGATIONS</h3>
                </ul>
                <ul>
                    <h3>2.1. Services Eligibility</h3>
                </ul>
                <p>
                    Services are available only to and may only be used by individuals who are 18 years and older and who can form legally binding
                    contracts under applicable law. Individuals under the age of 18 can use our Services only with prior consent of the person’s legal
                    representative. The parent or the legal guardian shall at all times be responsible for any and all activities related to the use
                    of the Services by the aforementioned individuals.
                </p>
                <ul>
                    <h3>2.2. Verification</h3>
                </ul>
                <p>
                    Teorem may request a Tutor to provide a government-issued ID (passport, driver’s license, etc.) and the documents confirming the
                    claimed educational credentials (educational background documents, such as diplomas, certificates, etc.). The Tutor will be asked
                    to upload a copy of the Tutor’s government-issued ID and their educational document(s). As a Tutor passes the verification
                    procedure, a special badge is added to the Tutor’s profile, and the uploaded copies of the documents get deleted from our servers.
                </p>
                <p>
                    Teorem does not endorse or make any representations or warranties regarding the accuracy, completeness, and reliability of any
                    information provided by the Tutor within the verification procedure.
                </p>
                <p>
                    Teorem cannot confirm that each User is who they claim to be. You agree and understand that you assume all risks when using the
                    Services, including without limitation any and all of the risks associated with any online or offline interactions with other
                    Users.
                </p>
                <p>
                    When interacting with other Users, you should exercise caution and common sense to protect your personal safety and property, just
                    as you would when interacting with other people you don’t know. Neither Teorem nor its affiliates or licensors is responsible for
                    the conduct, whether online or offline, of any User of the Services. Teorem, its affiliates, and licensors will not be liable for
                    any claim, injury, or damage arising from or in connection with your use of the Services.
                </p>
                <ul>
                    <h3>2.3. Consumer Reports</h3>
                </ul>
                <p>
                    Teorem may or may not utilize third-party consumer reporting agencies that perform, among other things, criminal background
                    checks, sex offender registry checks, motor vehicle records checks, credit checks, and identification verifications (“Consumer
                    Reports”). Teorem does not endorse or make any representations or warranties regarding the reliability of such Consumer Reports or
                    the accuracy, timeliness, or completeness of any information in the Consumer Reports. Teorem does not independently verify
                    information in the Consumer reports.
                </p>
                <p>
                    You hereby consent to Teorem collecting, using, and disclosing the information in the Consumer Reports. You understand and agree
                    that Teorem may, in its sole discretion, review and rely on the information in the Consumer Reports in deciding whether to suspend
                    or terminate a User account or to investigate a complaint about a User, but that Teorem shall not be responsible or liable in any
                    way in the event that any information in the Consumer Reports about any person, including without limitation any User, is not
                    accurate, timely or complete. Users who are the subject of Consumer Reports may contact the third-party consumer reporting agency
                    to dispute such information's accuracy, timeliness, or completeness. Teorem reserves the right to suspend and/or terminate a User
                    account based on the information in the Consumer Reports or for any other reason at Teorem's sole discretion.
                </p>
                <ul>
                    <h3>2.4. Privacy</h3>
                </ul>
                <p>
                    You may use the Teorem Marketplace without providing personally identifiable information. When using the Services, you may be
                    requested to provide your personal data. To learn more about our privacy practices, please refer to
                    <a href={'/en/privacy-policy'}> Teorem Privacy Policy</a>.
                </p>
                <ul>
                    <h3>2.5. Payment</h3>
                </ul>
                <p>
                    Payment processing on the Teorem Marketplace is provided by third-party payment processors including, but not limited to
                    Braintree, PayPal, Stripe, Skrill, Payoneer, and TransferWise, allowing us to:
                </p>
                <ul>a) Bill the Students in lieu of directly processing your credit/debit card information;</ul>
                <ul>b) Enable payouts to the Tutors.</ul>
                <p>
                    For the avoidance of doubt, payment made by the Student to Teorem shall satisfy the Student’s obligation with respect to the
                    payment to the Tutor for the tutoring services provided via the Teorem Marketplace.
                </p>
                <p>
                    Please review the additional payment terms as specified in the
                    <a href={'/en/refund-and-payment-policy'}> Refund and Payment Policy</a>.
                </p>
                <p>
                    More details on the security of your payment and billing information may be found in our
                    <a href={'/en/privacy-policy'}> Privacy Policy</a>.
                </p>
                <p>
                    We use third-party payment processors Braintree, PayPal and Stripe to bill you as Students in lieu of directly processing your
                    credit/debit card information and PayPal, Skrill, Payoneer, and TransferWise to enable payouts to the Tutors. Please read more on
                    how the security of your payment and billing information is handled in our
                    <a href={'/en/privacy-policy'}> Privacy Policy</a>.
                </p>
                <p>
                    For the avoidance of doubt, payment made by the Student to Teorem shall satisfy the Student’s obligation with respect to the
                    payment to the Tutor for the tutoring services provided via the Teorem Marketplace.
                </p>
                <p>We also use third-party payment processors (PayPal, Skrill, Payoneer, TransferWise) to enable payouts to the Tutors.</p>
                <p>
                    Please check the additional payment terms specified in the
                    <a href={'/en/refund-and-payment-policy'}> Refund and Payment Policy</a>.
                </p>
                <ul>
                    <h3>2.6. Refund</h3>
                </ul>
                <p>
                    Teorem strives to ensure a clear understanding of the financial relations between Students and Tutors with respect to the Services
                    we provide. Please check our <a href={'/en/refund-and-payment-policy'}> Refund and Payment Policy </a>
                    to find out more about how we handle refunds. To the fullest extent permitted by law, any refunds at any time are at our sole
                    discretion only.
                </p>
                <ul>
                    <h3>3. RIGHTS AND LIMITS</h3>
                </ul>
                <ul>
                    <h3>3.1. Your Right to Use the Teorem Marketplace</h3>
                </ul>
                <p>
                    In case you are a Student, Teorem hereby grants you, on the Terms set forth herein, a nontransferable, non-sublicensable,
                    non-exclusive, limited right to access and use the Teorem Marketplace solely for your non-commercial personal use.
                </p>
                <p>
                    In case you are a Tutor, Teorem hereby grants you, on the Terms set forth herein, a nontransferable, non-sublicensable,
                    non-exclusive, limited right to access and use the Teorem Marketplace solely for the provision of the tutoring services to the
                    Students.
                </p>
                <p>
                    You agree not to view, copy, or procure content or information from the Teorem Marketplace by automated means (such as scripts,
                    bots, spiders, crawlers, or scrapers), or to use other data mining technology or processes to frame, mask, extract data or other
                    materials from the Teorem Marketplace (except as may be a result of the standard search engine or Internet browser usage) unless
                    formally authorized by Teorem under separate written agreement.
                </p>
                <p>
                    You agree not to inquire about the engagement of or to engage Tutors to complete assignments, write papers, take quizzes, or
                    otherwise do work on your behalf. Further, you agree not to use the Services for any purpose that violates the academic honesty
                    policy or other conduct policies of your school, university, academic institution, or workplace.
                </p>
                <p>
                    No Teorem materials made available to you as part of the Services may be copied, reproduced, modified, republished, downloaded,
                    uploaded, posted, transmitted, or distributed in any form or by any means without Teorem’s prior written permission or as
                    expressly provided in these Terms.
                </p>
                <p>You may not share or transfer your account credentials with any third party.</p>
                <p>
                    Teorem may impose reasonable limits on your scope of access to the Teorem Marketplace, including limits on time or volume of
                    information accessed or devices used to access the Teorem Marketplace, to prevent unauthorized third-party use of the Services.
                </p>
                <p>All rights not expressly granted herein are reserved.</p>
                <ul>
                    <h3>3.2. Your Account</h3>
                </ul>
                <p>
                    Certain of our Services are reserved for registered users only. To become a registered user, you must create a Student or Tutor
                    account on the Teorem Marketplace. You agree that you are responsible for protecting your account credentials from unauthorized
                    use, and you are responsible for all activity that occurs under those account credentials. You agree to notify us immediately if
                    you believe that any of your account credentials have been or may be used without your permission so that appropriate action can
                    be taken.
                </p>
                <p>
                    You may use the Website and its features without being a registered user. This shall not preclude the application of these Terms
                    and other Teorem’s rules and policies applicable to your use of the Website, including but not limited to when you interact with
                    the Website.
                </p>
                <p>You may not:</p>
                <ul>a) Create more than two accounts (one as a Tutor and one as either a Parent or Student) to access the Teorem Marketplace,</ul>
                <ul>b) Share your account credentials with any third party, or</ul>
                <ul>c) Transfer your account to any third party.</ul>
                <p>
                    Teorem is not responsible for any loss or damage caused by or expense incurred by you as a result of your failure to safeguard
                    your account credentials. You agree that you shall not rent, resell, or remarket the Teorem Marketplace or provide access to the
                    Services to any third party.
                </p>
                <p>
                    When you create an account on Teorem, we may collect certain personal data directly from you, or if you create your account using
                    a third-party service (Facebook, Google), we may collect personal data about you from the third-party service (your username or
                    user ID associated with that third-party service). By choosing to create an account using a third-party service, you authorize us
                    to collect the personal data necessary to authenticate your account with the third-party service provider.
                </p>
                <p>
                    You may select a profile photo or connect your Google or Facebook account to be displayed within your Student account. Please be
                    advised that the provision of your photo is not obligatory to use our Services. You may edit your account at your sole discretion.
                    The security of any personal data you choose to share within your account is subject to our{' '}
                    <a href={'/en/privacy-policy'}> Privacy Policy</a>.
                </p>
                <p>When you create a Tutor account, it is obligatory to provide your profile photo and the video introduction.</p>
                <p>
                    You may edit your account at your sole discretion. Adding more details to your Tutor account may help you get the most out of your
                    use of the Services, for instance, find more Students. Therefore, it is your choice whether to include additional information
                    within your account or not, such as skills, education, and work experience. The security of any personal data you choose to share
                    within your account is subject to our <a href={'/en/privacy-policy'}> Privacy Policy</a>.
                </p>
                <ul>
                    <h3>3.3. Direct Interactions</h3>
                </ul>
                <p>Teorem does not take part in direct interactions between Students and Tutors except when we consider it advisable:</p>
                <ul>a) To ensure compliance with these Terms;</ul>
                <ul>b) To improve our Services; or</ul>
                <ul>
                    c) As stated in our <a href={'/en/refund-and-payment-policy'}> Refund and Payment Policy</a>.
                </ul>
                <p>
                    Teorem does not have control over the services provided by the Tutors, any reviews or ratings provided by the Students, or any
                    User acts and omissions.
                </p>
                <ul>
                    <h3>3.4. Representations and Warranties</h3>
                </ul>
                <p>
                    <b>Tutor-Specific Representations</b>
                </p>
                <p>If you use the Services as a Tutor: </p>
                <ul>
                    a) you will provide the tutoring services in accordance with these Terms and other policies set forth by Teorem and made available
                    to you via email or by posting on the Website;
                </ul>
                <ul>
                    b) you will provide the tutoring services in accordance with laws and regulations applicable in the state or country where you are
                    providing the tutoring services;
                </ul>
                <ul>
                    c) you are solely responsible and fully liable for any violation of any local laws and regulations that apply to your provision of
                    the tutoring services;
                </ul>
                <ul>
                    d) you will provide the tutoring services with reasonable care and skill and in accordance with generally recognized practices;
                </ul>
                <ul>
                    e) you have obtained all registrations, certifications, licenses, and other documentation that are required in the applicable
                    jurisdiction for providing the tutoring services. It is your obligation to maintain the validity of all aforementioned
                    documentation;
                </ul>
                <ul>
                    f) you will not provide tutoring services to the Students outside of the Teorem Marketplace, receive payments from the Students
                    directly, or encourage or solicit payment from the Student directly or through any channels other than those provided by Teorem;
                </ul>
                <ul>
                    g) you acknowledge and agree that Teorem may advertise tutoring services that you provide via the Teorem Marketplace without any
                    additional payment or obligation to you;
                </ul>
                <ul>
                    h) you acknowledge and agree that Teorem may improve the video you provided for your account. The improvements can be made by
                    editing the video, adding the Teorem logo to the video, improving the quality of sound in the video, and publishing the video on
                    Teorem social media accounts with adding the description and link to the Tutor’s profile.
                </ul>
                <p>
                    <b>Student-Specific Representations</b>
                </p>
                <p>If you use the Services as a Student: </p>
                <ul>a) you agree to honor the commitments you make to the Tutor via the Teorem Marketplace;</ul>
                <ul>
                    b) you agree that you will not circumvent or manipulate the fee structure, the billing process, or fees owed to Teorem or the
                    Tutor; and
                </ul>
                <ul>c) you agree to use good faith efforts to interact with the Tutors.</ul>
                <ul>
                    <h3>3.5. Tutors’ Introduction Video and Profile Photo</h3>
                </ul>
                <p>
                    Tutors grant Teorem the rights to use Tutor’s introduction video, name, and profile photo for marketing, advertising, or
                    promotional purposes, including but not limited to publishing on social media channels, video hosting, and streaming services,
                    such as YouTube, Vimeo, Facebook, or others, as to ensure accessibility and visibility to the Students.
                </p>
                <p>
                    You may always request to remove any introduction videos published on social media channels, video hosting, and streaming services
                    by writing to <a href={'mailto:support@teorem.co'}>support@teorem.co</a>.
                </p>
                <ul>
                    <h3>3.6. Tutors’ Ranking</h3>
                </ul>
                <p>
                    Teorem uses many pieces of information in order to show the Student the Tutors that best match the Student’s preferences. The
                    order in which Tutors are displayed depends on a number of factors including, but not limited to, the following:
                </p>
                <ul>
                    <li>The Subject the Student is searching for;</li>
                    <li>The country of the Student;</li>
                    <li>The time zone of the Student;</li>
                    <li>The overlap in standard business hours between the Tutor and Student’s time zones;</li>
                    <li>The overall availability of time slots on a Tutor’s calendar;</li>
                    <li>The past performance of a Tutor in helping their Students achieve their learning goals;</li>
                    <li>The Tutor profile information, including its completeness and the quality of the profile photo and introduction video;</li>
                    <li>How responsive the Tutor is to the Students’ messages;</li>
                    <li>The Student reviews and ratings;</li>
                    <li>Student’s learning history on the Teorem Marketplace</li>
                </ul>
                <ul>
                    <h3>3.7. User Complaints</h3>
                </ul>
                <p>
                    If a User has a complaint about Services, they should contact Teorem at <a href={'mailto:support@teorem.co'}>support@teorem.co</a>
                    with the subject line ‘Formal Complaint’ (the “Complaint”), providing as much detail as possible about the Complaint. Teorem shall
                    respond to the User confirming receipt and shall investigate the matter. Upon receiving the Complaint, Teorem customer support
                    shall investigate the Complaint internally, taking into account the importance and complexity of the issue or issues raised.
                </p>
                <p>
                    If the Complaint relates to a specific Teorem employee, another Teorem representative will help with the investigation in their
                    place.
                </p>
                <p>
                    Teorem shall respond to the User with its findings in response to the Complaint, and, where applicable, with a suggested solution.
                </p>
                <ul>
                    <h3>3.8. Lesson Recording</h3>
                </ul>
                <p>
                    You acknowledge and agree that, from time to time, Teorem may record videos of your lessons. This recording is intended solely for
                    the purpose of ensuring the quality of the Services. Recordings will not be shared externally, used for individual targeting, or
                    used for any sales, marketing, or other promotional purposes.
                </p>
                <p>
                    By using the Services, you agree and give consent for such recordings that is gratuitous, unsolicited, and without restriction.
                    You agree that Teorem may use these recordings without fiduciary or other obligation and without any additional compensation.
                </p>
                <ul>
                    <h3>3.9. Limits</h3>
                </ul>
                <p>
                    While using the Services, you agree to abide by the <a href={'/en/user-code-of-conduct'}>User Code of Conduct</a>.
                </p>
                <ul>
                    <h3>4. TEOREM CONTENT</h3>
                </ul>
                <p>
                    Users have a personal, non-transferable, non-exclusive right to use the Teorem Content of the Teorem Marketplace subject to these
                    Terms. The “Teorem Content” means all information, text, images, data, links, or other material accessible through the Teorem
                    Marketplace, whether created by us or provided by a third party on or through the Teorem Marketplace. The Content may contain
                    typographical errors, other inadvertent errors, or inaccuracies. We reserve the right to make changes to the Teorem Content
                    without obligation to issue any notice of such changes. You may view, copy, download, and print the Teorem Content that is
                    available on or through the Teorem Marketplace, subject to the following conditions:
                </p>
                <ul>
                    a) The Teorem Content is available solely for your personal use. No part of the Teorem Marketplace or the Teorem Content may be
                    reproduced or transmitted in any form, by any means, electronic or mechanical, including photocopying and recording for any other
                    purpose;
                </ul>
                <ul>b) The Teorem Content may not be modified;</ul>
                <ul>c) Copyright, trademark, and other proprietary notices may not be removed.</ul>
                <p>
                    Nothing contained on the Teorem Platform should be construed as granting, by implication, estoppel, or otherwise, any license or
                    right to use the Teorem Platform or any Teorem Content, except: (a) as expressly permitted by these Terms; or (b) with our prior
                    written permission or the permission of such third party that may own the trademark or copyright of the Teorem Content.
                </p>
                <ul>
                    <h3>5. USER-GENERATED CONTENT</h3>
                </ul>
                <p>
                    The <b>“User-Generated Content”</b> means all comments, remarks, information, feedback, text, photographs, links, data, images,
                    video, music, or other material that you or any User post to any part of the Teorem Marketplace or provide to Teorem, including
                    such content or information that is posted as a result of surveys.
                </p>
                <p>
                    We are not responsible or liable for the conduct of Users or for views, opinions, and statements expressed in the User-generated
                    Content submitted for public display through the Teorem Marketplace. We do not prescreen information posted online. We are acting
                    as a passive conduit for such distribution and may not be responsible for the User-generated Content. Any opinions, advice,
                    statements, services, offers, or other information in the User-generated Content expressed or made available by Users are those of
                    the respective author(s) or distributor(s) and not of Teorem. We neither endorse nor guarantee the accuracy, completeness, or
                    usefulness of any such User-generated Content. You are responsible for ensuring that the User-generated Content submitted to the
                    Teorem Marketplace is not provided in violation of any copyright, trade secret, or other intellectual property rights of another
                    person or entity. You shall be solely liable for any damages resulting from any infringement of copyrights, trade secrets, or
                    other intellectual property rights, or any other harm resulting from your use, uploading, posting, or submission of the
                    User-Generated Content to the Teorem Marketplace.
                </p>
                <p>
                    We have the right, but not the obligation, to randomly monitor, edit or remove any User-Generated Content submitted on or through
                    the Teorem Marketplace at any time.
                </p>
                <p>
                    If you believe that your intellectual property rights have been infringed, please submit your complaint to{' '}
                    <a href={'mailto:legal@teorem.co'}>legal@teorem.co</a>. You may report all types of intellectual property claims, including, but
                    not limited to, copyright, trademark, and patent claims. We respond quickly to the concerns of rights owners about any alleged
                    infringement, and we terminate repeat infringers in appropriate circumstances.
                </p>
                <ul>
                    <h3>6. LINKS TO THIRD-PARTY WEBSITES</h3>
                </ul>
                <p>
                    The Teorem Marketplace may contain links to non-Teorem websites. These links are provided to you as a convenience and/or ancillary
                    to the Services, and Teorem is not responsible for the content of any linked website. Any non-Teorem website accessed from the
                    Teorem Platform is independent from Teorem, and Teorem has no control over that website’s content. In addition, a link to any
                    non-Teorem website does not imply that Teorem accepts any responsibility for the content or use of such a website. Use of any
                    third-party website is subject to its terms of service and privacy policy. We request that the Users exercise caution and good
                    judgment when using third-party websites.
                </p>
                <ul>
                    <h3>7. ADVERTISEMENT</h3>
                </ul>
                <p>
                    Teorem may run advertisements and promotions sponsored by third parties on the Teorem Marketplace. Your correspondence or business
                    dealings with, or participation in promotions of advertisers other than Teorem found on or through the Services, including payment
                    and delivery of related goods or services, and any other terms, conditions, warranties, or representations associated with such
                    dealings, are solely between you and such advertiser. Teorem is not responsible or liable for any loss or damage of any sort
                    incurred as the result of any such dealings or as the result of the presence of such non-Teorem advertisers on the Teorem
                    Platform.
                </p>
                <p>
                    Teorem may display advertisements on non-Teorem websites to promote the tutoring services you provide via the Teorem Marketplace
                    and help you generate more Student leads.
                </p>
                <ul>
                    <h3>8. NO IMPLIED ENDORSEMENTS</h3>
                </ul>
                <p>
                    In no event shall any reference to any third party or third-party product or service be construed as an approval or endorsement by
                    Teorem of that third party or of any product or service provided by a third party. Teorem does not endorse, warrant, or guarantee
                    any product or service offered by any third party and will not be a party to or in any way monitor any transaction involving any
                    third-party providers of products or services. As with the purchase of a product or service through any medium or in any
                    environment, you are responsible for exercising caution and good judgment.
                </p>
                <ul>
                    <h3>9. RELATIONS BETWEEN TEOREM AND USERS</h3>
                </ul>
                <p>
                    In providing the Services, Teorem is acting as an on-demand intermediary connecting Students and Tutors and providing the tools to
                    facilitate the tutoring services. You acknowledge and agree that Teorem is a technology services provider that does not provide
                    online tutoring services.
                </p>
                <p>
                    Teorem does not serve as an employer of any User unless separately subject to a signed, written employment contract signed by both
                    the User and Teorem. Users may use the Services only for the provision and receipt of the tutoring services subject to these
                    Terms.
                </p>
                <p>
                    As such, Teorem will not be liable for any tax or withholding, including but not limited to unemployment insurance, employer’s
                    liability, workers’ compensation insurance, social security, or payroll withholding tax in connection with your use of Services.
                    You are solely responsible for adhering to all applicable tax regulations that may apply in connection with your use of the
                    Services. You hereby agree to compensate Teorem for all state fees, claims, payments, fines, or other tax liabilities that Teorem
                    will incur in connection with the obligations arising from applicable tax or other regulations not being met by you.
                </p>
                <p>
                    In all cases of use of the Teorem Marketplace, Users are acting as independent contractors and not as Teorem’s or any other
                    party’s employee, agent, franchisee, or servant. Accordingly, you will be solely responsible for all costs incurred by you or your
                    organization. You may not act as an employee, agent, or representative of Teorem nor bind any contract on behalf of Teorem. Where,
                    by implication of mandatory law or otherwise, you shall be deemed an employee of Teorem, you hereby agree to waive any claims
                    against us that may arise as a result of a such implied employment relationship. No User is entitled to participate in any Teorem
                    vacation, group medical or life insurance, disability, profit sharing or retirement benefits, or any other fringe benefits or
                    benefit plans offered by Teorem to its employees.
                </p>
                <p>
                    The Services provide connection to Tutors who are willing to be engaged by Students as independent contractors. As independent
                    contractors, each Tutor decides when and how often the Tutor will be available to provide the tutoring services to Students. Each
                    Tutor controls the methods, materials, content, and all aspects of the tutoring services. The Services allow Tutors to create
                    subject-specific tutoring services, for a certain number of hours.
                </p>
                <p>
                    Students are responsible for selecting the Tutor suitable for their learning goals. Students should check each Tutor's
                    self-reported credentials, education, and experience, as well as reviews from other Students. Each Tutor has the sole discretion
                    to accept or decline a request for the tutoring services, as well as continue or discontinue a tutoring relationship with any
                    Student.
                </p>
                <p>
                    Tutors may and, in fact are expected to perform the tutoring services for others or do other types of work (either as an
                    independent contractor or employee or other) while these Terms are in effect, including with Teorem’s competitors if desired,
                    provided that such other activities do not result in the Tutor’s violation of the Terms.
                </p>
                <ul>
                    <h3>10. ASSIGNMENT</h3>
                </ul>
                <p>
                    You may not assign or transfer your rights or obligations under these Terms in whole or in part to any third party without
                    Teorem’s written consent. These Terms shall be binding and inure to the benefit of the parties to these Terms and their respective
                    successors, permitted transferees, and assigns.
                </p>
                <ul>
                    <h3>11. FEEDBACK</h3>
                </ul>
                <p>
                    You acknowledge and agree that we may provide you with a mechanism to provide feedback, suggestions, and ideas about the Services
                    or the Teorem Marketplace (the “Feedback”).
                </p>
                <p>
                    By submitting any Feedback, you provide us with written consent to use your Feedback for the improvement and promotion of the
                    Services. You agree that submitting a Feedback is gratuitous, unsolicited, and without restriction and will not place us under any
                    fiduciary or other obligation and that we are free to use the Feedback without any additional compensation to you and/or to
                    disclose the Feedback on a non-confidential basis or otherwise to anyone.
                </p>
                <p>
                    You further acknowledge that, by accepting your Feedback, Teorem does not waive any rights to use similar or related ideas
                    previously known to Teorem, or developed by its employees, or obtained from sources other than you. You agree that we may, in our
                    sole discretion, use the Feedback you provide to us in any way, including in future enhancement and modifications to the Services.
                    You hereby grant to us and our assigns a perpetual, worldwide, fully transferable, sublicensable, irrevocable, royalty-free
                    license to use, reproduce, modify, create derivative works from, distribute, and display the Feedback in any manner for any
                    purpose, or without it in any media, software, or technology of any kind now existing or developed in the future, without any
                    obligation to provide attribution or compensation to you or any third party.
                </p>
                <ul>
                    <h3>12. REVIEWS</h3>
                </ul>
                <p>
                    You acknowledge and agree that Teorem may calculate a composite rating based on comments and reviews left by other Users. Tutors
                    agree to be rated by Students along several criteria, as suggested by Teorem. Teorem provides its automatic feedback and rating
                    system as a means through which Users can express their opinions publicly, and Teorem does not monitor or censor these opinions or
                    investigate any remarks posted by Users for accuracy or reliability unless a User brings the posting to Teorem's attention. You
                    may be held legally responsible for damages suffered by other Users or third parties as a result of these remarks if a court finds
                    that these remarks are legally actionable or defamatory. Teorem is not legally responsible for any feedback or comments posted or
                    made available on the Teorem Marketplace by any Users or third parties, even if that information is defamatory or otherwise
                    legally actionable. You agree to report violations or abuses of our rating and feedback system immediately by contacting Customer
                    Support.
                </p>
                <ul>
                    <h3>13. NOTIFICATIONS</h3>
                </ul>
                <p>
                    Unless you otherwise indicate in writing, Teorem will communicate with you by email, regular mail or by posting communications on
                    the Teorem Marketplace. You consent to receive communications from us electronically, and you agree that these electronic
                    communications satisfy any legal requirement that such communications be in writing. You will be considered to have received a
                    communication when we send it to the email address you have provided to Teorem within your account or when we post such
                    communication on the Teorem Marketplace. You should keep your email address updated in your account and regularly check this
                    Website for postings. If you fail to respond to an email message from Teorem regarding the violation, dispute, or complaint within
                    2 (two) business days, we will have the right to terminate or suspend your account. All notices to Teorem intended to have a legal
                    effect concerning these Terms must be in writing and delivered either in person or by means evidenced by a delivery receipt to the
                    following address: Masarykova street 25, Zagreb, 10000, Croatia.
                </p>
                <p>
                    To stop receiving specific communications from Teorem, please, submit a notification to us by email at
                    <a href={'mailto:support@teorem.co'}> support@teorem.co</a> in order to change the types and frequency of such communications. You
                    may also change notification preferences in your account.
                </p>
                <ul>
                    <h3>14. TERMINATION</h3>
                </ul>
                <p>
                    Other than Tutors, we may terminate any User’s access to the Teorem Marketplace in our sole discretion, for any reason and at any
                    time, with or without prior notice. It is our right to terminate Users who violate these Terms, as deemed appropriate in our sole
                    discretion.
                </p>
                <p>
                    We may terminate a Tutor’s use of the Services (i) immediately for failure to comply with the Terms, including User Code of
                    Conduct, which considers a material breach of the agreement; (ii) for other cause, including, but not limited to, sexual or other
                    unwelcome harassment, threats or intimidation, fraud, falsification of documents or qualifications; or (iii) upon 30 days’ advance
                    written notice for any reason.
                </p>
                <p>
                    We may also delete or restrict access to or use of all related information and files. Teorem will not be liable to Users or any
                    third party for any modification, suspension, or termination of the Service, or loss of related information.
                </p>
                <p>
                    In case Teorem suspends or terminates your account due to the breach of these Terms or any of Teorem policies, you understand and
                    agree that you shall receive no refund or compensation for any unused funds or scheduled lessons/classes, or loss of any content
                    or information associated with your account. In addition to the aforementioned, Teorem is entitled to withhold any funds remaining
                    on your account as liquidated damages.
                </p>
                <p>
                    In case you haven’t logged into your Teorem account for more than 180 days your account will be suspended, and your remaining
                    balance will expire.
                </p>
                <p>Even after your right to use the Services has been terminated or suspended, these Terms will remain enforceable against you.</p>
                <ul>
                    <h3>15. INTELLECTUAL PROPERTY RIGHTS</h3>
                </ul>
                <p>
                    All intellectual property in the design and layout of the Teorem Marketplace and the material and information published on the
                    Website pages or within the Teorem Marketplace functionality belongs to and is vested in Teorem or its licensors. You may not copy
                    any part of the Teorem Marketplace or otherwise do anything in relation to any part of the Teorem Marketplace. You may not
                    otherwise use or reproduce any of the Teorem Marketplace or the material contained within it in any manner other than those listed
                    above without first obtaining the prior written permission of Teorem.
                </p>
                <p>
                    Unless otherwise noted, all Teorem Content contained on the Teorem Marketplace is the property of Teorem and/or its affiliates or
                    licensors and is protected from unauthorized copying and dissemination by United Kingdom copyright law, trademark law,
                    international conventions, and other intellectual property laws. The service marks and trademarks of Teorem, including without
                    limitation Teorem and the Teorem logos are service marks owned by Teorem Ltd. Any other trademarks, service marks, logos, and/or
                    trade names appearing via the Service are the property of their respective owners. You may not copy or use any of these marks,
                    logos, or trade names without the express prior written consent of the owner.
                </p>
                <p>
                    You may not link or frame to any pages of the Website or any Teorem Content contained therein, whether in whole or in part,
                    without prior written consent from Teorem. You may like or follow Teorem or share links to the Website via social networking
                    technology referenced on the Website. Any rights not expressly granted herein are reserved.
                </p>
                <ul>
                    <h3>16. CONFIDENTIALITY</h3>
                </ul>
                <p>
                    You may obtain direct access via the Services to certain confidential information of Teorem, its affiliates or Users, including
                    but not limited to personally identifiable information, technical, contractual, product, program, pricing, marketing and other
                    valuable information that should reasonably be understood as confidential (“Confidential Information”). You agree to hold
                    Confidential Information in strict confidence and not use the Confidential Information except for the purposes set forth in these
                    Terms and not disclose such Confidential Information to any third party. All right, title and interest in the Confidential
                    Information remains with Teorem, its affiliates and its Users. No obligation is imposed upon you with respect to Confidential
                    Information that you can establish by legally sufficient evidence: (a) you possessed prior to your receipt from Teorem, without an
                    obligation to maintain its confidentiality; (b) is or becomes generally known to the public through no act or omission by you, or
                    otherwise without violation of the Terms; (c) you obtained from a third party who had the right to disclose it, without an
                    obligation to keep such information confidential; (d) you independently developed without the use of Confidential Information and
                    without the participation of individuals who have had access to it, or (e) is disclosed in response to a valid order by a court or
                    other governmental body, or as otherwise required by law, or as necessary to establish the rights of either party under these
                    Terms and as disclosed after prior notice to Teorem adequate to afford Teorem the opportunity to object to the disclosure.
                </p>
                <ul>
                    <h3>17. DISCLAIMER OF WARRANTY</h3>
                </ul>
                <p>
                    Use of the Services is entirely at your own risk. Teorem disclaims all liability in connection with any interactions,
                    correspondence, transactions, and other dealings that you have with any third parties, including without limitation Students or
                    Tutors found on or through the Teorem Marketplace (including on or via linked websites or advertisements) are solely between you
                    and the third party (including issues related to the content of third-party advertisements, payments, services, delivery of goods,
                    warranties (including product warranties), privacy and data security, and the like). Under no circumstances will we be liable for
                    any loss or damage caused by your reliance on the information in any content on the Teorem Marketplace. It is your responsibility
                    to evaluate the accuracy, completeness, or usefulness of any information, opinion, advice, or other content available through the
                    Teorem Marketplace.
                </p>
                <p>
                    You acknowledge that the Teorem Marketplace and all Services, text, images, and other information on or accessible from the Teorem
                    Marketplace are provided "as is" and are based in part on listings provided by Tutors, which are not verified by Teorem, and that
                    any tutoring services, listings or other content acquired through the use of the Teorem Marketplace are at your sole risk and
                    discretion. Teorem and its affiliates and licensors are not liable or responsible for any results generated through the use of the
                    Services. We provide no warranty of any kind, either express or implied, including but not limited to the implied warranties of
                    merchantability, fitness for a particular purpose, and non-infringement. Specifically, but without limitation, Teorem does not
                    warrant that: (i) the information available on the Teorem Marketplace is free of errors; (ii) the functions or services (including
                    but not limited to mechanisms for the downloading and uploading of content) provided by the Teorem Marketplace will be
                    uninterrupted, secure, or free of errors; (iii) defects will be corrected, or (iv) the Teorem Marketplace or the server(s) that
                    makes it available are free of viruses or other harmful components. Neither Teorem nor its affiliates or licensors is responsible
                    for the conduct, whether online or offline, between Users.
                </p>
                <p>
                    In addition, notwithstanding any feature a Student may use to expedite Tutor selection, each Student is responsible for selecting
                    their Tutor and negotiating a contract, and Teorem does not warrant any goods or tutoring services purchased by a Student and does
                    not recommend any particular Tutor. Teorem does not provide any warranties or guarantees regarding any Tutor’s professional
                    accreditation, registration, or license.
                </p>
                <p>
                    Teorem expressly disclaims any liability or claims that may arise between Users of its Services. You are solely responsible for
                    your interactions with all other Users and any disputes that arise from those interactions with other Users. Teorem is not obliged
                    but may attempt to assist in resolving disputes between Users.
                </p>
                <ul>
                    <h3>18. LIMITATION OF LIABILITY</h3>
                </ul>
                <p>
                    a) Disclaimer. In no event shall Teorem be liable to any User of the Teorem Marketplace or any other person or entity for any
                    direct, indirect, special, incidental, consequential, or exemplary damages (including, but not limited to, damages for loss of
                    profits, loss of data, loss of use, or costs of obtaining substitute goods or services) arising out of use, inability to use,
                    unauthorized access to or use or misuse of the Teorem Marketplace or any information contained thereon, whether based upon
                    warranty, contract, tort (including negligence) or otherwise, even if has been advised of the possibility of such damages or
                    losses.
                </p>
                <p>
                    b) Limitation. You agree that Teorem’s total cumulative liability in connection with these Terms, the Teorem Marketplace, the
                    Services, the Teorem Content, or any listing or services whether in contract, tort, or otherwise, shall not exceed the amounts, if
                    any, you paid to Teorem for the Services in the then-prior three months.
                </p>
                <p>
                    c) Waiver of Class Action. Any claims brought by you or Teorem must be brought in that party’s individual capacity, and not as a
                    plaintiff or class member in any purported class or representative proceeding.
                </p>
                <ul>
                    <h3>19. EXCLUSIONS</h3>
                </ul>
                <p>
                    Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or
                    consequential damages. Accordingly, some of the above limitations may not apply to you.
                </p>
                <ul>
                    <h3>20. INTERNATIONAL</h3>
                </ul>
                <p>
                    The Teorem Marketplace may be accessed from countries other than the United Kingdom. The Teorem Marketplace and the Services may
                    contain products or references to products that are only available within the United Kingdom. Any such references do not imply
                    that such products will be made available outside the United Kingdom.
                </p>
                <p>
                    If you access and use the Teorem Marketplace outside the United Kingdom, you are responsible for complying with all applicable
                    local laws and regulations.
                </p>
                <p>
                    We make no representation that information on the Teorem Marketplace is appropriate or available for use outside the United
                    Kingdom. Those who choose to access the Teorem Marketplace from outside the United Kingdom do so on their own initiative and at
                    their own risk.
                </p>
                <ul>
                    <h3>21. LIQUIDATED DAMAGES</h3>
                </ul>
                <p>
                    Teorem and a User hereto acknowledge and agree that the funds that may be withheld under Section 14 of these Terms shall
                    constitute liquidated damages and not penalties and are in addition to all other rights of Teorem in case of the breach of these
                    Terms. Teorem and a User further acknowledge that (i) the amount of loss or damages likely to be incurred is incapable or is
                    difficult to precisely estimate, (ii) the amounts specified in the abovementioned section bear a reasonable relationship to, and
                    are not plainly or grossly disproportionate to, the probable loss likely to be incurred in connection with any material breach of
                    the agreement by a User (iii) one of the reasons for Teorem and a User reaching an agreement as to such amounts was the
                    uncertainty and cost of litigation regarding the question of actual damages.
                </p>
                <ul>
                    <h3>22. INDEMNIFICATION</h3>
                </ul>
                <p>
                    By using the Services, you agree to indemnify, hold harmless and defend Teorem and its subsidiaries, affiliates, shareholders,
                    officers, directors, agents, licensors, suppliers, other partners, employees, and representatives from any claims, damages,
                    losses, liabilities, and all costs and expenses of defense, including but not limited to, attorneys' fees, resulting directly or
                    indirectly from a claim by a third party that arises in connection with your use of the Services, including but not limited to (a)
                    acts and/or omissions on or off the Teorem Marketplace; (b) violation of any rights of another, including without limitation any
                    alleged infringement of intellectual property or other right of any person or entity relating to the Teorem Marketplace; (c)
                    breach of these Terms; (d) disputes with or between other Users; (e) use and/or misuse of the Teorem Marketplace, including
                    without limitation any information, content and/or materials thereon; (f) violation of any applicable law or regulation; (g)
                    inaccurate, untimely, incomplete or misleading User information, including without limitation with respect to registration,
                    profile or eligibility; (h) misstatements and/or misrepresentations; (i) use of links to third party websites, including without
                    limitation such websites' availability, terms of use, privacy policy, information, content, materials, advertising, products
                    and/or services; (j) User information and any acts or omissions with respect to such User information; (k) use of any information
                    in third-party reports; (l) use of third-party payment processors; and/or (m) use of any services or products or any contracts or
                    arrangements made or provided based on information, content and/or materials obtained on or through the Teorem Marketplace. You
                    further agree that you will cooperate as requested by Teorem in defense of such claims. Teorem reserves the right, at its own
                    expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by Users, and you shall
                    not, in any event, settle any claim or matter on behalf of Teorem without the written consent of Teorem.
                </p>
                <p>
                    In the event that you have a dispute with any User, you hereby release Teorem (and its officers, directors, agents, investors,
                    subsidiaries, employees, contractors, and any other third parties related to the Services) from any and all claims, demands, or
                    damages (actual or consequential) of every kind, known and unknown, arising out of or in any way related with such disputes.
                </p>
                <ul>
                    <h3>23. LAW AND JURISDICTION</h3>
                </ul>
                <p>
                    These Terms and Conditions, and the relationship between you and Us (whether contractual or otherwise) shall be governed by, and
                    construed in accordance with, English law. If you are a consumer, you will benefit from any mandatory provisions of the law in
                    your country of residence. If you are a consumer, any dispute, controversy, proceedings, or claim between you and Us relating to
                    these Terms and Conditions or to the relationship between you and Us (whether contractual or otherwise) shall be subject to the
                    jurisdiction of the courts of England, Wales, Scotland, or Northern Ireland, as determined by your residency. If you are a
                    business user, any dispute, controversy, proceedings, or claim between you and Us relating to these Terms and Conditions or to the
                    relationship between you and Us (whether contractual or otherwise) shall be subject to the exclusive jurisdiction of the courts of
                    England and Wales.
                </p>
                <ul>
                    <h3>24. NON-SOLICITATION</h3>
                </ul>
                <p>
                    Without limitation, the Services may not be used to solicit for any other business, website, or service. You may not solicit,
                    advertise for, or contact in any form Users for employment, contracting, or any other purpose not related to the Service
                    facilitated through Teorem without express written permission from Teorem.
                </p>
                <p>
                    You may not use the Service to collect usernames and/or email addresses of Users by electronic or other means without the express
                    prior written consent of Teorem.
                </p>
                <ul>
                    <h3>25. HEADINGS </h3>
                </ul>
                <p>
                    The headings and captions used in these Terms are used for convenience only and are not to be considered in construing or
                    interpreting these Terms.
                </p>
                <ul>
                    <h3>26. ENTIRE AGREEMENT SEVERABILITY</h3>
                </ul>
                <p>
                    These Terms, together with any amendments and any additional agreements you may enter into with Teorem in connection with the
                    Services, shall constitute the entire agreement between you and Teorem concerning the Services. If any part of these Terms is held
                    to be unlawful, void, or unenforceable, that part will be deemed severable and shall not affect the validity and enforceability of
                    the remaining provisions.
                </p>
                <ul>
                    <h3>27. CONTACTS</h3>
                </ul>
                <p>
                    For additional information and in case you have any questions about these Terms, please contact
                    <a href={'mailto:support@teorem.co'}> support@teorem.co</a>.
                </p>
            </div>
        </LandingWrapper>
    );
};

export default Terms;
