import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { components, SingleValue, StylesConfig } from 'react-select';

import ITutorItem, { getTutorShowcase, ITutorShowcase } from '../../../store/slices/tutor/tutorService';
import { PricingTutorCard } from './PricingTutorCard';
import { PricingTutorCardMobile } from './PricingTutorCardMobile';

interface Option {
    value: string;
    label: string;
}

interface Props {
    data: ITutorShowcase[];
}

export const TutorsContainer = (props: Props) => {
    const { t } = useTranslation();
    const { data } = props;
    const [key, setKey] = useState<number>(0);
    const [autoChange, setAutoChange] = useState(true);
    const [options, setOptions] = useState<Option[]>([]);
    const [selectedSubject, setSelectedSubject] = useState<string>('');
    const [tutorMap, setTutorMap] = useState<Map<string, ITutorItem[]>>();
    const [firstTime, setFirstTime] = useState(true);
    const isMobile = window.innerWidth < 766;

    useEffect(() => {
        if (options.length > 0) {
            let interval: NodeJS.Timeout;
            if (firstTime) {
                setSelectedSubject((prevSelected) => {
                    const currentIndex = (options.findIndex((option) => option.value === prevSelected) + 1) % options.length;
                    setKey(currentIndex); // This will trigger the animation
                    return options[currentIndex].value;
                });
                setFirstTime(false);
            }
            if (autoChange) {
                interval = setInterval(() => {
                    setSelectedSubject((prevSelected) => {
                        const currentIndex = (options.findIndex((option) => option.value === prevSelected) + 1) % options.length;
                        setKey(currentIndex); // This will trigger the animation
                        return options[currentIndex].value;
                    });
                }, 8000);
            }
            return () => {
                if (interval) {
                    clearInterval(interval);
                }
            };
        }
    }, [autoChange, options]);

    function fetch() {
        const resultMap: Map<string, ITutorItem[]> = new Map();
        const parsedOptions: Option[] = data.map((item) => ({ value: item.subject.id, label: t('SUBJECTS.' + item.subject.abrv) }));
        data.forEach((item) => {
            resultMap.set(item.subject.id, item.tutors);
        });

        setOptions(parsedOptions);
        setTutorMap(resultMap);
    }

    useEffect(() => {
        fetch();
    }, []);

    const customStyles: StylesConfig<Option, false> = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#7e6cf2' : 'white',
            color: state.isSelected ? 'white' : 'black',
            fontSize: '14px',
        }),
        control: (base) => ({
            ...base,
            borderColor: 'lightgray',
            boxShadow: 'none',
            '&:hover': {
                border: '1px solid #7e6cf2',
            },
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#7e6cf2',
            animation: state ? 'none' : 'fadeIn 0.3s ease',
        }),
        dropdownIndicator: (base) => ({
            ...base,
            color: '#7e6cf2',
            '&:hover': {
                color: '#7e6cf2',
            },
            '& svg': {
                height: '40px',
                width: '40px',
            },
        }),
    };

    // Keyframes for the fade-in effect

    const handleChangeSubject = (selectedOption: SingleValue<Option>) => {
        const selectedValue = selectedOption ? selectedOption.value : '';
        setSelectedSubject(selectedValue);
    };

    let hoverTimer: NodeJS.Timeout;
    const handleMouseEnter = () => {
        // Set the timeout when the mouse enters
        hoverTimer = setTimeout(() => {
            setAutoChange(false);
        }, 1000); // Set for 1000 milliseconds
    };

    const handleMouseLeave = () => {
        // Clear the timeout when the mouse leaves
        clearTimeout(hoverTimer);
    };

    return (
        <>
            {tutorMap && options.length > 0 ? (
                <div className={'flex flex--col flex--jc--center p-4'}>
                    <div className={`flex ${isMobile ? 'flex--col' : 'flex--row'} flex--jc--center flex--center mb-4`}>
                        <span className={'pricing-title mr-1'}>{t('PRICING.COMPARE_PRICES')}</span>
                        <Select
                            isSearchable={false}
                            className={`${isMobile ? 'w--200' : 'w--400'} pricing-title`}
                            styles={customStyles}
                            components={{
                                IndicatorSeparator: () => null,
                            }}
                            defaultInputValue={selectedSubject}
                            classNamePrefix="select"
                            value={options.find((option) => option.value === selectedSubject) || options[0]}
                            onChange={handleChangeSubject}
                            options={options}
                            onFocus={() => setAutoChange(false)}
                        />
                    </div>
                    <div key={key} className={`flex flex--row flex--jc--center flex--gap-10 card-appear-animation ${isMobile ? ' flex--wrap' : ''}`}>
                        {tutorMap.get(selectedSubject)?.map((tutor) =>
                            !isMobile ? (
                                <PricingTutorCard
                                    key={tutor.id} // Assuming each tutor has a unique 'id' you can use as a key
                                    tutor={tutor}
                                    className={'p-4 h--350'}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                />
                            ) : (
                                <PricingTutorCardMobile
                                    key={tutor.id} // Assuming each tutor has a unique 'id' you can use as a key
                                    tutor={tutor}
                                    className={'p-4 h--350'}
                                />
                            )
                        )}
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};
