import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import { Route, Switch, useHistory } from 'react-router-dom';
import HowItWorks from './app/features/landing/pages/HowItWorks';
import BecomeTutor from './app/features/landing/pages/BecomeTutor';
import Pricing from './app/features/landing/pages/Pricing';
import Terms from './app/features/landing/pages/Terms';
import Terms_HR from './app/features/landing/pages/Terms_HR';
import Privacy_HR from './app/features/landing/pages/Privacy_HR';
import Privacy from './app/features/landing/pages/Privacy';
import Tax from './app/features/landing/pages/Tax';
import Cookie from './app/features/landing/pages/Cookie';
import Cookie_HR from './app/features/landing/pages/Cookie_HR';
import LegalCenter from './app/features/landing/pages/LegalCenter';
import RefundPolicy from './app/features/landing/pages/RefundPolicy';
import RefundPolicy_HR from './app/features/landing/pages/RefundPolicy_HR';
import Conduct from './app/features/landing/pages/Conduct';
import Conduct_HR from './app/features/landing/pages/Conduct_HR';
import { useLocaleContext } from './app/features/localization/providers/LocaleProvider';
import useMount from './app/utils/useMount';
import LocaleSelectModal from './app/features/localization/components/LocaleSelectModal';
import RegistrationModal from './app/features/auth/components/RegistrationModal';
import SchoolCampaign from './app/features/landing/pages/SchoolCampaign';
import SEO from './app/components/seo';
import { useLazyGetCountriesQuery } from './app/store/slices/countryMarket/countryService';
import { useAppDispatch } from './app/store/hooks';
import { setCountries } from './app/store/slices/countryMarket/countryMarketSlice';
import { useLazyGetLanguagesQuery } from './app/store/slices/lang/langService';
import { setLanguages } from './app/store/slices/lang/langSlice';
import LoginModal from './app/features/auth/components/LoginModal';
import ResetPasswordModal from './app/features/auth/components/ResetPasswordModal';

type MyRoute = {
    path: string;
    key: string;
    exact: boolean;
    lang: string;
    component: React.ComponentType;
};

export const ROUTES: MyRoute[] = [
    {
        path: '/en/online-tutoring',
        key: 'HOW_IT_WORKS',
        exact: true,
        lang: 'en',
        component: () => <HowItWorks />,
    },
    {
        path: '/hr/online-instrukcije',
        key: 'HOW_IT_WORKS',
        exact: true,
        lang: 'hr',
        component: () => <HowItWorks />,
    },
    {
        path: '/hr',
        key: 'HOW_IT_WORKS',
        exact: true,
        lang: 'hr',
        component: () => <Redirect to="/hr/online-instrukcije" />,
    },
    {
        path: '/en/become-tutor',
        key: 'BECOME_TUTOR',
        exact: true,
        lang: 'en',
        component: () => <BecomeTutor />,
    },
    {
        path: '/hr/postani-instruktor',
        key: 'BECOME_TUTOR',
        exact: true,
        lang: 'hr',
        component: () => <BecomeTutor />,
    },
    {
        path: '/en/pricing',
        key: 'PRICING',
        exact: true,
        lang: 'en',
        component: () => <Pricing />,
    },
    {
        path: '/hr/cijene',
        key: 'PRICING',
        exact: true,
        lang: 'hr',
        component: () => <Pricing />,
    },
    {
        path: '/en/terms',
        key: 'TERMS',
        exact: true,
        lang: 'en',
        component: () => <Terms />,
    },
    {
        path: '/hr/uvjeti-koristenja',
        key: 'TERMS',
        exact: true,
        lang: 'hr',
        component: () => <Terms_HR />,
    },
    {
        path: '/hr/politika-privatnosti',
        key: 'PRIVACY',
        exact: true,
        lang: 'hr',
        component: () => <Privacy_HR />,
    },
    {
        path: '/en/privacy-policy',
        key: 'PRIVACY',
        exact: true,
        lang: 'en',
        component: () => <Privacy />,
    },
    {
        path: '/hr/porez',
        key: 'TAX',
        exact: true,
        lang: 'hr',
        component: () => <Tax />,
    },
    {
        path: '/hr/skole',
        key: 'SCHOOL',
        exact: true,
        lang: 'hr',
        component: () => <SchoolCampaign />,
    },
    {
        path: '/en/tax',
        key: 'TAX',
        exact: true,
        lang: 'en',
        component: () => <Tax />,
    },
    {
        path: '/en/cookie-policy',
        key: 'COOKIE',
        exact: true,
        lang: 'en',
        component: () => <Cookie />,
    },
    {
        path: '/hr/politika-kolacica',
        key: 'COOKIE_HR',
        exact: true,
        lang: 'hr',
        component: () => <Cookie_HR />,
    },
    {
        path: '/hr/pravni-centar',
        key: 'LEGAL_CENTER',
        exact: true,
        lang: 'hr',
        component: () => <LegalCenter />,
    },
    {
        path: '/en/legal-center',
        key: 'LEGAL_CENTER',
        exact: true,
        lang: 'en',
        component: () => <LegalCenter />,
    },
    {
        path: '/en/refund-and-payment-policy',
        key: 'REFUND_POLICY',
        exact: true,
        lang: 'en',
        component: () => <RefundPolicy />,
    },
    {
        path: '/hr/politika-povrata-i-placanja',
        key: 'REFUND_POLICY',
        exact: true,
        lang: 'hr',
        component: () => <RefundPolicy_HR />,
    },
    {
        path: '/en/user-code-of-conduct',
        key: 'USER_CODE_OF_CONDUCT',
        exact: true,
        lang: 'en',
        component: () => <Conduct />,
    },
    {
        path: '/hr/kodeks-ponasanja-korisnika',
        key: 'USER_CODE_OF_CONDUCT',
        exact: true,
        lang: 'hr',
        component: () => <Conduct_HR />,
    },
    {
        path: '*',
        key: 'DEFAULT',
        exact: false,
        lang: 'en',
        component: () => <Redirect to="/" />,
    },
];

function RouteWithSubRoutes(route: any) {
    return (
        <Route key={route.key} path={route.path} exact={route.exact} render={(props: any) => <route.component {...props} routes={route.routes} />} />
    );
}

export default function App() {
    const { i18n } = useTranslation();
    const history = useHistory();
    const [locationKeys, setLocationKeys] = useState<(string | undefined)[]>([]);
    const { initLang } = useLocaleContext();
    const dispatch = useAppDispatch();

    const [getCountries] = useLazyGetCountriesQuery();
    const [getLanguages] = useLazyGetLanguagesQuery();

    async function initializeLocale() {
        try {
            const [res, resLang] = await Promise.all([getCountries().unwrap(), getLanguages().unwrap()]);

            dispatch(setCountries(res));
            dispatch(setLanguages(resLang));
        } catch (e) {
            console.log(e);
        }
    }

    useMount(() => {
        initializeLocale();
        window.scrollTo(0, 0);
        initLang((lang) => {
            console.log('initLang', lang);
            if (window.location.pathname !== '/') {
                const curr = ROUTES.find((route) => route.path === window.location.pathname);

                if (!curr) return;
                let newOne = ROUTES.find((route) => route.key === curr.key && route.lang === lang);
                if (!newOne) newOne = curr;

                history.push(newOne.path);

                return;
            }

            if (lang === 'hr') {
                history.push('/hr/online-instrukcije');
            } else {
                history.push('/en/online-tutoring');
            }
        });
    });

    useEffect(() => {
        return history.listen((location: any) => {
            if (history.action === 'PUSH') {
                if (location.key) setLocationKeys([location.key]);
            }

            if (history.action === 'POP') {
                if (locationKeys[1] === location.key) {
                    setLocationKeys(([, ...keys]) => keys);
                } else {
                    setLocationKeys((keys) => [location.key, ...keys]);
                }
                const lang = location.pathname.split('/')[1];
                if (i18n.language != lang) {
                    i18n.changeLanguage(lang);
                }
            }
        });
    }, [history, i18n, locationKeys]);

    return (
        <>
            <Switch>
                {ROUTES.map((route: any) => {
                    return <RouteWithSubRoutes key={route.key} {...route} />;
                })}
            </Switch>
            <SEO routes={ROUTES} />
            <LocaleSelectModal />
            <RegistrationModal />
            <LoginModal />
            <ResetPasswordModal />
        </>
    );
}
