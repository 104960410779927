import { useEffect } from 'react';

import LandingWrapper from '../components/LandingWrapper';

const Terms_HR = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <LandingWrapper>
            <div className="terms">
                <h1>
                    <strong>Teorem Uvjeti korištenja</strong>
                </h1>
                <h2>Za korisnike</h2>
                <p>
                    Pažljivo pročitajte ove Uvjete pružanja usluge (u daljnjem tekstu - "Uvjeti") prije korištenja Teorem Marketplace i/ili Usluga
                    (kako je definirano u nastavku). Ako ne prihvaćate ove Uvjete u cijelosti, uključujući ugovor o arbitraži na pojedinačnoj osnovi o
                    svim potraživanjima između vas i Teorem Ltd. (dalje u tekstu "Teorem", "mi" i "nas"), korporacije registrirane i organizirane
                    prema engleskom zakonu ili prema bilo kojoj od njenih podružnica, ne smijete pristupati ili koristiti Teorem.
                </p>
                <ul>
                    <h3>1. OPĆE INFORMACIJE</h3>
                </ul>
                <ul>
                    <h3>1.1. Uvjeti pružanja usluge</h3>
                </ul>
                <p>
                    Ovi Uvjeti opisuju odredbe i uvjete koji reguliraju vaš pristup i korištenje web stranice, koja se nalazi na
                    <a href={'/'}> www.teorem.co</a>, i njenih poddomena (zajedničkim nazivom "Web-stranica"), povezane aplikacije za mobilne uređaje
                    i softvera (zajedno s Web-stranicom, "Teorem Marketplace").
                </p>
                <p>
                    Vaše korištenje Usluga podložno je ovim Uvjetima i svim drugim pravilima ili pravilima objavljenim na Web-stranici ili koje vam je
                    Teorem na drugi način dostavio.
                </p>
                <p>
                    Otvaranjem računa na Teorem Marketplaceu shvaćate da ulazite u pravno obvezujući ugovor s Teoremom, koji je na snazi od datuma
                    kreiranja računa, te pokazujete da bezuvjetno prihvaćate ove Uvjete.
                </p>
                <p>
                    Ovi Uvjeti, kao i <a href={'/hr/politika-povrata-i-placanja'}>Pravila povrata novca i plaćanja</a>,
                    <a href={'/hr/politika-privatnosti'}> Pravila privatnosti</a>, <a href={'/hr/politika-kolacica'}> Pravila o kolačićima </a>i svi
                    uvjeti specifični za uslugu koji su dostupni u Pravnom centru, pravno su obvezujući ugovori koji uređuju odnos između Teorema i
                    bilo kojeg Korisnika (kako je definirano u nastavku) ili posjetitelja Teorem Marketplacea. Kao korisnik ili posjetitelj Teorem
                    Marketplacea, prikupljanje, korištenje i dijeljenje vaših osobnih podataka podliježu Politici privatnosti i Politici kolačića,
                    koji se mogu povremeno mijenjati.
                </p>
                <ul>
                    <h3>1.2. Teorem usluge</h3>
                </ul>
                <p>Teorem Marketplace možete koristiti kao: </p>
                <ul>
                    a) Registrirani korisnik koji traži usluge online podučavanja koristeći Teorem Marketplace ili druge alate koje Teorem stavlja na
                    raspolaganje ("Student");
                </ul>
                <ul>
                    b) Registrirani korisnik koji pruža usluge online podučavanja putem Teorem Marketplacea ili drugih alata koje nudi Teorem
                    ("Instruktor");
                </ul>
                <ul>c) Posjetitelj Web-stranice.</ul>
                <p>
                    Kroz ove Uvjete, pojmovi "vi" ili "vaš" mogu se odnositi na Studenta, Instruktora ili posjetitelja koji pristupa ili koristi bilo
                    koju od naših usluga (svaki se naziva "Korisnik").
                </p>
                <p>
                    Teorem usluge uključuju pristup Teorem Marketplaceu za Studente i Instruktore, za povezivanje za online podučavanje i usluge
                    podučavanja, olakšavanje plaćanja između Studenata i Instruktora te korisničku podršku i povezane usluge (u daljnjem tekstu
                    "Usluge"). Teorem ne pruža usluge podučavanja ili podučavanja. Sve dostupne usluge podučavanja i podučavanja na Teorem
                    Marketplaceu pružaju se u skladu s posebnim uvjetima usluga navedenim u<a href={'/hr/pravni-centar'}> Pravnom centru.</a>.
                </p>
                <p>
                    Vi ste odgovorni za nabavu i plaćanje bilo koje opreme i internetskih usluga potrebnih za pristup Uslugama. Možemo promijeniti,
                    obustaviti ili prekinuti Teorem Marketplace ili Usluge u cijelosti ili djelomično, u bilo koje vrijeme i iz bilo kojeg razloga,
                    bez prethodne obavijesti. Teorem Marketplace također može povremeno postati nedostupan zbog održavanja ili kvara računalne opreme
                    ili drugih razloga. S vremena na vrijeme možemo omogućiti pristup uslugama i proizvodima trećih strana ili našim vlastitim
                    proizvodima ili uslugama. Prihvaćate da se Teorem Marketplace razvija i da se oblik i priroda Usluga mogu promijeniti s vremena na
                    vrijeme bez obavijesti Vama.
                </p>
                <ul>
                    <h3>1.3. Izmjene i dopune ovih Uvjeta</h3>
                </ul>
                <p>
                    Zadržavamo pravo izmjene ili promjene Uvjeta u bilo kojem trenutku, objavljivanjem revidirane verzije na Web-stranici i
                    obavještavanjem putem Teorem Marketplacea ili putem adrese e-pošte koja je povezana s vama u slučaju materijalnih promjena.
                    Najnovija verzija objavljena je na ovoj stranici.
                </p>
                <p>
                    Potičemo vas da redovito provjeravate ovu stranicu. Ako se ne slažete s izmjenama i dopunama ovih Uvjeta, imate pravo raskinuti
                    ove Uvjete prestankom korištenja Usluga i slanjem obavijesti o prekidu Teoremu ili brisanjem svog računa. Nastavkom korištenja
                    Usluga nakon izmjene ovih Uvjeta, pristajete na obvezivanje Uvjeta kako su izmijenjeni.
                </p>
                <ul>
                    <h3>2. OBVEZE</h3>
                </ul>
                <ul>
                    <h3>2.1. Prihvatljivost usluga</h3>
                </ul>
                <p>
                    Usluge su dostupne samo pojedincima starijim od 18 godina i mogu ih koristiti samo pojedinci koji mogu sklopiti pravno obvezujuće
                    ugovore prema primjenjivom zakonu. Osobe mlađe od 18 godina mogu koristiti naše Usluge samo uz prethodnu suglasnost zakonskog
                    zastupnika osobe. Roditelj ili zakonski skrbnik će u svakom trenutku biti odgovoran za sve aktivnosti povezane s korištenjem
                    Usluga od strane gore navedenih pojedinaca.
                </p>
                <ul>
                    <h3>2.2. Verifikacija</h3>
                </ul>
                <p>
                    Teorem može zatražiti od Instruktora da dostavi službenu osobnu iskaznicu (putovnicu, vozačku dozvolu itd.) i dokumente koji
                    potvrđuju tražene obrazovne vjerodajnice (obrazovne pozadinske dokumente, kao što su diplome, svjedodžbe itd.). Od Instruktora će
                    se tražiti da prenese kopiju Instruktorove službene osobne iskaznice i svoje dokumente o obrazovanju. Kako Instruktor prođe
                    proceduru verifikacije, na njegov profil se dodaje posebna značka, a učitane kopije dokumenata brišu se s naših servera.
                </p>
                <p>
                    Teorem ne podržava niti daje nikakva zastupanja ili jamstva u vezi s točnošću, potpunošću i pouzdanošću bilo kojih informacija
                    koje pruža Instruktor u postupku provjere.
                </p>
                <p>
                    Teorem ne može potvrditi da je svaki Korisnik ono za što se predstavlja. Suglasni ste i razumijete da preuzimate sve rizike
                    prilikom korištenja Usluga, uključujući bez ograničenja sve rizike povezane s bilo kojom online ili offline interakcijom s drugim
                    Korisnicima.
                </p>
                <p>
                    Kada komunicirate s drugim Korisnicima, trebali biste biti oprezni i razumni kako biste zaštitili svoju osobnu sigurnost i
                    imovinu, baš kao što biste to učinili kada komunicirate s drugim ljudima koje ne poznajete. Niti Teorem niti njegove podružnice
                    ili davatelji licence nisu odgovorni za ponašanje, bilo online ili offline, bilo kojeg Korisnika Usluga. Teorem, njegove
                    podružnice i davatelji licenci neće biti odgovorni za bilo kakva potraživanja, ozljede ili štete proizašle iz ili u vezi s vašim
                    korištenjem Usluga.
                </p>
                <ul>
                    <h3>2.3. Izvješća potrošača</h3>
                </ul>
                <p>
                    Teorem može, ali ne mora, koristiti agencije treće strane za prijavu potrošača koje provode, između ostalog, provjeru kriminalne
                    prošlosti, provjeru registra seksualnih prijestupnika, provjeru evidencije motornih vozila, provjeru kreditne sposobnosti i
                    provjeru identifikacije ("Izvješća potrošača"). Teorem ne podupire niti daje bilo kakva predstavljanja ili jamstva u vezi s
                    pouzdanošću takvih Izvješća potrošača ili točnosti, pravodobnosti ili potpunosti bilo koje informacije u Izvješćima potrošača.
                    Teorem ne provjerava neovisno informacije u Izvješćima potrošača.
                </p>
                <p>
                    Ovime pristajete na to da Teorem prikuplja, koristi i otkriva informacije u Izvješćima potrošača. Razumijete i slažete se da
                    Teorem može, prema vlastitom nahođenju, pregledati i osloniti se na informacije u Izvješćima potrošača pri odlučivanju hoće li
                    suspendirati ili ukinuti korisnički račun ili istražiti pritužbu o korisniku, ali da Teorem neće biti odgovoran na bilo koji način
                    u slučaju da bilo koja informacija u Izvješćima potrošača o bilo kojoj osobi, uključujući bez ograničenja bilo kojeg Korisnika,
                    nije točna, pravovremena ili potpuna. Korisnici koji su predmet Izvješća potrošača mogu se obratiti agenciji treće strane za
                    prijavu potrošača kako bi osporili točnost, pravodobnost ili potpunost takvih informacija. Teorem zadržava pravo obustaviti i/ili
                    ukinuti korisnički račun na temelju informacija u Izvješćima potrošača ili iz bilo kojeg drugog razloga prema vlastitom nahođenju.
                </p>
                <ul>
                    <h3>2.4. Privatnost</h3>
                </ul>
                <p>
                    Prilikom korištenja Usluga od vas se može tražiti da date svoje osobne podatke. Kako biste saznali više o našim praksama
                    privatnosti, pogledajte <a href={'/hr/politika-privatnosti'}> Pravila privatnosti Teorema.</a>.
                </p>
                <ul>
                    <h3>2.5. Plaćanje</h3>
                </ul>
                <p>
                    Obradu plaćanja na Teorem Marketplaceu pružaju procesori plaćanja trećih strana uključujući, ali ne ograničavajući se na
                    Braintree, PayPal, Stripe, Skrill, Payoneer i TransferWise, što nam omogućuje:
                </p>
                <ul>a) naplatiti Studentima umjesto izravne obrade podataka vaše kreditne/debitne kartice;</ul>
                <ul>b) Omogućiti isplate Instruktorima.</ul>
                <p>
                    Radi izbjegavanja sumnje, plaćanje koje je izvršio Student Teoremu zadovoljit će studentovu obvezu u pogledu plaćanja Instruktoru
                    za usluge podučavanja pružene putem Teorem Marketplacea.
                </p>
                <p>
                    Pregledajte dodatne uvjete plaćanja kako je navedeno u
                    <a href={'/hr/politika-povrata-i-placanja'}> Politici plaćanja i povrata novca</a>.
                </p>
                <p>
                    Više pojedinosti o sigurnosti vaših podataka o plaćanju i naplati možete pronaći u našim
                    <a href={'/hr/politika-privatnosti'}> Pravilima privatnosti</a>.
                </p>
                <ul>
                    <h3>2.6. Povrat novca</h3>
                </ul>
                <p>
                    Teorem nastoji osigurati jasno razumijevanje financijskih odnosa između Studenata i Instruktora s obzirom na Usluge koje pružamo.
                    Provjerite našu <a href={'/hr/politika-povrata-i-placanja'}> Politiku povrata novca i plaćanja </a>
                    kako biste saznali više o tome kako obrađujemo povrat novca. U najvećoj mjeri dopuštenoj zakonom, bilo koji povrat novca u bilo
                    kojem trenutku isključivo je po našem nahođenju.
                </p>
                <ul>
                    <h3>3. PRAVA I OGRANIČENJA</h3>
                </ul>
                <ul>
                    <h3>3.1. Vaše pravo na korištenje Teorem Marketplacea</h3>
                </ul>
                <p>
                    U slučaju da ste Student, Teorem vam ovime daje, prema ovdje navedenim Uvjetima, neprenosivo, neisključivo, ograničeno pravo na
                    pristup i korištenje Teorem Marketplacea isključivo za vašu nekomercijalnu osobnu upotrebu.
                </p>
                <p>
                    U slučaju da ste Instruktor, Teorem vam ovime daje, prema ovdje navedenim Uvjetima, neprenosivo, nepodlicencirajuće, neisključivo,
                    ograničeno pravo pristupa i korištenja Teorem Marketplacea isključivo za pružanje usluga podučavanja Studentima.
                </p>
                <p>
                    Suglasni ste da nećete pregledavati, kopirati ili nabavljati sadržaj ili informacije s Teorem Marketplacea automatiziranim
                    sredstvima (kao što su skripte, botovi, pauci, programi za indeksiranje ili strugači) ili koristiti drugu tehnologiju rudarenja
                    podataka ili procese za uokvirivanje, maskiranje, izdvajanje podataka ili drugih materijala s Teorem Marketplacea (osim ako to
                    može biti rezultat standardne tražilice ili korištenja internetskog preglednika) osim ako to Teorem nije službeno odobrio posebnim
                    pisanim ugovorom.
                </p>
                <p>
                    Suglasni ste da se nećete raspitivati o angažmanu ili angažirati Instruktore da dovrše zadatke, napišu radove, rješavaju kvizove
                    ili na neki drugi način obavljaju posao u vaše ime. Nadalje, suglasni ste da nećete koristiti Usluge u bilo koju svrhu koja krši
                    politiku akademskog poštenja ili drugu politiku ponašanja vaše škole, sveučilišta, akademske ustanove ili radnog mjesta.
                </p>
                <p>
                    Nijedan materijal Teorema koji vam je dostupan kao dio Usluga ne smije se kopirati, reproducirati, mijenjati, ponovno
                    objavljivati, preuzimati, učitavati, objavljivati, prenositi ili distribuirati u bilo kojem obliku ili na bilo koji način bez
                    prethodnog pisanog dopuštenja Teorema ili kako je izričito navedeno u ovim Uvjetima.
                </p>
                <p>Ne smijete dijeliti niti prenositi svoje vjerodajnice računa s bilo kojom trećom stranom.</p>
                <p>
                    Teorem može nametnuti razumna ograničenja vašem opsegu pristupa Teorem Marketplaceu, uključujući ograničenja vremena ili količine
                    informacija kojima se pristupa ili uređaja koji se koriste za pristup Teorem Marketplaceu, kako bi se spriječilo neovlašteno
                    korištenje Usluga od treće strane.
                </p>
                <p>Sva prava koja ovdje nisu izričito navedena su pridržana.</p>
                <ul>
                    <h3>3.2. Vaš korisnički račun</h3>
                </ul>
                <p>
                    Određene naše Usluge rezervirane su samo za registrirane korisnike. Da biste postali registrirani korisnik, morate stvoriti račun
                    Studenta ili Instruktora na Teorem Marketplaceu. Suglasni ste da ste odgovorni za zaštitu vjerodajnica svog računa od neovlaštene
                    upotrebe i odgovorni ste za sve aktivnosti koje se odvijaju pod tim vjerodajnicama računa. Suglasni ste da ćete nas odmah
                    obavijestiti ako smatrate da je bilo koja vjerodajnica vašeg računa korištena ili bi se mogla koristiti bez vašeg dopuštenja kako
                    bismo mogli poduzeti odgovarajuće radnje.
                </p>
                <p>
                    Web-stranicu i njene značajke možete koristiti bez da ste registrirani korisnik. Ovo ne isključuje primjenu ovih Uvjeta i drugih
                    Teoremovih pravila i politika primjenjivih na vašu upotrebu Web-stranice, uključujući, ali ne ograničavajući se na vašu
                    interakciju s Web-stranicom.
                </p>
                <p>Ne možete:</p>
                <ul>a) Napraviti više od dva računa (jedan kao Instruktor i jedan kao Roditelj ili Student) za pristup Teorem Marketplaceu,</ul>
                <ul>b) Podijeliti svoje vjerodajnice računa s bilo kojom trećom stranom, ili</ul>
                <ul>c) Prenesti svoj račun bilo kojoj trećoj strani.</ul>
                <p>
                    Teorem nije odgovoran za bilo kakav gubitak ili štetu koju ste prouzročili ili troškove nastale kao rezultat vašeg propusta da
                    zaštitite vjerodajnice svog računa. Suglasni ste da nećete iznajmljivati, preprodavati ili ponovno prodavati Teorem Marketplace
                    niti pružati pristup Uslugama bilo kojoj trećoj strani.
                </p>
                <p>
                    Kada kreirate račun na Teoremu, možemo prikupljati određene osobne podatke izravno od vas, ili ako kreirate svoj račun pomoću
                    usluge treće strane (Facebook, Google), možemo prikupljati osobne podatke o vama od usluge treće strane (vaše korisničko ime ili
                    korisnički ID povezan s tom uslugom treće strane). Odabirom izrade računa pomoću usluge treće strane, ovlašćujete nas da
                    prikupljamo osobne podatke potrebne za provjeru autentičnosti vašeg računa kod pružatelja usluge treće strane.
                </p>
                <p>
                    Možete odabrati profilnu sliku ili povezati svoj Google ili Facebook račun koji će biti prikazan unutar vašeg Student računa.
                    Imajte na umu da dostava vaše fotografije nije obvezna za korištenje naših usluga. Možete uređivati svoj račun prema vlastitom
                    nahođenju. Sigurnost svih osobnih podataka koje odlučite podijeliti unutar svog računa podliježe našim
                    <a href={'/hr/politika-privatnosti'}> Pravilima o privatnosti</a>.
                </p>
                <p>Kada kreirate Instruktor račun, obavezno je dati svoju profilnu sliku.</p>
                <p>
                    Možete uređivati svoj račun prema vlastitom nahođenju. Dodavanje više pojedinosti vašem računu Instruktora može vam pomoći da
                    izvučete najviše iz svoje upotrebe usluga, na primjer, pronađete više Studenata. Stoga je vaš izbor hoćete li uključiti dodatne
                    podatke u svoj račun ili ne, kao što su vještine, obrazovanje i radno iskustvo. Sigurnost svih osobnih podataka koje odlučite
                    podijeliti unutar svog računa podliježe našim
                    <a href={'/hr/politika-privatnosti'}> Pravilima o privatnosti</a>.
                </p>
                <ul>
                    <h3>3.3. Izravne interakcije</h3>
                </ul>
                <p>Teorem ne sudjeluje u izravnim interakcijama između Studenata i Instruktora osim kada to smatramo preporučljivim:</p>
                <ul>a) Kako bismo osigurali usklađenost s ovim Uvjetima;</ul>
                <ul>b) Za poboljšanje naših Usluga; ili</ul>
                <ul>
                    c) Kao što je navedeno u našoj <a href={'/hr/politika-povrata-i-placanja'}> Politici povrata novca i plaćanja</a>.
                </ul>
                <p>
                    Teorem nema kontrolu nad uslugama koje pružaju Instruktoru, bilo kakvim recenzijama ili ocjenama koje daju Studenti, niti nad
                    radnjama i propustima Korisnika.
                </p>
                <ul>
                    <h3>3.4. Izjave i jamstva</h3>
                </ul>
                <p>
                    <b>Zastupstva specifična za Instruktore</b>
                </p>
                <p>Ako koristite usluge kao Instruktor: </p>
                <ul>
                    a) pružat ćete usluge podučavanja u skladu s ovim Uvjetima i drugim pravilima koje postavlja Teorem i koja su vam dostupna putem
                    e-pošte ili objavljivanjem na Web-stranici;
                </ul>
                <ul>b) pružat ćete usluge podučavanja u skladu sa zakonima i propisima primjenjivim u državi u kojoj pružate usluge podučavanja;</ul>
                <ul>
                    c) Vi ste isključivo odgovorni i u potpunosti odgovorni za svako kršenje bilo kojih lokalnih zakona i propisa koji se odnose na
                    vaše pružanje usluga podučavanja;
                </ul>
                <ul>d) pružat ćete usluge podučavanja s razumnom pažnjom i vještinom te u skladu s općeprihvaćenom praksom;</ul>
                <ul>
                    e) dobili ste sve registracije, certifikate, licence i drugu dokumentaciju koja je potrebna u primjenjivoj jurisdikciji za
                    pružanje usluga podučavanja. Vaša je obveza održavati valjanost sve gore navedene dokumentacije;
                </ul>
                <ul>
                    f) nećete pružati usluge podučavanja Studentima izvan Teorem Marketplacea, primati uplate izravno od Studenta ili poticati ili
                    tražiti plaćanje od Studenta izravno ili putem bilo kojih drugih kanala osim onih koje nudi Teorem;
                </ul>
                <ul>
                    g) prihvaćate i slažete se da Teorem može reklamirati usluge podučavanja koje pružate putem Teorem Marketplacea bez ikakvog
                    dodatnog plaćanja ili obveze prema vama;
                </ul>
                <ul>
                    h) potvrđujete i slažete se da Teorem može poboljšati video koji ste dali za svoj račun. Poboljšanja se mogu napraviti uređivanjem
                    videa, dodavanjem logotipa Teorem videu, poboljšanjem kvalitete zvuka u videu i objavljivanjem videa na računima društvenih medija
                    Teorema uz dodavanje opisa i veze na profil Instruktora.
                </ul>
                <p>
                    <b>Zastupstva specifična za Studente</b>
                </p>
                <p>Ako koristite usluge kao Student: </p>
                <ul>a) slažete se da ćete poštovati obveze koje ste preuzeli prema Instruktoru putem Teorem Marketplacea;</ul>
                <ul>
                    b) suglasni ste da nećete zaobići ili manipulirati strukturom naknada, procesom naplate ili naknadama koje dugujete Teoremu ili
                    Instruktoru; i
                </ul>
                <ul>c) slažete se da ćete uložiti napore u dobroj vjeri u interakciju s Instruktorima.</ul>
                <ul>
                    <h3>3.5. Uvodni video Instruktora i fotografija profila</h3>
                </ul>
                <p>
                    Instruktori daju Teoremu prava na korištenje uvodnog videa, imena i profilne fotografije nastavnika u marketinške, reklamne ili
                    promotivne svrhe, uključujući ali ne ograničavajući se na objavljivanje na kanalima društvenih medija, video hosting i usluge
                    „streamanja“, kao što su YouTube, Vimeo, Facebook , ili drugi, kako bi se osigurala dostupnost i vidljivost Studentima.
                </p>
                <p>
                    Uvijek možete zatražiti uklanjanje svih uvodnih videozapisa objavljenih na kanalima društvenih medija, video hostingu i uslugama
                    „streamanja“ pisanjem na <a href={'mailto:support@teorem.co'}>support@teorem.co</a>.
                </p>
                <ul>
                    <h3>3.6. Rangiranje Instruktora</h3>
                </ul>
                <p>
                    Teorem koristi mnogo informacija kako bi Studentu pokazao Instruktore koji najbolje odgovaraju Studentovim preferencama.
                    Redoslijed kojim se prikazuju Instruktori ovisi o nizu čimbenika uključujući, ali ne ograničavajući se na sljedeće:
                </p>
                <ul>
                    <li>Predmet koji Student traži;</li>
                    <li>Država Studenta;</li>
                    <li>Vremenska zona Studenta;</li>
                    <li>Preklapanje standardnog radnog vremena između vremenskih zona Instruktora i Studenta;</li>
                    <li>Opća dostupnost termina u kalendaru nastavnika;</li>
                    <li>Dosadašnji učinak Instruktora u pomaganju njihovim Studentima u postizanju njihovih ciljeva učenja;</li>
                    <li>Informacije o profilu Instruktora, uključujući njihovu potpunost i kvalitetu fotografije profila i uvodnog videa;</li>
                    <li>Koliko Instruktor odgovara na poruke Studenta;</li>
                    <li>Studentske recenzije i ocjene;</li>
                    <li>Povijest učenja Studenta na Teorem Marketplaceu.</li>
                </ul>
                <ul>
                    <h3>3.7. Pritužbe korisnika</h3>
                </ul>
                <p>
                    Ako Korisnik ima pritužbu u vezi s Uslugama, trebao bi kontaktirati Teorem{' '}
                    <a href={'mailto:support@teorem.co'}>support@teorem.co</a>s predmetom 'Službena pritužba' ("Pritužba"), navodeći što više
                    pojedinosti o Pritužbi. Teorem će odgovoriti Korisniku potvrđujući primitak i istražiti slučaj. Po primitku Pritužbe, Teorem
                    korisnička podrška interno će istražiti pritužbu, uzimajući u obzir važnost i složenost postavljenog pitanja ili problema.
                </p>
                <p>Ako se Pritužba odnosi na određenog zaposlenika Teorema, drugi predstavnik Teorema pomoći će u istrazi umjesto njega.</p>
                <p>Teorem će odgovoriti Korisniku sa svojim nalazima u odgovoru na Pritužbu, i, gdje je primjenjivo, s prijedlogom rješenja.</p>
                <ul>
                    <h3>3.8. Snimanje predavanja</h3>
                </ul>
                <p>
                    Prihvaćate i slažete se da, s vremena na vrijeme, Teorem može snimati videozapise vaših lekcija. Ova snimka je namijenjena
                    isključivo u svrhu osiguranja kvalitete Usluga. Snimke se neće dijeliti izvana, koristiti za pojedinačno ciljanje ili koristiti u
                    bilo kakve prodajne, marketinške ili druge promotivne svrhe.
                </p>
                <p>
                    Korištenjem Usluga pristajete i dajete suglasnost za takva snimanja koja su besplatna, neželjena i bez ograničenja. Slažete se da
                    Teorem može koristiti ove snimke bez fiducijarnih ili drugih obveza i bez ikakve dodatne naknade.
                </p>
                <ul>
                    <h3>3.9. Ograničenja</h3>
                </ul>
                <p>
                    Dok koristite Usluge, slažete se da ćete se pridržavati
                    <a href={'/hr/kodeks-ponasanja-korisnika'}> Korisničkog kodeksa ponašanja</a>.
                </p>
                <ul>
                    <h3>4. SADRŽAJ TEOREMA</h3>
                </ul>
                <p>
                    Korisnici imaju osobno, neprenosivo, neisključivo pravo korištenja Teorem sadržaja na Teorem Marketplaceu podložno ovim Uvjetima.
                    "Sadržaj Teorema" znači sve informacije, tekst, slike, podatke, poveznice ili drugi materijal dostupan putem Teorem Marketplacea,
                    bilo da smo ga stvorili mi ili ih je pružila treća strana na ili putem Teorem Marketplacea. Sadržaj može sadržavati tiskarske
                    pogreške, druge nenamjerne pogreške ili netočnosti. Zadržavamo pravo izmjene Sadržaja Teorema bez obveze izdavanja bilo kakve
                    obavijesti o takvim promjenama. Možete pregledavati, kopirati, preuzimati i ispisivati Teorem Sadržaj koji je dostupan na ili
                    putem Teorem Marketplacea, podložno sljedećim uvjetima:
                </p>
                <ul>
                    a) Sadržaj Teorema dostupan je isključivo za vašu osobnu upotrebu. Nijedan dio Teorem Marketplacea ili Teorem Sadržaja ne smije se
                    reproducirati ili prenositi u bilo kojem obliku, bilo kojim sredstvom, elektroničkim ili mehaničkim, uključujući fotokopiranje i
                    snimanje u bilo koju drugu svrhu;
                </ul>
                <ul>b) Sadržaj Teorema ne može se mijenjati;</ul>
                <ul>c) Obavijesti o autorskim pravima, zaštitnim znakovima i drugim pravima vlasništva ne smiju se uklanjati.</ul>
                <p>
                    Ništa što je sadržano na Teorem Marketplaceu ne smije se tumačiti kao davanje, implicitno, eksplicitno ili na neki drugi način,
                    bilo koje licence ili prava na korištenje Teorem Marketplacea ili bilo kojeg Sadržaja Teorema, osim: (a) kako je izričito
                    dopušteno ovim Uvjetima; ili (b) uz naše prethodno pismeno dopuštenje ili dopuštenje takve treće strane koja može posjedovati
                    zaštitni znak ili autorsko pravo Sadržaja Teorema.
                </p>
                <ul>
                    <h3>5. SADRŽAJ GENERIRAN OD STRANE KORISNIKA</h3>
                </ul>
                <p>
                    <b>“Korisnički generirani sadržaj”</b> znači sve komentare, primjedbe, informacije, povratne informacije, tekst, fotografije,
                    poveznice, podatke, slike, video, glazbu ili drugi materijal koji vi ili bilo koji Korisnik objavite na bilo kojem dijelu Teorem
                    Marketplacea ili date Teoremu, uključujući takav sadržaj ili informacije koji su objavljeni kao rezultat anketa.
                </p>
                <p>
                    Nismo odgovorni za ponašanje korisnika ili za stavove, mišljenja i izjave izražene u sadržaju koji su stvorili korisnici koji je
                    podnijet za javno prikazivanje putem Teorem Marketplacea. Informacije objavljene na internetu ne provjeravamo unaprijed. Mi
                    djelujemo kao pasivni kanal za takvu distribuciju i možda nismo odgovorni za sadržaj koji generiraju korisnici. Sva mišljenja,
                    savjeti, izjave, usluge, ponude ili druge informacije u Korisnički generirani sadržaj koje su Korisnici izrazili ili učinili
                    dostupnima pripadaju dotičnom autoru(ima) ili distributeru(ima), a ne Teoremu. Ne podržavamo niti jamčimo točnost, cjelovitost ili
                    korisnost bilo kojeg takvog korisnički generiranog sadržaja. Vi ste odgovorni za osiguravanje da Korisnički generiran sadržaj
                    poslan na Teorem Marketplace ne predstavlja kršenje autorskih prava, poslovne tajne ili drugih prava intelektualnog vlasništva
                    druge osobe ili entiteta. Isključivo ćete biti odgovorni za bilo kakvu štetu koja proizlazi iz bilo kakvog kršenja autorskih
                    prava, poslovnih tajni ili drugih prava intelektualnog vlasništva ili bilo koje druge štete koja proizlazi iz vaše upotrebe,
                    učitavanja, objavljivanja ili podnošenja Sadržaja koji generiraju Korisnici na Teorem Marketplaceu.
                </p>
                <p>
                    Imamo pravo, ali ne i obvezu, nasumično nadzirati, uređivati ili uklanjati bilo koji sadržaj koji su generirali korisnici poslani
                    na ili putem Teorem Marketplacea u bilo kojem trenutku.
                </p>
                <p>
                    Ako smatrate da su vaša prava intelektualnog vlasništva povrijeđena, pošaljite pritužbu na
                    <a href={'mailto:legal@teorem.co'}> legal@teorem.co</a>. Možete prijaviti sve vrste potraživanja intelektualnog vlasništva,
                    uključujući, ali ne ograničavajući se na autorska prava, trgovačke znakove i patente. Brzo odgovaramo na zabrinutosti vlasnika
                    prava u vezi s bilo kakvim navodnim kršenjem i ukidamo ponovljene prekršitelje u odgovarajućim okolnostima.
                </p>
                <ul>
                    <h3>6. VEZE NA WEB MJESTA TREĆIH STRANA</h3>
                </ul>
                <p>
                    Teorem Marketplace može sadržavati poveznice na web stranice koje nisu Teorem. Ove veze su vam pružene kao pogodnost i/ili pomoćne
                    usluge, a Teorem nije odgovoran za sadržaj bilo koje povezane web stranice. Bilo koje web mjesto koje nije Teorem kojem se
                    pristupa s Teorem Marketplacea neovisno je o Teoremu, a Teorem nema kontrolu nad sadržajem tog web mjesta. Nadalje, poveznica na
                    bilo koju web stranicu koja nije Teorem ne znači da Teorem prihvaća bilo kakvu odgovornost za sadržaj ili korištenje takve web
                    stranice. Korištenje bilo koje web stranice treće strane podliježe njezinim uvjetima pružanja usluge i politici privatnosti.
                    Molimo korisnike da budu oprezni i dobro rasuđuju kada koriste web stranice trećih strana.
                </p>
                <ul>
                    <h3>7. OGLASI</h3>
                </ul>
                <p>
                    Teorem može objavljivati oglase i promocije koje sponzoriraju treće strane na Teorem Marketplaceu. Vaša korespondencija ili
                    poslovni odnosi s, ili sudjelovanje u promocijama oglašivača koji nisu Teorem koji se nalaze na ili putem Usluga, uključujući
                    plaćanje i isporuku povezane robe ili usluga, te bilo koje druge odredbe, uvjete, jamstva ili izjave povezane s takvim poslovima,
                    isključivo između vas i takvog oglašivača. Teorem nije odgovoran za bilo kakav gubitak ili štetu bilo koje vrste nastale kao
                    rezultat takvih poslova ili kao rezultat prisutnosti takvih oglašivača koji nisu Teorem na platformi Teorem.
                </p>
                <p>
                    Teorem može prikazivati oglase na web-mjestima koja nisu Teorem kako bi promovirao usluge podučavanja koje pružate putem Teorem
                    Marketplacea i pomogao vam da stvorite više potencijalnih Studenata.
                </p>
                <ul>
                    <h3>8. BEZ PODRAZUMIJEVANIH POTVRDA</h3>
                </ul>
                <p>
                    Ni u kojem slučaju bilo koje pozivanje na bilo koju treću stranu ili proizvod ili uslugu treće strane neće se tumačiti kao
                    odobrenje ili potvrda od strane Teorema te treće strane ili bilo kojeg proizvoda ili usluge koju pruža treća strana. Teorem ne
                    podupire, ne jamči niti jamči za bilo koji proizvod ili uslugu koju nudi bilo koja treća strana i neće biti stranka niti na bilo
                    koji način nadzirati bilo koju transakciju koja uključuje pružatelje proizvoda ili usluga treće strane. Kao i kod kupnje proizvoda
                    ili usluge putem bilo kojeg medija ili u bilo kojem okruženju, odgovorni ste za oprez i dobru prosudbu.
                </p>
                <ul>
                    <h3>9. ODNOSI IZMEĐU TEOREMA I KORISNIKA</h3>
                </ul>
                <p>
                    U pružanju Usluga, Teorem djeluje kao posrednik na zahtjev koji povezuje Studente i Instruktore i pruža alate za olakšavanje
                    usluga podučavanja. Prihvaćate i slažete se da je Teorem pružatelj tehnoloških usluga koji ne pruža usluge online podučavanja.
                </p>
                <p>
                    Teorem ne služi kao poslodavac nijednom Korisniku osim ako to posebno ne podliježe potpisanom, pismenom ugovoru o radu koji
                    potpisuju i Korisnik i Teorem. Korisnici mogu koristiti Usluge samo za pružanje i primanje usluga podučavanja koje podliježu ovim
                    Uvjetima.
                </p>
                <p>
                    Kao takav, Teorem neće biti odgovoran za bilo kakav porez ili zadržavanje, uključujući ali ne ograničavajući se na osiguranje od
                    nezaposlenosti, odgovornost poslodavca, osiguranje radnika, socijalno osiguranje ili porez po odbitku na plaće u vezi s vašim
                    korištenjem Usluga. Isključivo ste vi odgovorni za poštivanje svih primjenjivih poreznih propisa koji se mogu primjenjivati u vezi
                    s vašim korištenjem Usluga. Ovime pristajete nadoknaditi Teoremu sve državne pristojbe, potraživanja, plaćanja, kazne ili druge
                    porezne obveze koje će Teorem imati u vezi s obvezama proizašlim iz primjenjivih poreznih ili drugih propisa koje niste ispunili.
                </p>
                <p>
                    U svim slučajevima korištenja Teorem Marketplacea, Korisnici djeluju kao neovisni ugovaratelji, a ne kao zaposlenici, agenti,
                    primatelji franšize ili službenici Teorema ili bilo koje druge strane. Sukladno tome, vi ćete biti isključivo odgovorni za sve
                    troškove koje imate vi ili vaša organizacija. Ne smijete djelovati kao zaposlenik, agent ili predstavnik Teorema niti obvezivati
                    bilo koji ugovor u ime Teorema. Tamo gdje ćete se, implikacijom obveznog zakona ili na drugi način, smatrati zaposlenikom Teorema,
                    ovime se slažete da se odričete bilo kakvih potraživanja protiv nas koja mogu nastati kao rezultat takvog impliciranog radnog
                    odnosa. Nijedan korisnik nema pravo sudjelovati u bilo kakvom Teoremovom godišnjem odmoru, grupnom zdravstvenom ili životnom
                    osiguranju, invalidnini, udjelu dobiti ili mirovinskim beneficijama ili bilo kojim drugim dodatnim beneficijama ili planovima
                    beneficija koje Teorem nudi svojim zaposlenicima.
                </p>
                <p>
                    Usluge omogućuju povezivanje s Instruktorima koje su Studenti voljni angažirati kao neovisne izvođače. Kao nezavisni ugovaratelji,
                    svaki Instruktor odlučuje kada i koliko često će Instruktor biti dostupan za pružanje usluga podučavanja Studentima. Svaki mentor
                    kontrolira metode, materijale, sadržaj i sve aspekte usluga podučavanja. Usluge dopuštaju Instruktorima da kreiraju usluge
                    podučavanja specifične za određeni predmet, za određeni broj sati.
                </p>
                <p>
                    Studenti su odgovorni za odabir Instruktora koji odgovara njihovim ciljevima učenja. Studenti bi trebali provjeriti vjerodajnice,
                    obrazovanje i iskustvo svakog Instruktora koje je sam prijavio, kao i recenzije drugih Studenata. Svaki Instruktor ima
                    diskrecijsko pravo prihvatiti ili odbiti zahtjev za usluge podučavanja, kao i nastaviti ili prekinuti odnos podučavanja s bilo
                    kojim Studentom.
                </p>
                <p>
                    Predavači mogu i zapravo se od njih očekuje da obavljaju usluge podučavanja za druge ili obavljaju druge vrste poslova (bilo kao
                    neovisni ugovaratelj ili zaposlenik ili drugo) dok su ovi Uvjeti na snazi, uključujući i Teoremove konkurente ako to žele, pod
                    uvjetom da takve druge aktivnosti ne rezultiraju Instruktorovim kršenjem Uvjeta.
                </p>
                <ul>
                    <h3>10. DODJELJIVANJE</h3>
                </ul>
                <p>
                    Ne smijete dodijeliti ili prenijeti svoja prava ili obveze prema ovim Uvjetima u cijelosti ili djelomično bilo kojoj trećoj strani
                    bez pisanog pristanka Teorema. Ovi Uvjeti su obvezujući i vrijede u korist stranaka u ovim Uvjetima i njihovih nasljednika,
                    dopuštenih preuzimatelja i prenositelja.
                </p>
                <ul>
                    <h3>11. POVRATNE INFORMACIJE</h3>
                </ul>
                <p>
                    Prihvaćate i slažete se da vam možemo pružiti mehanizam za pružanje povratnih informacija, prijedloga i ideja o Uslugama ili
                    Teorem Marketplaceu ("Povratne informacije").
                </p>
                <p>
                    Slanjem bilo kakvih povratnih informacija dajete nam pisani pristanak za korištenje vaših povratnih informacija za poboljšanje i
                    promicanje Usluga. Slažete se da je podnošenje Povratnih informacija besplatno, neželjeno i bez ograničenja te da nas neće staviti
                    pod nikakvu fiducijarnu ili drugu obvezu te da smo slobodni koristiti Povratne informacije bez ikakve dodatne naknade vama i/ili
                    otkriti Povratne informacije na ne-povjerljivo ili bilo kome na drugi način.
                </p>
                <p>
                    Nadalje potvrđujete da se, prihvaćanjem vaših Povratnih informacija, Teorem ne odriče nikakvih prava na korištenje sličnih ili
                    srodnih ideja koje su prethodno bile poznate Teoremu, ili koje su razvili njegovi zaposlenici, ili koje su dobivene iz drugih
                    izvora osim vas. Suglasni ste da možemo, prema vlastitom nahođenju, koristiti Povratne informacije koje nam dostavite na bilo koji
                    način, uključujući u budućim poboljšanjima i izmjenama Usluga. Ovime dajete nama trajnu, svjetsku, potpuno prenosivu,
                    podlicenciranu, neopozivu, besplatnu licencu za korištenje, reprodukciju, modificiranje, stvaranje izvedenih radova, distribuciju
                    i prikazivanje povratnih informacija na bilo koji način u bilo koju svrhu, ili bez toga u bilo kojem mediju, softveru ili
                    tehnologiji bilo koje vrste koja sada postoji ili će se razvijati u budućnosti, bez ikakve obveze pružanja atribucije ili naknade
                    vama ili bilo kojoj trećoj strani.
                </p>
                <ul>
                    <h3>12. RECENZIJE</h3>
                </ul>
                <p>
                    Prihvaćate i slažete se da Teorem može izračunati složenu ocjenu na temelju komentara i recenzija drugih Korisnika. Instruktori
                    pristaju da ih Studenti ocjenjuju prema nekoliko kriterija, kao što predlaže Teorem. Teorem pruža svoje automatske povratne
                    informacije i sustav ocjenjivanja kao sredstvo putem kojeg Korisnici mogu javno izraziti svoja mišljenja, a Teorem ne prati niti
                    cenzurira ta mišljenja niti istražuje bilo koje primjedbe koje su objavili korisnici radi točnosti ili pouzdanosti, osim ako
                    Korisnik ne skrene pažnju Teoremu na objavu. Možete biti zakonski odgovorni za štetu koju su pretrpjeli drugi Korisnici ili treće
                    strane kao rezultat ovih primjedbi ako sud utvrdi da su te primjedbe pravno pokrenute ili klevetničke. Teorem nije zakonski
                    odgovoran za bilo kakve povratne informacije ili komentare koje su Korisnici ili treće strane objavili ili učinili dostupnima na
                    Teorem Marketplaceu, čak i ako su te informacije klevetničke ili na neki drugi način pravno pokrenute. Slažete se da ćete odmah
                    prijaviti kršenja ili zlouporabe našeg sustava ocjenjivanja i povratnih informacija kontaktiranjem korisničke podrške.
                </p>
                <ul>
                    <h3>13. OBAVIJESTI</h3>
                </ul>
                <p>
                    Osim ako pismeno ne naznačite drugačije, Teorem će s vama komunicirati putem e-pošte, obične pošte ili objavljivanjem komunikacija
                    na Teorem Marketplaceu. Pristajete primati komunikacije od nas elektroničkim putem i slažete se da te elektroničke komunikacije
                    zadovoljavaju sve zakonske uvjete da takve komunikacije budu u pisanom obliku. Smatrat će se da ste primili komunikaciju kada je
                    pošaljemo na adresu e-pošte koju ste dali Teoremu unutar svog računa ili kada takvu komunikaciju objavimo na Teorem Marketplaceu.
                    Trebali biste ažurirati svoju adresu e-pošte na svom računu i redovito provjeravati ovu Web-stranicu za postove. Ako ne odgovorite
                    na poruku e-pošte od Teorema u vezi s kršenjem, osporavanjem ili pritužbom u roku od 2 (dva) radna dana, imat ćemo pravo ukinuti
                    ili suspendirati vaš račun. Sve obavijesti Teorema koje imaju pravni učinak u vezi s ovim Uvjetima moraju biti u pisanom obliku i
                    dostavljene osobno ili putem dostavnice na sljedeću adresu: Masarykova ulica 25, Zagreb, 10000, Hrvatska.
                </p>
                <p>
                    Da biste prestali primati određene komunikacije od Teorema, pošaljite nam obavijest e-poštom na
                    <a href={'mailto:support@teorem.co'}> support@teorem.co</a> kako bismo promijenili vrste i učestalost takve komunikacije. Također
                    možete promijeniti postavke obavijesti na svom računu.
                </p>
                <ul>
                    <h3>14. RASKID</h3>
                </ul>
                <p>
                    Osim Instruktora, možemo prekinuti pristup Teorem Marketplaceu bilo kojem Korisniku prema vlastitom nahođenju, iz bilo kojeg
                    razloga i u bilo koje vrijeme, sa ili bez prethodne obavijesti. Naše je pravo isključiti Korisnike koji krše ove Uvjete, prema
                    vlastitom nahođenju.
                </p>
                <p>
                    Možemo prekinuti Instruktorovo korištenje Usluga (i) odmah zbog nepoštivanja Uvjeta, uključujući
                    <a href={'/hr/kodeks-ponasanja-korisnika'}> Kodeks ponašanja korisnika</a>, koji se smatra materijalnim kršenjem ugovora; (ii) iz
                    drugih razloga, uključujući, ali ne ograničavajući se na seksualno ili drugo nepoželjno uznemiravanje, prijetnje ili
                    zastrašivanje, prijevaru, krivotvorenje dokumenata ili kvalifikacija; ili (iii) nakon 30 dana unaprijed pismene obavijesti iz bilo
                    kojeg razloga.
                </p>
                <p>
                    Također možemo izbrisati ili ograničiti pristup ili korištenje svih povezanih informacija i datoteka. Teorem neće biti odgovoran
                    Korisnicima ili bilo kojoj trećoj strani za bilo kakve izmjene, obustavu ili prekid usluge ili gubitak povezanih informacija.
                </p>
                <p>
                    U slučaju da Teorem suspendira ili ukine vaš račun zbog kršenja ovih Uvjeta ili bilo kojeg od Teoremovih pravila, razumijete i
                    slažete se da nećete dobiti nikakav povrat novca ili kompenzaciju za neiskorištena sredstva ili zakazane lekcije/sate, ili gubitak
                    bilo kakvog sadržaja ili informacija povezan s vašim računom. Uz gore navedeno, Teorem ima pravo zadržati sva sredstva preostala
                    na vašem računu kao ugovorenu kaznu.
                </p>
                <p>
                    U slučaju da se niste prijavili na svoj Teorem račun dulje od 180 dana, vaš će račun biti suspendiran, a vaš preostali saldo će
                    isteći.
                </p>
                <p>Čak i nakon što je vaše pravo na korištenje usluga ukinuto ili obustavljeno, ovi će uvjeti ostati primjenjivi protiv vas.</p>
                <ul>
                    <h3>15. PRAVA INTELEKTUALNOG VLASNIŠTVA</h3>
                </ul>
                <p>
                    Svo intelektualno vlasništvo u dizajnu i izgledu Teorem Marketplacea te materijala i informacija objavljenih na stranicama
                    Web-stranice ili unutar funkcionalnosti Teorem Marketplacea pripada Teoremu ili njegovim davateljima licence i dodijeljeno im je.
                    Ne smijete kopirati bilo koji dio Teorem Marketplacea ili na bilo koji drugi način činiti bilo što u vezi s bilo kojim dijelom
                    Teorem Marketplacea. Ne smijete na bilo koji drugi način koristiti ili reproducirati bilo koji dio Teorem Marketplacea ili
                    materijal sadržan u njemu na bilo koji drugi način osim gore navedenih bez prethodnog dobivanja pismenog dopuštenja Teorema.
                </p>
                <p>
                    Osim ako nije drugačije navedeno, sav Teorem Sadržaj sadržan na Teorem Marketplaceu vlasništvo je Teorema i/ili njegovih
                    podružnica ili davatelja licence i zaštićen je od neovlaštenog kopiranja i širenja zakonom o autorskim pravima Ujedinjenog
                    Kraljevstva, zakonom o zaštitnim znakovima, međunarodnim konvencijama i drugim zakonima o intelektualnom vlasništvu. Oznake usluge
                    i zaštitni znakovi Teorema, uključujući bez ograničenja Teorem i logotipe Teorema su oznake usluga u vlasništvu Teorem Ltd. Svi
                    drugi zaštitni znakovi, oznake usluga, logotipi i/ili trgovačka imena koja se pojavljuju putem Usluge vlasništvo su njihovih
                    vlasnika. Ne smijete kopirati ili koristiti bilo koji od ovih znakova, logotipa ili trgovačkih naziva bez izričitog prethodnog
                    pismenog pristanka vlasnika.
                </p>
                <p>
                    Ne smijete postavljati poveznice ili okvire na bilo koje stranice Web-stranice ili bilo koji Sadržaj Teorema koji se tamo nalazi,
                    u cijelosti ili djelomično, bez prethodnog pisanog pristanka Teorema. Možete lajkati ili pratiti Teorem ili dijeliti poveznice do
                    Web-stranice putem tehnologije društvenog umrežavanja navedene na Web-stranici. Pridržana su sva prava koja ovdje nisu izričito
                    navedena.
                </p>
                <ul>
                    <h3>16. POVJERLJIVOST</h3>
                </ul>
                <p>
                    Putem Usluga možete dobiti izravan pristup određenim povjerljivim informacijama Teorema, njegovih podružnica ili Korisnika,
                    uključujući, ali ne ograničavajući se na osobne podatke, tehničke, ugovorne, proizvodne, programske, cjenovne, marketinške i druge
                    vrijedne informacije koje treba razumno shvatiti kao povjerljive (“Povjerljive informacije”). Suglasni ste da ćete Povjerljive
                    informacije držati u strogoj tajnosti i da ih nećete koristiti osim u svrhe navedene u ovim Uvjetima i da takve Povjerljive
                    informacije nećete otkrivati trećoj strani. Sva prava, vlasništvo i udjeli u Povjerljivim informacijama ostaju Teoremu, njegovim
                    podružnicama i korisnicima. Ne nameću vam se nikakve obveze u pogledu Povjerljivih informacija koje možete utvrditi pravno
                    dostatnim dokazima: (a) koje ste posjedovali prije nego što ste ih primili od Teorema, bez obveze održavanja njihove
                    povjerljivosti; (b) je ili postane opće poznato javnosti bez vašeg čina ili propusta ili na drugi način bez kršenja Uvjeta; (c)
                    koje ste dobili od treće strane koja je imala pravo otkriti ih, bez obveze čuvanja povjerljivosti takvih informacija; (d) koje ste
                    samostalno razvili bez korištenja Povjerljivih informacija i bez sudjelovanja pojedinaca koji su im imali pristup, ili (e) je
                    otkrivena kao odgovor na važeći nalog suda ili drugog državnog tijela, ili kako to na drugi način zahtijeva zakona, ili ako je
                    potrebno za utvrđivanje prava bilo koje strane u skladu s ovim Uvjetima i kako je otkriveno nakon prethodne obavijesti Teoremu
                    kako bi se Teoremu pružila mogućnost prigovora na otkrivanje.
                </p>
                <ul>
                    <h3>17. ODRICANJE OD JAMSTVA</h3>
                </ul>
                <p>
                    Korištenje Usluga je u potpunosti na vlastitu odgovornost. Teorem se odriče svake odgovornosti u vezi s bilo kakvim interakcijama,
                    korespondencijom, transakcijama i drugim poslovima koje imate s trećim stranama, uključujući, bez ograničenja, Studente ili
                    Instruktore koji se nalaze na ili putem Teorem Marketplacea (uključujući na ili putem povezanih web stranica ili oglasa)
                    isključivo su između vas i treće strane (uključujući pitanja vezana uz sadržaj oglasa trećih strana, plaćanja, usluge, isporuku
                    robe, jamstva (uključujući jamstva za proizvode), privatnost i sigurnost podataka i slično). Ni pod kojim okolnostima nećemo biti
                    odgovorni za bilo kakav gubitak ili štetu uzrokovanu vašim oslanjanjem na informacije u bilo kojem sadržaju na Teorem
                    Marketplaceu. Vaša je odgovornost procijeniti točnost, potpunost ili korisnost bilo koje informacije, mišljenja, savjeta ili
                    drugog sadržaja dostupnog putem Teorem Marketplacea.
                </p>
                <p>
                    Prihvaćate da su Teorem Marketplace i sve Usluge, tekstovi, slike i druge informacije na Teorem Marketplaceu ili kojima se može
                    pristupiti dostupni "kakvi jesu" i da se djelomično temelje na popisima koje pružaju Tutori, a koje Teorem ne ovjerava, te da sve
                    usluge podučavanja, popisi ili drugi sadržaji stečeni korištenjem Teorem Marketplacea su na vašu isključivu odgovornost i
                    diskreciju. Teorem i njegove podružnice i davatelji licence nisu odgovorni ni za kakve rezultate generirane korištenjem Usluga. Ne
                    dajemo nikakva jamstva bilo koje vrste, bilo izričita ili prešutna, uključujući, ali ne ograničavajući se na prešutna jamstva o
                    utrživosti, prikladnosti za određenu svrhu i nekršenju. Konkretno, ali bez ograničenja, Teorem ne jamči da: (i) informacije
                    dostupne na Teorem Marketplaceu ne sadrže pogreške; (ii) funkcije ili usluge (uključujući ali ne ograničavajući se na mehanizme za
                    preuzimanje i učitavanje sadržaja) koje pruža Teorem Marketplace bit će neprekinute, sigurne ili bez pogrešaka; (iii) nedostaci će
                    biti ispravljeni, ili (iv) Teorem Marketplace ili poslužitelj(i) koji ga čine dostupnim ne sadrže viruse ili druge štetne
                    komponente. Ni Teorem ni njegove podružnice ili davatelji licence nisu odgovorni za ponašanje između Korisnika, bilo online ili
                    offline.
                </p>
                <p>
                    Osim toga, bez obzira na bilo koju značajku koju Student može koristiti za ubrzavanje odabira Instruktora, svaki je Student
                    odgovoran za odabir svog Instruktora i pregovaranje o ugovoru, a Teorem ne jamči za robu ili usluge podučavanja koje je kupio
                    Student i ne preporučuje nijednog određenog Instruktora. Teorem ne daje nikakva jamstva u vezi s profesionalnom akreditacijom,
                    registracijom ili licencom bilo kojeg Instruktora.
                </p>
                <p>
                    Teorem se izričito odriče bilo kakve odgovornosti ili potraživanja koja mogu nastati između Korisnika njegovih Usluga. Vi ste
                    isključivo odgovorni za svoje interakcije sa svim drugim Korisnicima i sve sporove koji nastanu iz tih interakcija s drugim
                    Korisnicima. Teorem nije obvezan, ali može pokušati pomoći u rješavanju sporova između Korisnika.
                </p>
                <ul>
                    <h3>18. OGRANIČENJE ODGOVORNOSTI</h3>
                </ul>
                <p>
                    a) Odricanje od odgovornosti. Ni u kojem slučaju Teorem neće biti odgovoran bilo kojem korisniku Teorem Marketplacea ili bilo
                    kojoj drugoj osobi ili subjektu za bilo kakvu izravnu, neizravnu, posebnu, slučajnu, posljedičnu ili egzemplarnu štetu
                    (uključujući, ali ne ograničavajući se na, štetu zbog izgubljene dobiti, gubitka podataka, gubitak korištenja ili troškovi
                    dobivanja zamjenske robe ili usluga) koji proizlaze iz korištenja, nemogućnosti korištenja, neovlaštenog pristupa ili korištenja
                    ili zlouporabe Teorem Marketplace ili bilo koje informacije sadržane na njemu, bilo da se temelje na jamstvu, ugovoru, deliktu
                    (uključujući nemar) ili na neki drugi način, čak i ako je upozoren na mogućnost takve štete ili gubitaka.
                </p>
                <p>
                    b) Ograničenje. Suglasni ste da ukupna kumulativna odgovornost Teorema u vezi s ovim Uvjetima, Teorem Marketplacea, Uslugama,
                    sadržajem Teorema ili bilo kojim popisom ili uslugama, bilo u ugovoru, deliktu ili na neki drugi način, neće premašiti iznose, ako
                    ih ima, koje ste platili Teorem za usluge u prethodna tri mjeseca.
                </p>
                <p>
                    c) Odricanje od grupne tužbe. Sve tužbe koje podnesete vi ili Teorem moraju se podnijeti u osobnom svojstvu te strane, a ne kao
                    tužitelj ili član klase u bilo kojem navodnom klasnom ili zastupničkom postupku.
                </p>
                <ul>
                    <h3>19. ISKLJUČENJA</h3>
                </ul>
                <p>
                    Neke jurisdikcije ne dopuštaju isključivanje određenih jamstava ili ograničenje ili isključenje odgovornosti za slučajne ili
                    posljedične štete. Sukladno tome, neka od gore navedenih ograničenja možda se ne odnose na vas.
                </p>
                <ul>
                    <h3>20. MEĐUNARODNI</h3>
                </ul>
                <p>
                    Teorem Marketplaceu se može pristupiti iz drugih zemalja osim Ujedinjenog Kraljevstva. Teorem Marketplace i usluge mogu sadržavati
                    proizvode ili reference na proizvode koji su dostupni samo u Ujedinjenom Kraljevstvu. Takve reference ne znače da će takvi
                    proizvodi biti dostupni izvan Ujedinjenog Kraljevstva.
                </p>
                <p>
                    Ako pristupite i koristite Teorem Marketplace izvan Ujedinjenog Kraljevstva, odgovorni ste za poštivanje svih primjenjivih
                    lokalnih zakona i propisa.
                </p>
                <p>
                    Ne izjavljujemo da su informacije na Teorem Marketplaceu prikladne ili dostupne za korištenje izvan Ujedinjenog Kraljevstva. Oni
                    koji odluče pristupiti Teorem Marketplaceu izvan Ujedinjenog Kraljevstva čine to na vlastitu inicijativu i na vlastiti rizik.
                </p>
                <ul>
                    <h3>21. LIKVIDIRANA ŠTETA</h3>
                </ul>
                <p>
                    Teorem i Korisnik ovime prihvaćaju i suglasni su da će sredstva koja mogu biti zadržana prema Odjeljku 14 ovih Uvjeta
                    predstavljati ugovorenu štetu, a ne kazne, te su dodatak svim ostalim pravima Teorema u slučaju kršenja ovih Uvjeta. Teorem i
                    Korisnik nadalje prihvaćaju da (i) iznos gubitka ili štete koji će vjerojatno nastati nije moguće ili ga je teško precizno
                    procijeniti, (ii) iznosi navedeni u gore navedenom odjeljku imaju razuman odnos s, i nisu jasno ili krajnje nerazmjeran
                    vjerojatnom gubitku koji bi mogao nastati u vezi s bilo kojim materijalnim kršenjem ugovora od strane Korisnika (iii) jedan od
                    razloga zašto su Teorem i Korisnik postigli dogovor o takvim iznosima bila je neizvjesnost i trošak parnice u vezi s pitanjem
                    stvarne štete.
                </p>
                <ul>
                    <h3>22. ODŠTETA</h3>
                </ul>
                <p>
                    Korištenjem Usluga pristajete na obeštetu, oslobađanje od odgovornosti i obranu Teorema i njegovih podružnica, podružnica,
                    dioničara, službenika, direktora, agenata, davatelja licence, dobavljača, drugih partnera, zaposlenika i predstavnika od bilo
                    kakvih zahtjeva, šteta, gubitaka, odgovornosti, i sve troškove i izdatke obrane, uključujući, ali ne ograničavajući se na,
                    odvjetničke naknade, koji izravno ili neizravno proizlaze iz tužbe treće strane koja nastaje u vezi s vašim korištenjem Usluga,
                    uključujući, ali ne ograničavajući se na (a) radnje i /ili propusti na ili izvan Teorem Marketplacea; (b) kršenje bilo kojeg tuđeg
                    prava, uključujući bez ograničenja svako navodno kršenje intelektualnog vlasništva ili drugog prava bilo koje osobe ili subjekta u
                    vezi s Teorem Marketplaceom; (c) kršenje ovih Uvjeta; (d) sporovi sa ili između drugih Korisnika; (e) korištenje i/ili zlouporaba
                    Teorem Marketplacea, uključujući bez ograničenja sve informacije, sadržaj i/ili materijale na njemu; (f) kršenje bilo kojeg
                    važećeg zakona ili propisa; (g) netočne, nepravodobne, nepotpune ili obmanjujuće informacije o korisniku, uključujući bez
                    ograničenja u pogledu registracije, profila ili podobnosti; (h) pogrešne izjave i/ili lažne izjave; (i) korištenje poveznica na
                    web stranice trećih strana, uključujući bez ograničenja dostupnost takvih web stranica, uvjete korištenja, politiku privatnosti,
                    informacije, sadržaj, materijale, oglašavanje, proizvode i/ili usluge; (j) Podaci o Korisniku i sve radnje ili propusti u vezi s
                    takvim podacima o Korisniku; (k) korištenje bilo kojih informacija u izvješćima trećih strana; (l) korištenje procesora plaćanja
                    trećih strana; i/ili (m) korištenje bilo kojih usluga ili proizvoda ili bilo kakvih ugovora ili dogovora napravljenih ili
                    osiguranih na temelju informacija, sadržaja i/ili materijala dobivenih na ili putem Teorem Marketplacea. Nadalje se slažete da
                    ćete surađivati kako Teorem zahtijeva u obrani od takvih tvrdnji. Teorem zadržava pravo, o vlastitom trošku, preuzeti isključivu
                    obranu i kontrolu nad bilo kojim pitanjem koje inače podliježe obeštećenju od strane Korisnika, a vi ni u kojem slučaju nećete
                    rješavati bilo kakve zahtjeve ili stvari u ime Teorema bez pisanog pristanka Teorem.
                </p>
                <p>
                    U slučaju da imate spor s bilo kojim Korisnikom, ovime oslobađate Teorem (i njegove službenike, direktore, agente, investitore,
                    podružnice, zaposlenike, izvođače i sve druge treće strane povezane s Uslugama) od bilo kakvih i svih tužbi, zahtjeva , ili štete
                    (stvarne ili posljedične) svake vrste, poznate i nepoznate, proizašle iz ili na bilo koji način povezane s takvim sporovima.
                </p>
                <ul>
                    <h3>23. ZAKON I NADLEŽNOST</h3>
                </ul>
                <p>
                    Ovi Uvjeti i odredbe te odnos između vas i nas (bilo ugovorni ili neki drugi) bit će uređeni i tumačeni u skladu s engleskim
                    pravom. Ako ste potrošač, imat ćete koristi od svih obveznih odredbi zakona u vašoj zemlji prebivališta. Ako ste potrošač, svaki
                    spor, kontroverza, postupak ili zahtjev između vas i nas koji se odnosi na ove Uvjete i odredbe ili na odnos između vas i nas
                    (bilo ugovorni ili neki drugi) bit će podložan nadležnosti sudova Engleske, Wales, Škotska ili Sjeverna Irska, prema vašem
                    prebivalištu. Ako ste poslovni korisnik, svaki spor, prijepor, postupak ili zahtjev između vas i nas koji se odnosi na ove Uvjete
                    i odredbe ili na odnos između vas i nas (bilo ugovorni ili neki drugi) podliježe isključivoj nadležnosti sudova Engleske i Walesa.
                </p>
                <ul>
                    <h3>24. NETRAŽENJE</h3>
                </ul>
                <p>
                    Bez ograničenja, Usluge se ne smiju koristiti za traženje bilo koje druge tvrtke, web stranice ili usluge. Ne smijete tražiti,
                    oglašavati ili kontaktirati u bilo kojem obliku korisnike radi zapošljavanja, ugovaranja ili bilo koje druge svrhe koja nije
                    povezana s uslugom koju pruža Teorem bez izričitog pismenog dopuštenja Teoreme.
                </p>
                <p>
                    Ne smijete koristiti Uslugu za prikupljanje korisničkih imena i/ili adresa e-pošte korisnika elektroničkim ili drugim sredstvima
                    bez izričitog prethodnog pisanog pristanka Teorema.
                </p>
                <ul>
                    <h3>25. NASLOVI </h3>
                </ul>
                <p>
                    Naslovi i opisi koji se koriste u ovim Uvjetima koriste se samo radi praktičnosti i ne uzimaju se u obzir pri tumačenju ili
                    sastavljanju ovih Uvjeta.
                </p>
                <ul>
                    <h3>26. RASKIDIVOST CIJELOG UGOVORA</h3>
                </ul>
                <p>
                    Ovi Uvjeti, zajedno sa svim izmjenama i dodatnim ugovorima koje možete sklopiti s Teoremom u vezi s Uslugama, činit će cjelokupni
                    ugovor između vas i Teorema u vezi s Uslugama. Ako se bilo koji dio ovih Uvjeta smatra nezakonitim, ništavim ili neprovedivim, taj
                    dio će se smatrati odvojivim i neće utjecati na valjanost i provedivost preostalih odredbi.
                </p>
                <ul>
                    <h3>27. KONTAKTI</h3>
                </ul>
                <p>
                    Za dodatne informacije i u slučaju da imate pitanja o ovim Uvjetima, obratite se na
                    <a href={'mailto:support@teorem.co'}> support@teorem.co</a>.
                </p>
            </div>
        </LandingWrapper>
    );
};

export default Terms_HR;
