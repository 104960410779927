export const HR_HR = {
    MAIN_TITLE: 'Teorem',
    WATERMARK: '© Teorem',
    BIRTH_DATE_FORMAT: 'DD/MM/YYYY',
    DATE_FORMAT: 'DD. MMM YYYY',
    ERROR_HANDLING: {
        UNHANDLED_ERROR: 'Došlo je do neočekivane pogreške!',
        SUPPORT: 'Nešto je pošlo po zlu, molimo Vas kontaktirajte podršku',
        CREDIT_CARD_MISSING: 'Nedostaju podatci kartice. Molimo dodajte način plaćanja prije kreiranja rezervacije.',
        DEFAULT_CARD_MISSING: 'Molimo odredite jednu od svojih kartica kao standardni način plaćanja.',
        NO_CHILD_USERNAME: 'Ne postoji dijete s danim korisničkim imenom',
        REGISTERED_SUCCESSFULLY: 'Registracija uspješna.',
    },
    TRIAL_POPUP: {
        WELCOME: 'Welcome to Teorem',
        DESCRIPTION: 'Your profile is now ready to use. Fill out the agenda, sit back and relax, wait for students to reach out to you!',
        EXPIRATION: 'Your free trial will expire in 30 days!',
        REMIND: 'Don’t worry, we’ll remind you on time.',
        CONFIRM_BUTTON: 'Got it',
    },
    EMAIL_CONFIRMATION_POPUP: {
        WELCOME: 'Dobrodošli na Teorem!',
        DESCRIPTION: 'Molimo Vas potvrdite Vašu e-mail adresu klikom na link koji smo Vam poslali.',
        EXPIRATION: 'Ponovno pošalji email za potvrdu',
        RESEND_TIMEOUT: 'Probajte ponovno za',
        CONFIRM_BUTTON: 'U redu',
    },
    ONBOARDING: {
        NAVIGATION: {
            TITLE: 'Dobrodošli na Teorem!',
            DESCRIPTION: 'Molimo Vas pratite proces kako bi završili izradu profila. Proces traje samo nekoliko minuta.',
            STEP_ONE: {
                TITLE: 'Osobni podatci',
                DESCRIPTION: 'Dopustite nam da Vas upoznamo malo bolje',
            },
            TUTOR: {
                STEP_TWO: {
                    TITLE: 'Podatci za plaćanje',
                    DESCRIPTION: 'Nikada nije previše podataka',
                },
            },
            PARENT: {
                STEP_TWO: {
                    TITLE: 'Podatci o djeci',
                    DESCRIPTION: 'Ispunite podatke za Vaše dijete i odredite njegove/njene podatke za prijavu.',
                },
            },
        },
        EDIT_CHILD_DETAILS: 'Izmijenite podatke o djetetu',
    },
    FORM_VALIDATION: {
        INVALID_EMAIL: 'Neispravan email',
        INVALID_IMAGE: 'Slika mora biti formata jpg, png, jpeg ili svg i manja od 2MB veličine.',
        IMAGE_TYPE: 'Slika mora biti formata jpg, png, jpeg ili svg',
        IMAGE_SIZE: 'Slika mora biti manja od 2MB veličine.',
        TOO_SHORT: 'Pretratko!',
        TOO_LONG: 'Predugo!',
        TOO_BIG: 'Broj je prevelik',
        REQUIRED: 'Ovo polje je neophodno',
        LOWERCASE: 'Sadržavati barem jedno malo slovo (a-z)',
        UPPERCASE: 'Sadržavati barem jedno veliko slovo (A-Z)',
        NUMBER: 'Sadržavati barem jednu brojku (0-9)',
        MIN_CHARACTERS: 'Sadržavati najmanje 8 znakova',
        SPECIAL_CHAR: 'Sadržavati barem jedan poseban znak (@, $, !, %, *, ?, &...)',
        PASSWORD_MUST: 'Lozinka mora',
        PASSWORD_MATCH: 'Lozinke se moraju poklapati',
        PASSWORD_STRENGTH: 'Lozinka mora ispunjavati uvjete',
        PRICE: 'Cijena ne može biti ispod ',
        FUTURE_DATE: 'Datum ne može biti u budućnosti',
        VALID_DATE: 'Datum je nevažeći',
        PHONE_SHORT: 'Broj telefona mora sadržavati barem 10 znamenaka',
        PHONE_NUMBER: 'Neispravan broj telefona',
        TUTOR_AGE: 'Instruktor mora imati više od 18 godina',
        WRONG_REQUIREMENTS: 'Niste ispunili sva potrebna polja',
        NEGATIVE: 'Ne može biti negativan broj',
        USERNAME_EXIST: 'Korisničko ime već postoji',
        TOAST: {
            UPDATE: 'Uspješno ste ažurirali podatke o djetetu',
            CREATE: 'Uspješno ste dodali dijete',
            DELETE: 'Uspješno ste uklonili dijete',
        },
        LEVEL_REQUIRED: 'Razina je obvezna',
        SUBJECT_REQUIRED: 'Predmet je obvezan',
        CHILD_REQUIRED: 'Dijete je obvezno',
        EMAIL_IN_PASSWORD: 'Ne smije sadržavati email adresu',
        NAME_IN_PASSWORD: 'Ne smije sadržavati ime',
        NAME_EMAIL_NOT_IN_PASSWORD: 'Ne sadržava ni ime ni email adresu',
    },
    NAVIGATION: {
        MY_BOOKINGS: 'Kalendar',
        MY_REVIEWS: 'Moje recenzije',
        SEARCH_TUTORS: 'Pretraga instruktora',
        TUTOR_MANAGMENT: 'Upravljanje instruktorima',
        MY_PROFILE: 'Moj profil',
        COMPLETED_LESSONS: 'Završene lekcije',
        CHAT: 'Razgovori',
        DASHBOARD: 'Upravljačka ploča',
        EARNINGS: 'Prihod',
        SHARE_PROFILE: 'Podijeli profil',
        TEXT_COPIED: 'Profil kopiran!',
    },
    SEARCH_TUTORS: {
        AVAILABILITY: {
            TIME_OF_DAY: {
                LABEL: 'Vrijeme dana',
                BEFORE_NOON: 'Prije 12:00',
                NOON_TO_FIVE: '12:00 - 17:00',
                AFTER_FIVE: 'Nakon 17:00',
            },
            DAY_OF_WEEK: {
                LABEL: 'Dan u tjednu',
                MON: 'pon',
                TUE: 'uto',
                WED: 'sri',
                THU: 'čet',
                FRI: 'pet',
                SAT: 'sub',
                SUN: 'ned',
            },
        },
        PLACEHOLDER: {
            LEVEL: 'Razina',
            SUBJECT: 'Predmet',
            AVAILABILITY: 'Dostupnost',
        },
        NO_OPTIONS_MESSAGE: 'Molimo prvo odaberite razinu',
        TITLE: 'Pretraga instruktora',
        VIEW_PROFILE: 'Pogledaj Profil',
        BOOK_LESSON: 'Rezerviraj',
        RESET_FILTER: 'Resetiraj pretragu',
        TUTOR_AVAILABLE: 'Dostupni instruktori',
        COMPLETED_LESSONS: 'završenih lekcija',
        NO_REVIEWS: 'Još nema recenzija',
        NO_COMPLETED_LESSONS: 'Još nema završenih lekcija',
        NO_VIDEO: 'Instruktor još nije učitao video',
        SUBJECT_LIST: {
            MORE: 'više',
        },
        NO_RESULT: {
            TITLE: 'Bez rezultata',
            DESC: ' Hmm, izgleda da nema instruktora za ovu određenu pretragu.',
        },
        NOT_FILLED: 'Ovi podatci još nisu ispunjeni',
        YEARS_OF_EXPERIENCE: 'Godine iskustva: ',
        NUMBER_OF_REVIEWS: 'ocjena',
        TUTOR_PROFILE: {
            ABOUT_ME: 'O meni',
            ABOUT_TEACHINGS: 'O mojim predavanjima',
            EMPTY_STATE_ABOUT: 'Ovaj instruktor nije ispunio odjeljak "O meni"',
            EMPTY_STATE_LESSON: 'Ovaj instruktor nije ispunio odjeljak "O predmetima"',
            ADDITIONAL_INFORMATION_TITLE: 'O meni',
            ADDITIONAL_INFORMATION_DESC: 'Izmjena i ažuriranje sekcije "O meni" Vašeg profila.',
            UPDATE_ADDITIONAL_INFO_SUCCESS: 'Dodatni podatci ažurirani!',
            UPDATE_TEACHINGS_SUCCESS: 'Predmeti ažurirani!',
            LESSON_LENGTH: '50 min predavanje',
            FORM: {
                ABOUT_TUTOR_LABEL: 'Recite nam više o sebi*',
                ABOUT_TUTOR_PLACEHOLDER: 'Što Vas najbolje opisuje, koji su Vaši hobiji, pristup...',
                ABOUT_TUTOR_REQUEST: 'Najmanje 50 riječi',
                ABOUT_TUTOR_TOOLTIP: 'Detaljno opišite sebe, svoje interese, iskustvo i hobije.',
                TOOLTIP_EFFECTIVE: 'Najučinkovitiji profili sadrže 100+ riječi u ovoj rubrici.',
                ABOUT_LESSONS_LABEL: 'Recite nam više o Vašim predavanjima*',
                ABOUT_LESSONS_PLACEHOLDER: 'Opišite Vaša predavanja, pristup učenju...',
                ABOUT_LESSONS_TOOLTIP: 'Detaljno opišite na koji način radite s učenicima.',
                ABOUT_LESSONS_TOOLTIP_2: 'Opišite koje metode podučavanja koristite (vizualne, auditivne, kinestetičke, itd.)',
                SUBMIT_BTN: 'Spremanje',
            },
            NO_PRICE: 'Cijena nije navedena',
            HOUR: 'sat',
        },
        PRICE_SORT: 'Sortirati po',
        SORT_LOW: 'Najnižoj cijeni',
        SORT_HIGH: 'Najvišoj cijeni',
        SORT_NONE: 'Bez sortiranja',
    },
    TUTOR_MANAGMENT: {
        TITLE: 'Popis instruktora',
        UNPROCESSED: 'NEOBRAĐENO',
        APPROVED: 'ODOBRENO',
        DENIED: 'ODBIJENO',
        SUBJECT_LIST: {
            MORE: 'više',
        },
        NO_RESULT: {
            TITLE: 'Nema rezultata',
            DESC: ' Hmm, izgleda da nema instruktora za ovu određenu pretragu.',
        },
        TABLE: {
            FIRST_NAME: 'IME',
            LAST_NAME: 'PREZIME',
            EMAIL: 'EMAIL',
            COUNTRY: 'DRŽAVA',
            DATE_OF_BIRTH: 'DATUM ROĐENJA',
            PREVIEW_PROFILE: 'Pregled profila',
            PHONE_NUMBER: 'BROJ TELEFONA',
        },
        ACTIONS: {
            APPROVE: 'Odobri',
            DECLINE: 'Odbij',
            DELETE: 'Izbriši',
            APPROVE_TUTOR: 'Odobri instruktora',
            DECLINE_TUTOR: 'Odbij instruktora',
            DELETE_TUTOR: 'Izbriši instruktora',
            EDIT_NOTE: 'Uredi bilješku',
            CANCEL: 'Odustani',
        },
        NOTE: 'Bilješka',
        NOTE_PLACEHOLDER: 'Unesi bilješku...',
        DETAILS: ' DETALJI',
        SEARCH_PLACEHOLDER: 'Pronađi instruktora',
    },
    CHAT: {
        PLACEHOLDER: 'Pošaljite poruku kako biste započeli razgovor',
        SEARCH_PLACEHOLDER: 'Pretražite razgovor',
        BOOK_SESSION: 'Rezervirajte lekciju',
        FREE_CONSULTATION: 'Započnite besplatan poziv',
        ACCEPT_CONSULTATION: 'Prihvati',
        DENY_CONSULTATION: 'Odbij',
        DENY_FREE_CONSULTATION: 'Odustanite od poziva',
        CHAT_REQUEST_TITLE: 'Dolazni videopoziv',
        CHAT_MISSED_CALL: '<span class="chat-missed-incoming-message">Propušten poziv od korisnika userInsert={user}</span>',
    },
    ROLE_SELECTION: {
        TITLE: 'Registracija',
        ACTION: 'Želim se registrirati kao',
        ACCOUNT: 'Već imate korisnički račun?',
        LOG_IN: 'Prijavite se',
        STUDENT_TITLE: 'Učenik',
        STUDENT_DESCRIPTION: 'Ovdje sam da proširim svoje znanje.',
        PARENT_TITLE: 'Roditelj / Skrbnik',
        PARENT_DESCRIPTION: 'Ovdje sam za svoje dijete.',
        TUTOR_TITLE: 'Instruktor',
        TUTOR_DESCRIPTION: 'Ovdje sam da podučavam učenike.',
    },
    RESET_PASSWORD: {
        TITLE: 'Zaboravili ste lozinku?',
        FORM: {
            EMAIL: 'Email',
            SUBMIT_BTN: 'Resetiraj lozinku',
        },
        SUCCESS: 'Uspješno ste resetirali lozinku. Molimo provjerite svoj email.',
        BACK_BTN: 'Povratak na prijavu',
    },
    LOGIN: {
        TITLE: 'Prijava',
        FORM: {
            EMAIL: 'Email',
            PASSWORD: 'Lozinka',
            SUBMIT_BTN: 'Prijava',
        },
        FORGOT_PASSWORD: 'Zaboravili ste lozinku?',
        ACCOUNT: 'Nemate korisnički račun?',
        REGISTER: 'Registrirajte se',
    },
    BACKEND_ERRORS: {
        BASE: {
            UNAUTHORIZED: 'Niste ovlašteni.',
            FORBIDDEN: 'Nemate dopuštenje.',
            NOT_FOUND: 'Resurs nije pronađen',
            CONFLICT: 'Resurs već postoji',
            INTERNAL_ERROR: 'Pogreška servera',
            BAD_REQUEST: 'Loš zahtjev',
        },
        USER: {
            EMAIL_CONFLICT: 'Korisnik s ovom email adresom već postoji',
            LOGIN_WRONG_FORM_DATA: {
                BODY: 'Pogrešni podaci. Molimo vas pokušajte ponovo.',
                TITLE: 'Probajmo još jednom',
            },
            NOT_FOUND: 'Korisnik nije pronađen',
            USERNAME_CONFLICT: 'Korisničko ime već postoji',
            PROFILE_NOT_ACTIVE: {
                SENT_AGAIN: 'Molimo potvrdite vašu e-mail adresu.',
            },
        },
        LEVEL: {
            NOT_FOUND: 'Razina nije pronađena',
        },
        FILE: {
            NOT_FOUND: 'Dokument nije pronađen',
        },
        SUBJECT: {
            NOT_FOUND: 'Predmet nije pronađen',
        },
        TUTOR: {
            NOT_FOUND: 'Instruktor nije pronađen',
        },
        TUTOR_AVAILABILITY: {
            CONFLICT: 'Dostupnost instruktora već je određena',
            NOT_FOUND: 'Dostupnost instruktora nije pronađena',
        },
        BOOKING: {
            NOT_FOUND: 'Rezervacija nije pronađena',
        },
        ROLE: {
            NOT_FOUND: 'Uloga nije pronađena',
        },
        REVIEW: {
            CONFLICT: 'Već ste napisali recenziju',
        },
        INPUT_FAILED: 'Pogrešni ulazni podatci',
        INVALID_IMAGE: 'Nevažeća slika',
    },
    NOT_FOUND: {
        TITLE: 'Stranica nije pronađena',
        SUBTITLE: ' Oprostite, izgleda da smo Vas poslali pogrešnim putem. Dopustite nam da Vas vratimo natrag!',
        BACK_BUTTON: 'Povratak na početak',
    },
    UPCOMING_LESSONS: {
        TITLE: 'NADOLAZEĆI DOGAĐAJI',
        EMPTY: 'Nema nadolazećih lekcija',
    },
    REGISTER: {
        TITLE: 'Registracija',
        FORM: {
            BUTTONS_SUBTITLE: 'Tip računa',
            BUTTON_PARENT: 'Roditelj',
            BUTTON_STUDENT: 'Učenik',
            BUTTON_TUTOR: 'Instruktor',
            NAME_SUBTITLE: 'Ime',
            FIRST_NAME: 'Ime kao na osobnoj iskaznici',
            FIRST_NAME_PLACEHOLDER: 'Enter First Name',
            LAST_NAME: 'Prezime kao na osobnoj iskaznici',
            LAST_NAME_PLACEHOLDER: 'Enter Last Name',
            LAST_NAME_INFO: 'Provjerite da ovo odgovara imenu na Vašoj službenoj osobnoj iskaznici.',
            DATE_OF_BIRTH_SUBTITLE: 'Datum rođenja',
            DATE_OF_BIRTH: 'Datum rođenja',
            DATE_OF_BIRTH_PLACEHOLDER: 'Enter your birthdate',
            DATE_OF_BIRTH_INFO: 'Kako biste se registrirali na Teorem, morate imati najmanje 18 godina.',
            CONTACT_INFO_SUBTITLE: 'Kontaktni podaci',
            EMAIL: 'Email',
            EMAIL_PLACEHOLDER: 'Enter Email',
            PHONE_NUMBER: 'Broj telefona',
            PHONE_INFO: 'Vaši kontakt podaci neće biti vidljivi javnosti. E-poštom ćemo Vam slati potvrde predavanja i račune.',
            PASSWORD_SUBTITLE: 'Lozinka',
            PASSWORD: 'Lozinka',
            PASSWORD_PLACEHOLDER: 'Type your password',
            CONFIRM_PASSWORD: 'Repeat Password',
            CONFIRM_PASSWORD_PLACEHOLDER: 'Type your password',
            AGREE: 'Klikom na Slažem se i nastavi, slažem se s Uvjetima pružanja usluge i Politikom privatnosti Teorema.',
            SUBMIT_BUTTON: 'Slažem se i nastavi',
            MARKETING_DISCLAIMER:
                'Teorem će vam slati posebne ponude, marketinše e-poruke i obavijesti. Možete isključiti njihovo primanje u bilo kojem trenutku u postavkama svog računa ili izravno iz marketinške obavijesti.',
            MARKETING_DISCLAIMER_CHECKBOX: 'Ne želim primati marketinške poruke od Teorema.',
            CHILD_NAME: 'Ime djeteta',
            CHILD_NAME_PLACEHOLDER: 'Unesite ime djeteta',
            CHILD_DATE_OF_BIRTH: 'Datum rođenja djeteta*',
            USERNAME: 'Korisničko ime*',
            EMAIL_EXISTS: 'Ovaj email se već koristi. Molimo ulogirajte se.',
            TERMS_AND_CONDITIONS:
                '<div>Klikom na <strong>Slažem se i nastavi</strong>, slažem se s <a href="https://teorem.hr/hr/uvjeti-koristenja" target="_blank">Uvjetima pružanja usluge</a> i <a href="https://teorem.hr/hr/politika-privatnosti" target="_blank">Politikom privatnosti</a> Teorema.</div>',
        },
        CARD_DETAILS: {
            FIRST_NAME: 'Ime*',
            LAST_NAME: 'Prezime*',
            CARD_NUMBER: 'Broj kartice*',
            EXPIRY_DATE: 'Datum isteka*',
            EXPIRY_PLACEHOLDER: 'MM / GG',
            CVV: 'CVV*',
            ZIP_CODE: 'Poštanski broj*',
            ZIP_CODE_PLACEHOLDER: 'Unesite poštanski broj',
        },
        BACK_BUTTON: 'Natrag na odabir',
        NEXT_BUTTON: 'Dalje',
        BACK_TO_REGISTER: 'Natrag na registraciju',
        FINISH: 'Završi',
        BACK_TO_STEP_ONE: 'Natrag na korak 1',
        BACK_TO_STEP_TWO: 'Natrag na korak ',
        SAVE_BUTTON: 'Spremi',
        DELETE_BUTTON: 'Obriši',
        BACK_TO_LIST: 'Natrag na popis',
        NAVIGATION: {
            STEP1: {
                TITLE: 'Osobni podatci',
                DESC: 'Dopustite nam da Vas bolje upoznamo',
            },
            STEP2: {
                TITLE: 'Popis djece',
                DESC: 'Ispunite podatke o Vašem djetetu i postavite njegove/njezine podatke za prijavu.',
            },
        },
    },
    ADD_CHILD: {
        TITLE: 'Dodajte novo dijete',
        DESCRIPTION: 'Izaberite za dodavanje novog djeteta',
        SAVE: 'Spremi dijete',
    },
    MY_BOOKINGS: {
        TITLE: 'Kalendar',
        NOTIFICATION_PART_1: 'Imate ',
        NOTIFICATION_PART_2: ' lekcija danas!',
        UNAVAILABLE: 'Nedostupno',
        UNAVAILABILITY: 'Nedostupnost',
        UNABLE_MESSAGE: 'Ne možete dodati događaj u prošlosti ili u iduća 3 sata',
        TIME_AFTER: 'Vrijeme početka ne može biti nakon vremena završetka',
        TIME_SAME: 'Vrijeme početka i vrijeme završetka ne mogu biti isti',
        CALENDAR: {
            TITLE: 'KALENDAR',
        },
        MODAL: {
            DELETED_USER: 'Izbrisani korisnik',
            ACCEPT: 'Prihvati',
            PROPOSE: 'Predloži novo vrijeme',
            DELETE: 'Izbriši',
            DENY: 'Odbij',
            TUTOR_CALENDAR: 'Raspored instruktora',
            TIME: 'Vrijeme',
            DATE: 'Datum',
            WHOLE_DAY: 'Čitav dan',
            SET_UNAVAILABILITY: 'Postavi nedostupnost',
            CANCEL: 'Otkaži',
        },
        INFORMATION: {
            TITLE: 'INFORMACIJE O REZERVACIJAMA',
            CARD1: {
                TITLE: 'Kako rezervirati termin?',
                DESC: 'Možete rezervirati termin s instruktorom odlaskom na njegov/njezin profil i pritiskom na "Rezervirajte lekciju". Moći ćete vidjeti instruktorovu dostupnost i izabrati vrijeme koje odgovara za oboje.',
            },
            CARD2: {
                TITLE: 'Kako otkazati rezervaciju?',
                DESC: 'Možete otkazati rezervaciju u Vašem kalendaru klikom na rezervaciju i zatim na "Otkaži". Ako otkažete rezervaciju više od 24 sata unaprijed, dobit ćete puni povrat novca.',
            },
        },
    },
    BOOK: {
        TITLE: 'Rezervirajte termin',
        FORM: {
            LEVEL: 'Razina',
            LEVEL_PLACEHOLDER: 'Odaberite razinu',
            SUBJECT: 'Predmet',
            CHILD: 'Dijete',
            CHILD_PLACEHOLDER: 'Odabir djeteta',
            TIME: 'Vrijeme* (Lekcije traju 50min)',
            TIME_PLACEHOLDER: 'Vrijeme',
            SUBMIT: 'Rezerviraj',
            UPDATE: 'Ažuriraj rezervaciju',
            CANCEL: 'Otkaži',
        },
        JOIN: 'Pridruži se',
    },
    MY_REVIEWS: {
        TITLE: 'Moje recenzije',
        COUNT_TITLE: 'RECENZIJE',
        AVG_SCORE: 'Prosječna ocjena',
        PUBLISHED: 'Objavljeno',
        STAR: {
            1: '1 zvjezdica',
            2: '2 zvjezdice',
            3: '3 zvjezdice',
            4: '4 zvjezdice',
            5: '5 zvjezdica',
        },
        NO_RESULT: {
            TITLE: 'Bez rezultata',
            DESC: 'Još uvijek nemate recenzija.',
            DESC_ADMIN: 'Još uvijek nema niti jedne recenzije.',
        },
        DATE_AGO: {
            BEFORE: ' prije ',
            YEAR: ' godina',
            YEARS: ' godina',
            MONTH: ' mjesec',
            MONTHS: ' mjeseci',
            WEEK: ' tjedan',
            WEEKS: ' tjedna',
            DAY: ' dan',
            DAYS: ' dana',
            TODAY: ' danas',
        },
    },
    COMPLETED_LESSONS: {
        TITLE: 'Završene lekcije',
        TUTORS_AVAILABLE: 'Dostupni instruktori',
        LESSONS_AVAILABLE: 'Dostupne lekcije',
        EMPTY_LESSONS_LIST: 'Nema dostupnih završenih lekcija',
        EMPTY_LESSONS_TITLE: 'Nema lekcija',
        LEAVE_REVIEW: 'Ostavite recenziju',
        VIEW_CALENDAR: 'Pregledajte kalendar',
        VIDEOS_TITLE: 'Gledajte / Preuzmite lekcije',
        EMPTY_VIDEOS: 'Nema dostupnih završenih lekcija za odabir',
        LINK: 'Idi na kalendar',
        COUNT_EXTENSION: 'Lekcije',
    },
    WRITE_REVIEW: {
        SECTION_TITLE: 'Napišite recenziju',
        TITLE: 'Naslov',
        RATING: 'Ocjena',
        REVIEW: 'Recenzija',
        SUBMIT: 'Objavi',
        CANCEL: 'Odustani',
        HEADING_PLACEHOLDER: 'Napiši naslov svoje recenzije',
        TEXT_PLACEHOLDER: 'Opiši svoje cjelokupno iskustvo s ovom lekcijom',
    },
    COMPLETE_PROFILE: {
        TITLE: 'Završi svoj profil',
        DESCRIPTION: 'Ispuni nedostajeće podatke kako bi završio svoj profil',
        PROFILE_SETTINGS: 'Postavke profila',
        GENERAL_AVAILABILITY: 'Dostupnost',
        MY_TEACHINGS: 'Predmeti',
        ABOUT_ME: 'O meni',
        ACCOUNT: 'Korisnički račun',
        CHILD_INFO: 'Informacije o djetetu',
        EARNINGS: 'Zarada',
    },
    COMPLETE_TUTOR_PROFILE_CARD: {
        TITLE: 'Završi svoj profil!',
        DESCRIPTION: 'Molimo ispunite ostatak podataka o svojem profilu kako bi počeli predavati.',
    },
    ACCOUNT: {
        CHANGE_PASSWORD: {
            TITLE: 'Promijenite lozinku',
            DESCRIPTION: 'Potvrdite svoju trenutnu lozinku te nakon toga unesite novu.',
            CURRENT_PASSWORD: 'Trenutna lozinka',
            CURRENT_PASSWORD_PLACEHOLDER: 'Unesite trenutnu lozinku',
            NEW_PASSWORD: 'Nova lozinka',
            NEW_PASSWORD_PLACEHOLDER: 'Unesite novu lozinku',
            CONFIRM_PASSWORD: 'Potvrdi lozinku',
            CONFIRM_PASSWORD_PLACEHOLDER: 'Potvrdi lozinku',
        },
        CARD_DETAILS: {
            TITLE: 'Plaćanje',
            DESCRIPTION: 'Odaberi osnovnu metodu plaćanja ili dodaj novu.',
            TITLE_TUTOR: 'Detalji plaćanja',
            DESCRIPTION_TUTOR: 'Odaberi kako zeliš biti plaćen za instrukcije.',
            ADD_NEW: 'Dodaj novu karticu',
            ADD_NEW_DESC: 'Izaberi za dodavanje nove kartice',
        },
        NEW_CARD: {
            ADD: 'DODAJTE NOVU KARTICU',
            NAME: 'Ime',
            NAME_PLACEHOLDER: 'Unesi ime',
            SURNAME: 'Prezime',
            SURNAME_PLACEHOLDER: 'Unesi prezime',
            CITY: 'Grad',
            CITY_PLACEHOLDER: 'Unesi ime grada',
            ADDRESS1: 'Adresa 1',
            ADDRESS1_PLACEHOLDER: 'Unesi prvu adresu',
            ADDRESS2: 'Adresa 2',
            ADDRESS2_PLACEHOLDER: 'Unesi drugu adresu',
            CARD_NUMBER: 'Broj kartice',
            CARD_NUMBER_PLACEHOLDER: '**** **** **** ****',
            EXPIRY: 'Datum isteka',
            EXPIRY_PLACEHOLDER: 'MM / GG',
            CVV: 'CVV*',
            CVV_PLACEHOLDER: '***',
            ADD_BUTTON: 'Dodaj novu karticu',
            CANCEL_BUTTON: 'Odustani',
            ZIP: 'Zip',
            ZIP_PLACEHOLDER: '12345',
            COUNTRY: 'Država',
            COUNTRY_PLACEHOLDER: 'Izaberite državu',
        },
        SUBMIT: 'Spremi',
    },
    MY_PROFILE: {
        TITLE: 'Moj profil',
        PREVIEW: 'Pregled profila',
        ABOUT_ME: {
            OCCUPATION: 'Vaše trenutno zanimanje*',
            OCCUPATION_PLACEHOLDER: 'Unesite Vaše trenutno zanimanje',
            YEARS: 'Broj godina profesionalnog iskustva (neobavezno)',
            YEARS_PLACEHOLDER: 'Koliko godina profesionalnog iskustva imate',
            ABOUT_YOURSELF: 'Recite nam više o sebi*',
            ABOUT_LESSON: 'Recite nam više o Vašem načinu predavanja*',
        },
        MY_TEACHINGS: {
            TITLE: 'Moji predmeti',
            DESCRIPTION: 'Izmijenite i ažurirajte svoje predmete',
            ADD_NEW: 'Dodajte novi predmet',
            ADD_DESC: 'Izaberite za dodavanje novog predmeta',
            DELETED: 'Predmet je obrisan',
            CREATED: 'Predmet je dodan',
            EDIT_TITLE: 'IZMIJENITE PREDMET',
            ADD_TITLE: 'DODAJTE NOVI PREDMET',
            SUBJECT: 'Odaberite predmet koji predajete',
            LEVEL: 'Odaberite razine koje predajete',
            PRICING: 'Cijena ',
            PRICING_PLACEHOLDER: '',
            HOUR_ABRV: 'h',
            SAVE: 'Spremi podatke',
            DELETE: 'Izbriši',
            CANCEL: 'Odustani',
        },
        GENERAL_AVAILABILITY: {
            TITLE: 'Dostupnost',
            DESCRIPTION: 'Izmijenite i ažurirajte svoju dostupnost',
            UPDATED: 'Dostupnost ažurirana',
            CREATED: 'Dostupnost dodana',
        },
        PROFILE_SETTINGS: {
            TITLE: 'Osobni podatci',
            DESCRIPTION: 'Izmijenite i ažurirajte svoje osobne podatke',
            FIRST_NAME: 'Ime*',
            FIRST_NAME_PLACEHOLDER: 'Unesite svoje ime',
            LAST_NAME: 'Prezime*',
            LAST_NAME_PLACEHOLDER: 'Unesite svoje prezime',
            PHONE: 'Broj telefona*',
            COUNTRY: 'Država*',
            BIRTHDAY: 'Datum rođenja*',
            IMAGE: '<Profilna slika>*',
            UPLOAD_IMAGE: 'Povucite i ispustite za učitavanje',
        },
        PROFILE_ACCOUNT: {
            STRIPE: 'Idi na Stripe',
            STRIPE_ALREADY_CONNECTED: 'Stripe račun je već povezan,ukoliko želite povezati drugi račun, ponovo pritisnite tipku ispod',
            STRIPE_CONNECTED: 'Povezano',
            STRIPE_DISCONNECTED: 'Nije Povezano',
            STRIPE_CARD_DECLINED: 'Greška prilikom izrade Stripe računa',
            SUCCESS_PASSWORD: 'Lozinka je uspješno izmijenjena',
            STRIPE_DEFAULT_PAYMENT_METHOD_UPDATED: 'Primarna metoda plaćanja je izmijenjena',
        },
        CHILD: {
            ADD_TITLE: 'DODAJTE DIJETE',
            EDIT_TITLE: 'AŽURIRAJTE DIJETE',
            FIRST_NAME: 'Ime',
            FIRST_NAME_PLACEHOLDER: 'Unesite ime djeteta',
            LAST_NAME: 'Prezime',
            LAST_NAME_PLACEHOLDER: 'Unesite prezime djeteta',
            USERNAME: 'Korisničko ime',
            USERNAME_PLACEHOLDER: 'Unesite korisničko ime',
            PASSWORD: 'Unesite novu lozinku',
            PASSWORD_PLACEHOLDER: 'Upišite novu lozinku',
            PASSWORD_OPTIONAL: 'Ovo je polje izborno, ako želite promijeniti djetetovu trenutnu lozinku možete to učiniti u polju ispod.',
            DELETE: 'Izbriši',
            CANCEL: 'Odustani',
        },
        TRANSLATION: {
            TITLE: 'Prijevod',
            SUBTITLE: 'Izaberite jezik aplikacije.',
        },
        SUBMIT: 'Spremi',
        TUTOR_DISABLE: {
            TITLE: 'Vidljivost profila',
            SUBTITLE: 'Profil se prikazuje',
            YES: 'Da',
            NO: 'Ne',
        },
    },
    DASHBOARD: {
        TITLE: 'Upravljačka ploča',
        SCHEDULE: {
            TITLE: 'DANAŠNJI RASPORED',
            BUTTON: 'Spajanje',
            EMPTY: 'Danas nema zakazanih predavanja',
        },
        MESSAGES: {
            TITLE: 'NEPROČITANE PORUKE',
            BUTTON: 'Razgovori',
            EMPTY: 'Nema nepročitanih poruka',
        },
        BOOKINGS: {
            TOTAL: 'Ukupno',
            EMPTY: 'Nema zakazanih termina',
            EMPTY_SUBTITLE: 'Hmm, izgleda da za sada nemate zakazanih termina.',
        },
        NOTIFICATIONS: {
            TITLE: 'OBAVIJESTI',
            CLEAR: 'Označi kao pročitano',
            ALL: 'Vidi sve',
            EMPTY: 'Nema nepročitanih obavijesti',
        },
        COMING_SOON: {
            TITLE: 'Stiže uskoro!',
            SUBTITLE: 'Naš tim marljivo radi kako bismo dodali ovu funkcionalnost',
        },
    },
    NOTIFICATIONS: {
        TITLE: 'Sve obavijesti',
        EMPTY: 'Nema obavijesti',
        BOOKING_CLASS_STARTING: {
            DESCRIPTION: 'Predavanje započinje uskoro',
            TITLE: 'Predavanje započinje uskoro',
        },
        CHAT_MISSED_CALL: {
            DESCRIPTION: 'Propušten videopoziv od',
            TITLE: 'Propušten videopoziv',
        },
        BOOKING: {
            STUDENT: 'Učenik',
            PARENT: 'Roditelj',
            TUTOR: 'Instruktor',
        },
        BOOKING_NEW_BOOKING: {
            TITLE: 'Nova rezervacija',
            DESCRIPTION: '',
            MADE_BOOKING_TEXT: 'je napravio novu rezervaciju za',
        },
        BOOKING_RESCHEDULE_PROPOSITION: {
            TITLE: 'Predloženo ponovno zakazivanje',
            DESCRIPTION: '',
            PROPOSING_NEW_TIME_TEXT: 'želi predložiti novo vrijeme za rezervaciju',
        },
        BOOKING_ACCEPTED: {
            TITLE: 'Rezervacija prihvaćena',
            DESCRIPTION: 'je prihvatio/la rezervaciju za',
        },
        BOOKING_CANCELED: {
            TITLE: 'Rezervacija otkazana',
            DESCRIPTION: '',
            CANCEL_TEXT: 'je otkazao/la',
        },
        BOOKING_DELETED: {
            TITLE: 'Neprihvaćena rezervacija uklonjena',
            DESCRIPTION: {
                PART1: 'Rezervacija je uklonjena jer ju',
                PART2: 'nije prihvatio/la.',
            },
        },
        CHAT_HAS_MISSED_CALL: ' je propustio Vaš poziv',
    },
    EARNINGS: {
        TITLE: 'Prihod',
        GENERAL: {
            TITLE: 'OPĆI PODATCI',
            BOOKINGS: 'Ukupno rezervacija',
            STUDENTS: 'Ukupno učenika',
            REVIEWS: 'Ukupno recenzija',
            REVENUE: 'Ukupan prihod',
            CURRENCY: ' HRK',
        },
        REVENUE: {
            TITLE: 'PRIHOD',
            GRAPH_LEGEND: 'Prihod',
        },
        DETAILS: {
            TITLE: 'DETALJI',
            TABLE: {
                MONTH: 'Mjesec',
                BOOKINGS: 'Rezervacije',
                STUDENTS: 'Učenici',
                REVIEWS: 'Recenzije',
                REVENUE: 'Prihod',
                EMPTY: 'Nema detalja',
            },
        },
    },
    ROUTER_MODAL: {
        TITLE: 'Spremiti promjene?',
        DESC: 'Želite li spremiti svoje promjene prije odlaska s ove stranice?',
        SAVE: 'Spremi',
        NO: 'Nemoj spremiti',
    },
    TUTOR_PROFILE: {
        AVAILABILITY_EMPTY: 'Instruktor nije ispunio tablicu dostupnosti',
        PRICING: 'Cijena',
        RATING_TITLE: 'Ocjena',
        COMPLETED_LESSONS: 'Odrađenih lekcija',
        BOOK: 'Rezerviraj lekciju',
        SEND: 'Pošalji poruku',
        EMPTY: 'Korisnik nije pronađen',
        SUBJECTS: {
            TITLE: 'Predmeti',
            SUBJECT: 'Predmet',
            QUALIFICATION: 'Kvalifikacija',
            PRICE: 'Cijena',
            HOUR_ABRV: 'h',
            EMPTY: 'Nema ponuđenih predmeta',
        },
        RATING: {
            TITLE: 'Ocjene i recenzije',
            TOTAL: 'ukupno recenzija',
        },
        PRE12: '08-12h',
        ON12: '12-17h',
        AFTER5: '17-20h',
    },
    LANDING: {
        HEADER: {
            NAV_1: 'Kako radimo',
            NAV_2: 'Cijene',
            NAV_3: 'Postanite instruktor',
            NAV_4: 'Blog',
            SIGN_IN: 'Prijavite se',
            GET_STARTED: 'Započnite danas',
        },
        FOOTER: {
            TITLE_TEXT: 'Ako imate dodatnih pitanja, slobodno nas kontaktirajte putem emaila ',
            APP_NAME: 'Teorem Ltd',
            ADDRESS: '',
            MOBILE: '',
            EMAIL: 'support@teorem.co',
            LEGAL: {
                TITLE: 'O Teoremu',
                LEGAL: 'Uvjeti korištenja',
                REFUND: 'Politika povrata novca i plaćanja',
                PRIVACY: 'Politika privatnosti',
                CONDUCT: 'Kodeks ponašanja korisnika',
                TUTOR: 'Postanite instruktor',
                LEGAL_CENTER: 'Pravni centar',
            },
            PAYMENT: {
                TITLE: 'Metode plaćanja',
            },
        },
        LOCALE: {
            TITLE: 'Molimo odaberite',
            TITLE_COUNTRY: 'Država',
            TITLE_LANGUAGE: 'Jezik',
            SEARCH: 'Traži',
            SUBMIT_COUNTRY: 'Spremite željenu državu',
            SUBMIT_LANGUAGE: 'Spremite željeni jezik',
        },
    },
    HOW_IT_WORKS: {
        HERO: {
            TITLE: '<i>Online</i> instrukcije koje oslobađaju potencijal',
            SUBTITLE: '1-na-1 <i>online</i>-predavanja od 50 minuta jedinstveno skrojena za svakog učenika. Svi školski predmeti pokriveni.',
        },
        SUBTITLE_1: 'Kao što je istaknuto u nekim od najboljih publikacija u Hrvatskoj',
        SUBTITLE_2: 'Često postavljena pitanja',
        TEXT_CARD_1: {
            TITLE: 'Velik krug provjerenih i visoko ocijenjenih instruktora',
            SUBTITLE: 'Izaberite instruktora koji najbolje odgovara Vama i potrebama Vašeg djeteta.',
        },
        TEXT_CARD_2: {
            TITLE: 'Bolje razumijevanje već nakon prvog predavanja',
            SUBTITLE: 'Idite predavanje po predavanje ili odaberite dugoročni plan učenja.',
        },
        TEXT_CARD_3: {
            TITLE: 'Jednostavno ponavljanje kroz ponovno gledanje predavanja',
            SUBTITLE: 'Dok Vaše dijete ponavlja, pratite napredak kroz izvješća o predavanjima.',
        },
        TEXT_CARD_4: {
            TITLE: 'Platite samo ono što iskoristite.',
            SUBTITLE:
                'Teorem Vam neće ništa naplatiti dok ne pronađete svog savršenog instruktora, porazgovarate s njim i rezervirate svoje prvo predavanje. Bez naknada za registraciju, bez pretplate, samo jednostavno plaćanje po satu.',
        },
        REVIEW_1: {
            TEXT: 'Teorem je pomogao mom sinu popravit ocjene iz fizike! Oduševljena sam da je sve preko interneta i da ga ne moram više vozit na drugi kraj grada!',
            NAME: 'Sandra B.',
            OCCUPATION: 'Lukasova majka',
        },
        REVIEW_2: {
            TEXT: 'Mogućnost besplatnog videopoziva omogućila mi je da razgovaram s nekoliko instruktora prije nego što sam se odlučila za onoga koji najbolje odgovara potrebama mog djeteta. Ne mogu biti sretnija!',
            NAME: 'Renata J.',
            OCCUPATION: 'Mateina i Markova majka',
        },
        REVIEW_3: {
            TEXT: 'S Teoremovom virtualnom učionicom napokon mogu radit kroz interaktivne vježbe i zabavan dizajn. Jako mi se sviđa mogućnost ponovnog gledanja predavanja.',
            NAME: 'Ivan K.',
            OCCUPATION: 'Student ekonomije',
        },
        BUTTON_1: 'Započnite danas',
        FAQ: {
            QUESTION_1: 'Zašto 1-na-1 instrukcije?',
            ANSWER_1:
                '<i>Online</i> individualne instrukcije omogućuju potpuno prilagođen pristup učenju, uzimajući u obzir specifične potrebe, ciljeve i tempo učenika. Instruktor se može usredotočiti na identificiranje i rješavanje konkretnih poteškoća ili problema učenika, što često nije moguće u većim grupama. Uz to, u 1-na-1 interakciji, učenici se osjećaju sigurnije i manje sramežljivo postavljajući pitanja ili izražavajući svoje nedoumice, što dovodi do većeg samopouzdanja.',
            QUESTION_2: 'Koje su prednosti <i>online</i>-instrukcija?',
            ANSWER_2:
                '<i>Online</i>-instrukcije omogućuju učenicima i tutorima pristup učenju s bilo kojeg mjesta i u bilo koje vrijeme, čime se olakšava prilagodba rasporedu i svakodnevnim obavezama. Na mreži, učenici imaju pristup širem izboru tutora jer nisu ograničeni na one koji su fizički blizu. Mogu odabrati najboljeg tutora za svoje potrebe.',
            QUESTION_3: 'Koje kvalifikacije imaju instruktori?',
            ANSWER_3:
                'Prijavljeni kandidati prolaze kroz niz testova prije nego što mogu postati instruktori na našoj platformi. Ti testovi obuhvaćaju evaluaciju njihovog znanja u specifičnim predmetima, kao i procjenu njihovih osobnih i pedagoških vještina. Od velikog broja kandidata koji se prijave, samo nekolicina postaju instruktori.',
            QUESTION_4: 'Kako funkcioniraju <i>online</i>-predavanja?',
            ANSWER_4:
                'Teorem <i>online</i>-predavanja održavaju se u našoj virtualnoj učionici koja sadrži video pozive, sustav poruka i interaktivnu digitalnu ploču, pružajući učenicima i instruktorima jednostavan način komunikacije, rasprave o složenim temama i zajedničke vježbe. Sva predavanja u sustavu Teorem traju 50 minuta, a video pozivi omogućuju tinejdžerima medij na koji su već navikli iz svakodnevnog života.',
        },
    },
    PRICING: {
        HERO: {
            TITLE: 'Personalizirane instrukcije po personaliziranim cijenama',
            SUBTITLE: 'Teorem pomaže učenicima svih dobnih skupina ostvariti svoje akademske ciljeve.',
            LABEL_1: '1-na-1 predavanja s individualno odabranim učiteljima',
            LABEL_2: 'Predavanje po predavanje ili dugoročni plan učenja',
            LABEL_3: 'Fleksibilno zakazivanje i pomicanje termina',
        },
        COMPARE_PRICES: 'Usporedite cijene instruktora za ',
        SUBTITLE_1: 'Početak je jednostavan kao 1,2,3!',
        SUBTITLE_4: 'Pratite napredak Vašeg djeteta',
        SUB_SUBTITLE_4: 'Nakon svakog predavanja dobit ćete izvještaj o pokrivenom gradivu i aktivnosti Vašeg djeteta',
        SUBTITLE_2: 'Sigurno online-učenje',
        SUBTITLE_3: 'Često postavljena pitanja',
        BUTTON_1: 'Započnite danas',
        TEXT_CARD_1: {
            TITLE: 'Koja je cijena </br> <i>online</i>-instrukcija?',
            SUBTITLE:
                'Teoremovi instruktori sami određuju svoje cijene, ovisno o njihovom iskustvu i dostupnosti. Cijene instrukcija kreću se od 10 EUR.',
        },
        FAQ: {
            QUESTION_1: 'Koja je cijena instruktora?',
            ANSWER_1:
                'Instruktori platforme Teorem imaju slobodu postaviti svoje cijene, prilagođene njihovom iskustvu i dostupnosti. Cijene instrukcija počinju već od EUR 10. Možete vidjeti sve instruktore koji odgovaraju Vašem financijskom planu pomoću opcije filtriranja prema cijeni u odjeljku "Pretraži instruktore"',
            QUESTION_2: 'Kako pronaći instruktora?',
            ANSWER_2:
                'Pronaći instruktora je jednostavno. Nakon što otvorite korisnički račun na Teoremu, možete tražiti instruktore i filtrirati rezultate pretraživanja prema predmetu, razini, cijeni i dostupnosti.',
            QUESTION_3: 'Mogu li promijeniti instruktora ako nisam zadovoljan/-na?',
            ANSWER_3: 'Naravno! Slobodni ste promijeniti instruktora kada god želite.',
            QUESTION_4: 'Mogu li dobiti povrat novca?',
            ANSWER_4:
                '<span>Naravno! Za više informacijama o pravilima povrata novca posjetite <a href="/hr/politika-povrata-i-placanja"> politiku povrata novca i plaćanja</a>.</span>',
        },
        STEP_1: {
            TITLE: 'Početne konzultacije',
            SUBTITLE: 'Svako putovanje učenja započinje postavljanjem ciljeva s instruktorom i izradom temeljitog plana učenja.',
        },
        STEP_2: {
            TITLE: 'Probno predavanje',
            SUBTITLE: 'Pozivamo Vas na probno predavanje kako biste Vi i Vaše dijete vidjeli kako Teorem funkcionira.',
        },
        STEP_3: {
            TITLE: 'Suradnja',
            SUBTITLE: 'Vaš instruktor pomoći će Vam provesti plan učenja u djelo i nadmašiti dugoročne potrebe učenja Vašeg djeteta.',
        },
        CARD_1: {
            TITLE: 'Zaštićen proces plaćanja',
            SUBTITLE: 'Jednostavno i fleksibilno zakazivanje prilagođeno Vama.',
        },
        CARD_2: {
            TITLE: 'Pažljivo odabrani i provjereni instruktori',
            SUBTITLE: 'Dostupno na svim uređajima, s ugrađenom funkcijom snimanja, digitalnom bijelom pločom te dijeljenjem ekrana i dokumenata.',
        },
        CARD_3: {
            TITLE: 'Sigurna platforma za online učenje',
            SUBTITLE:
                'Sigurno i pouzdano plaćanje u suradnji sa Stripe-om. Bez skrivenih naknada, plaćaju se samo satovi instrukcija, a moguć je i povrat novca.',
        },
        CARD_4: {
            TITLE: 'Korisnička podrška',
            SUBTITLE: '24/7 pristup podršci. Naš posvećeni tim podrške dostupan Vam je u svakom trenutku.',
        },
    },
    LEGAL_CENTER: {
        TITLE: 'Pravni centar',
        SUBTITLE: 'Uvjeti korištenja Teorem platforme definirani su na sljedećim linkovima:',
        TAX_TITLE: 'Porezne informacije (Tutori)',
    },
    TAX: {
        TITLE: 'Podučavajte na pravi način',
        SUBTITLE: 'Ostvarite vrijedne pogodnosti s prijavom svog digitalnog prihoda kroz Paušalni Obrt',
        BENEFITS: {
            TITLE: 'Vaše pogodnosti',
            CREDIT_HISTORY: 'Jačanje kreditne sposobnosti',
            MORTGAGE: 'Mogućnost dobivanja hipoteke',
            HEALTH: 'Mogućnost dobivanja zdravstvenog osiguranja',
            PENSION: 'Doprinosi u mirovinske fondove',
        },
        TABLE: {
            TITLE: 'Osigurajte ove pogodnosti uz doprinos od samo 1.8% vaših prihoda',
            INCOME_CLASS: 'Razred Prihoda',
            ANNUAL_REPORTED_INCOME: 'Godišnji Prijavljeni Prihodi (EUR)',
            ANNUAL_TAX_OBLIGATION: 'Godišnje Porezne Obveze',
            PERCENT_OF_INCOME: '% Prihoda*',
            DISCLAIMER_1: '* Postotni porezni udio procijenjen na temelju brojke na vrhu svake dohodovne klase.',
            DISCLAIMER_2:
                '** Tablica odražava doprinose na dohodak za grad Zagreb. Doprinosi na dohodak za ostale županije u Hrvatskoj mogu biti veći ili niži. ',
        },
        STEPPER: {
            TITLE: 'Otvaranje paušalnog obrta',
            STEP_1: {
                TITLE: 'Izaberite ime, specijalizaciju i sjedište',
                SUBTITLE:
                    'Prvi korak u otvaranju paušalnog obrta je odabir imena, poslovne specijalizacije, u ovom slučaju davanje usluga edukacije, te odabir sjedišta. Ovo može biti i Vaša kućna adresa.',
            },
            STEP_2: {
                TITLE: 'Posjetite stranicu ',
                SUBTITLE:
                    'Otvaranje paušalnog obrta nikad nije bilo jednostavnije zahvaljujući online portalu E-Obrt gdje možete pokrenut svoj obrt u manje od 15 minuta. Sve što Vam treba je Vaša osobna iskaznica i mBanking aplikacija.',
            },
            STEP_3: {
                TITLE: 'Otvorite žiro račun u banci',
                SUBTITLE:
                    'Otvaranje paušalnog obrta nikad nije bilo jednostavnije zahvaljujući online portalu E-Obrt gdje možete pokrenut svoj obrt u manje od 15 minuta. Sve što Vam treba je Vaša osobna iskaznica i mBanking aplikacija.',
            },
            STEP_4: {
                TITLE: 'Počnite podučavati!',
                SUBTITLE:
                    'Sve je spremno! Sada se možete usredotočiti na ono što najviše volite – podučavanje. Ako želite da promijenimo neke od vaših postavki vezanih uz isplate, uvijek nam se možete obratiti.',
            },
            LAST_STEP_1: 'Otvaranje Paušanog obrta je potpuno besplatno. Posjetite ',
            LAST_STEP_2: ' za više informacija.',
        },
    },
    BECOME_A_TUTOR: {
        HERO: {
            TITLE: 'Počnite podučavati <i>online</i>',
            SUBTITLE: 'Držite instrukcije kada god Vi to želite, iz udobnosti vlastitog doma.',
        },
        TEXT_CARD_1: {
            TITLE: 'Teoremove <i>online</i> instrukcije olakšavaju Vaš život',
            SUBTITLE: 'Pružit ćemo Vam sve što trebate za podučavanje online. Pomažemo Vam pronaći učenike i upravljati rasporedom.',
        },
        TEXT_CARD_2: {
            TITLE: 'Preglednost Vaše zarade i brza isplata',
            SUBTITLE: 'Isplata na IBAN Vašeg odabira svaki četvrtak. Umanjena za proviziju od 15%.',
        },
        REVIEW_1: {
            TEXT: 'Otkada poučavam na Teoremu, moj je mjesečni prihod postao stabilan izvor financijske sigurnosti. Sada mogu opušteno planirati svoje troškove bez brige.',
            NAME: 'Ante O.',
            OCCUPATION: 'Instruktor fizike',
            NUMBER_OF_LESSONS: '12 završenih lekcija',
        },
        REVIEW_2: {
            TEXT: 'Zahvaljujući Teoremu, ostvarila sam svoj san o preseljenju na Vis i istodobno mogla nastaviti davati instrukcije učenicima u Zagrebu.',
            NAME: 'Dunja M.',
            OCCUPATION: 'Instruktorica njemačkog',
            NUMBER_OF_LESSONS: '32 završene lekcije',
        },
        SUBTITLE_1: 'Ovdje smo da Vam pomognemo napredovati!',
        SUBTITLE_2: 'Kako postati <i>online</i> instruktor na Teoremu',
        SUBTITLE_3: 'Često postavljena pitanja',
        BUTTON_1: 'Započnite danas',
        FAQ: {
            QUESTION_1: 'Kako postati  <i>online</i> instruktor?',
            ANSWER_1:
                'Ako Vas zanima kako da započnete s davanjem instrukcija, došli ste na pravo mjesto! Samo stvorite korisnički račun, rezervirajte svoj intervju i mi ćemo Vam pomoći započeti!',
            QUESTION_2: 'Koji su zahtjevi za instruktore?',
            ANSWER_2:
                'Najniža razina kvalifikacije koju instruktori moraju imati je srednja škola. Instruktori moraju imati računalo i stabilnu internetsku vezu kako bi predavali <i>online</i>. Teorem će testirati znanje instruktora na platformi prema vlastitoj diskreciji.',
            QUESTION_3: 'Koliko je dugo potrebno čekati kako bih započeo/la s održavanjem <i>online</i>-predavanja?',
            ANSWER_3:
                'Budući da se registracijski proces sastoji od nekoliko jednostavnih koraka, obično treba samo 1 dan kako bi se izvršila verifikacija i započelo s <i>online</i>-predavanjem.',
            QUESTION_4: 'Kako izgleda radni odnos?',
            ANSWER_4:
                '<span>Vi nudite nezavisne usluge instrukcija koristeći se platformom Teorem. Nema radnog odnosa te ste sami odgovorni za sve <a href="/hr/porez">porezne kontribucije</a>.</span>',
            QUESTION_5: 'Trebam li platiti bilo kakav porez ili prijaviti porez državi?',
            ANSWER_5: 'Više informacija o porezu i prihodima možete pronaći na <1>ovoj poveznici</1>.',
            LINK_5: '/hr/porez',
            QUESTION_6: 'Što razlikuje Teorem od ostalih platformi?',
            ANSWER_6:
                'Mi Vam garantiramo stabilan dotok novih učenika te se brinemo o svemu tako da se Vi možete u potpunosti posvetiti predavanjima.',
            QUESTION_7: 'Kako Teorem zarađuje?',
            ANSWER_7: 'Teorem uzima proviziju od 15% od instruktora.',
        },
        CARD_1: {
            TITLE: '"Remote" rad',
            SUBTITLE: 'Sve što Vam treba su računalo i internet.',
        },
        CARD_2: {
            TITLE: 'Inovativni alati',
            SUBTITLE: 'Sustav rezervacija, chat, videopozivi i više.',
        },
        CARD_3: {
            TITLE: 'Dodatan izvor prihoda',
            SUBTITLE: 'Dodatan izvor prihoda iz udobnosti vlastita doma!',
        },
        CARD_4: {
            TITLE: 'Samostalno određivanje cijene',
            SUBTITLE: 'O cijeni predavanja odlučuje instruktor.',
        },
        CARD_5: {
            TITLE: 'Podrška 24/7 <div style="color: white">.</div>',
            SUBTITLE: 'Tim podrške Vam je u svakom trenutku na raspolaganju.',
        },
        CARD_6: {
            TITLE: 'Poboljšajte svoje podučavanje',
            SUBTITLE: 'Mi brinemo o administraciji, a Vi se samo fokusirajte na predavanje.',
        },
        STEP_1: 'Stvorite svoj korisnički račun i ispunite podatke.',
        STEP_2: 'Potvrda našeg tima za uspjeh da ste postali instruktor.',
        STEP_3: 'Počnite podučavati!',
    },
    SCHOOL: {
        HERO: {
            TITLE: 'Vaša platforma za <i>online</i>-instrukcije',
            SUBTITLE: 'Držite <i>online</i> instrukcije kada god Vi to želite, iz udobnosti vlastitog doma.',
        },
        TEXT_CARD_1: {
            TITLE: 'Teoremove online instrukcije olakšavaju Vaš život',
            SUBTITLE: 'Pružit ćemo Vam sve što trebate za podučavanje online. Pomažemo Vam pronaći učenike i upravljati rasporedom.',
        },
        TEXT_CARD_2: {
            TITLE: 'Odredite svoju cijenu i dostupnost!',
            SUBTITLE: 'Koristite naš alat kako biste pratili koliko zarađujete i povećali bazu Vaših klijenata!',
        },
        REVIEW_1: {
            TEXT: 'Otkada poučavam na Teoremu, moj je mjesečni prihod postao stabilan izvor financijske sigurnosti. Sada mogu opušteno planirati svoje troškove bez brige.',
            NAME: 'Ante O.',
            OCCUPATION: 'Instruktor fizike',
        },
        REVIEW_2: {
            TEXT: 'Zahvaljujući Teoremu, ostvarila sam svoj san o preseljenju na Vis i istodobno mogla nastaviti davati instrukcije učenicima u Zagrebu.',
            NAME: 'Dunja M.',
            OCCUPATION: 'Instruktorica njemačkog',
            NUMBER_OF_LESSONS: '32 završene lekcije',
        },
        SUBTITLE_1: 'Ovdje smo da Vam pomognemo napredovati!',
        SUBTITLE_2: 'Kako postati  <i>online</i> instruktor na Teoremu',
        SUBTITLE_3: 'Često postavljena pitanja',
        BUTTON_1: 'Započnite danas',
        FAQ: {
            QUESTION_1: 'Kako postati  <i>online</i> instruktor?',
            ANSWER_1:
                'Ako Vas zanima kako da započnete s davanjem instrukcija, došli ste na pravo mjesto! Samo stvorite korisnički račun, rezervirajte svoj intervju i mi ćemo Vam pomoći započeti!',
            QUESTION_2: 'Koji su zahtjevi za instruktore?',
            ANSWER_2:
                'Najniža razina kvalifikacije koju instruktori moraju imati je srednja škola. Instruktori moraju imati računalo i stabilnu internetsku vezu kako bi predavali <i>online</i>. Teorem će testirati znanje instruktora na platformi prema vlastitoj diskreciji.',
            QUESTION_3: 'Koliko je dugo potrebno čekati kako bih započeo/la s održavanjem <i>online</i>-predavanja?',
            ANSWER_3:
                'Budući da se registracijski proces sastoji od nekoliko jednostavnih koraka, obično treba samo 1 dan kako bi se izvršila verifikacija i započelo s <i>online</i>-predavanjem.',
            QUESTION_4: 'Kako izgleda radni odnos?',
            ANSWER_4:
                '<span>Vi nudite nezavisne usluge instrukcija koristeći se platformom Teorem. Nema radnog odnosa te ste sami odgovorni za sve <a href="/hr/porez">porezne kontribucije</a>.</span>',
            QUESTION_5: 'Trebam li platiti bilo kakav porez ili prijaviti porez državi?',
            ANSWER_5: 'Više informacija o porezu i prihodima možete pronaći na <1>ovoj poveznici</1>.',
            LINK_5: '/hr/porez',
            QUESTION_6: 'Što razlikuje Teorem od ostalih platformi?',
            ANSWER_6:
                'Mi Vam garantiramo stabilan dotok novih učenika te se brinemo o svemu tako da se Vi možete u potpunosti posvetiti predavanjima.',
            QUESTION_7: 'Kako Teorem zarađuje?',
            ANSWER_7: 'Teorem uzima proviziju od 15% od instruktora.',
        },
        CARD_1: {
            TITLE: '"Remote" rad',
            SUBTITLE: 'Sve što Vam treba su računalo i internet.',
        },
        CARD_2: {
            TITLE: 'Inovativni alati',
            SUBTITLE: 'Sustav rezervacija, chat, videopozivi i više.',
        },
        CARD_3: {
            TITLE: 'Dodatan izvor prihoda',
            SUBTITLE: 'Dodatan izvor prihoda iz udobnosti vlastita doma!',
        },
        CARD_4: {
            TITLE: 'Samostalno određivanje cijene',
            SUBTITLE: 'O cijeni predavanja odlučuje instruktor.',
        },
        CARD_5: {
            TITLE: 'Podrška 24/7 <div style="color: white">.</div>',
            SUBTITLE: 'Tim podrške Vam je u svakom trenutku na raspolaganju.',
        },
        CARD_6: {
            TITLE: 'Poboljšajte svoje podučavanje',
            SUBTITLE: 'Mi brinemo o administraciji, a Vi se samo fokusirajte na predavanje.',
        },
        STEP_1: 'Stvorite svoj korisnički račun i ispunite podatke.',
        STEP_2: 'Potvrda našeg tima za uspjeh da ste postali instruktor.',
        STEP_3: 'Počnite podučavati!',
    },
    EMAIL_CONFIRMED: {
        WELCOME: 'Dobrodošli na Teorem!',
        DESCRIPTION: 'Vaš e-mail je uspješno potvrđen.',
        BUTTON: 'Log in',
    },
    STRIPE_CONNECTED: {
        WELCOME: 'Uspjeh!',
        DESCRIPTION: 'Stripe je uspješno povezan.',
        BUTTON: 'Nastavi',
    },
    STRIPE_FAIL: {
        WELCOME: 'Nešto je pošlo po krivu.',
        DESCRIPTION: 'Stripe nije uspješno povezan. Molimo pokušajte ponovno.',
        BUTTON: 'Nastavi',
    },
    STRIPE_CONNECT: {
        TITLE: 'Isplate',
        LINE_1: 'Adresa linija 1',
        LINE_2: 'Adresa linija 2',
        POST_CODE: 'Poštanski broj',
        CITY: 'Grad',
        IBAN: 'IBAN',
        IBAN_CONFIRM: 'IBAN',
        SAVE: 'Spremi',
        CANCEL: 'Odustani',
        TERMS: '<div>Klikom na gumb \'Pošalji\' slažete se s <a href="https://stripe.com/legal/connect-account" target="_blank">Uvjetima pružanja usluge</a> i <a href="https://stripe.com/privacy" target="_blank">Politikom privatnosti</a></div>',
        SUCCESS: 'Račun za plaćanje povezan',
    },
    SUBJECTS: {
        biology: 'Biologija',
        chemistry: 'Kemija',
        economics: 'Ekonomija',
        english: 'Engleski jezik',
        croatian: 'Hrvatski jezik',
        geography: 'Geografija',
        german: 'Njemački jezik',
        history: 'Povijest',
        it: 'IT',
        italian: 'Talijanski jezik',
        maths: 'Matematika',
        music: 'Glazbena kultura',
        physics: 'Fizika',
        polish: 'Poljski jezik',
        psychology: 'Psihologija',
        religion: 'Vjeronauk',
        russian: 'Ruski jezik',
        sociology: 'Sociologija',
        spanish: 'Španjolski jezik',
        businessstudies: 'Poslovne studije',
        classicalgreek: 'Klasični grčki jezik',
        computing: 'Računarstvo',
        designtechnology: 'Dizajn i tehnologija',
        drama: 'Drama',
        environmentalstudies: 'Zaštita okoliša',
        french: 'Francuski jezik',
        governmentandpolitics: 'Politika i gospodarstvo',
        ict: 'ICT (Informacijska i komunikacijska tehnologija)',
        latin: 'Latinski jezik',
        science: 'Znanost',
        art: 'Umjetnost',
        accounting: 'Računovodstvo',
        architecture: 'Arhitektura',
        chemicalengineering: 'Kemijsko inženjerstvo',
        law: 'Pravo',
        medicine: 'Medicina',
        philosophy: 'Filozofija',
        politics: 'Politika',
        statistics: 'Statistika',
    },
    LEVELS: {
        primaryschool: 'Osnovna škola',
        highschool: 'Srednja škola',
        ib: 'IB (International Baccalaureate)',
        maturaprep: 'Priprema za maturu',
        university: 'Fakultet',
    },
    ROLES: {
        student: 'Učenik',
        parent: 'Roditelj',
        child: 'Dijete',
        tutor: 'Instruktor',
        admin: 'Administrator',
    },
    CONSTANTS: {
        MONTHS_SHORT: {
            JAN: 'SIJ',
            FEB: 'VELJ',
            MAR: 'OŽU',
            APR: 'TRA',
            MAY: 'SVI',
            JUN: 'LIP',
            JUL: 'SRP',
            AUG: 'KOL',
            SEP: 'RUJ',
            OCT: 'LIS',
            NOV: 'STU',
            DEC: 'PRO',
        },
        MONTHS_LONG: {
            JAN: 'Siječanj',
            FEB: 'Veljača',
            MAR: 'Ožujak',
            APR: 'Travanj',
            MAY: 'Svibanj',
            JUN: 'Lipanj',
            JUL: 'Srpanj',
            AUG: 'Kolovoz',
            SEP: 'Rujan',
            OCT: 'Listopad',
            NOV: 'Studeni',
            DEC: 'Prosinac',
        },
        DAYS_SHORT: {
            MON: 'Pon',
            TUE: 'Uto',
            WED: 'Sri',
            THU: 'Čet',
            FRI: 'Pet',
            SAT: 'Sub',
            SUN: 'Ned',
        },
        DAYS_LONG: {
            MON: 'Ponedjeljak',
            TUE: 'Utorak',
            WED: 'Srijeda',
            THU: 'Četvrtak',
            FRI: 'Petak',
            SAT: 'Subota',
            SUN: 'Nedjelja',
        },
    },
    PATHS: {
        REGISTER: '/hr/registracija',
        FORGOT_PASSWORD: '/hr/zaboravljena-lozinka',
        RESET_PASSWORD: '/hr/resetiraj-lozinku',
        LOGIN: '/hr/login',
        MY_BOOKINGS: '/hr/kalendar',
        SEARCH_TUTORS: '/hr/pretraga-instruktora',
        SEARCH_TUTORS_TUTOR_PROFILE: '/hr/profil/:tutorSlug',
        SEARCH_TUTORS_TUTOR_BOOKINGS: '/hr/pretraga-instruktora/kalendar/:tutorSlug',
        ONBOARDING: '/hr/onboarding',
        MY_REVIEWS: '/hr/moje-recenzije',
        COMPLETED_LESSONS: '/hr/zavrsene-lekcije',
        CHAT: '/hr/razgovor',
        DASHBOARD: '/hr/glavna-ploca',
        NOTIFICATIONS: '/hr/glavna-ploca/obavijesti',
        EARNINGS: '/hr/prihod',
        REFUND: '/hr/politika-povrata-i-placanja',
        COOKIE: '/hr/politika-kolacica',
        CONDUCT: '/hr/kodeks-ponasanja-korisnika',
        TERMS: '/hr/uvjeti-koristenja',
        PRIVACY: '/hr/politika-privatnosti',
        TAX: '/hr/porez',
        LEGAL_CENTER: '/hr/pravni-centar',
        TUTOR_MANAGMENT: '/hr/upravljanje-instruktorima',
        TUTOR_MANAGMENT_TUTOR_PROFILE: '/hr/upravljanje-instruktorima/profil/:tutorSlug',
        LANDING_PATHS: {
            HOW_IT_WORKS: '/hr/online-instrukcije',
            BECOME_TUTOR: '/hr/postani-instruktor',
            PRICING: '/hr/cijene',
            TERMS: '/hr/uvjeti-koristenja',
            REFUND: '/hr/politika-povrata-i-placanja',
            COOKIE: '/hr/politika-kolacica',
            PRIVACY: '/hr/politika-privatnosti',
            TAX: '/hr/porez',
            LEGAL_CENTER: '/hr/pravni-centar',
            SCHOOL: '/hr/skole',
            BLOG: 'https://start.teorem.co/hr',
        },
        PROFILE_PATHS: {
            MY_PROFILE: '/hr/moj-profil',
            MY_PROFILE_INFO: '/hr/moj-profil/informacije',
            MY_PROFILE_INFO_PERSONAL: '/hr/moj-profil/informacije/postavke-profila',
            MY_PROFILE_INFO_AVAILABILITY: '/hr/moj-profil/informacije/dostupnost',
            MY_PROFILE_INFO_TEACHINGS: '/hr/moj-profil/informacije/predmeti',
            MY_PROFILE_INFO_ADDITIONAL: '/hr/moj-profil/informacije/o-meni',
            MY_PROFILE_ACCOUNT: '/hr/moj-profil/korisnicki-racun',
            MY_PROFILE_CHILD_INFO: '/hr/moj-profil/djeca',
        },
        EMAIL_CONFIRMED: '/hr/email-povezan',
        STRIPE_CONNECTED: '/hr/racun-povezan',
        STRIPE_FAIL: '/hr/racun-pogreska',
        RESEND_ACTIVATION_TOKEN: '/hr/ponovna-aktivacija',
    },
    SEO: {
        FALLBACK: {
            TITLE: 'Teorem',
            META: 'Teorem nudi 1-na-1 online instrukcije, pomoć s učenjem, pomoć sa zadaćom, pripremanje za ispite, i virtualno učenje za sve učenike. - Savršen instruktor za bilokoga - Plan učenja stvoren za Vas - Virtualna učionica - Započni danas',
        },
        HOW_IT_WORKS: {
            TITLE: 'Najbolje 1-na-1 online instrukcije i pomoć s učenjem | Teorem',
            META: 'Teorem nudi 1-na-1 online instrukcije, pomoć s učenjem, pomoć sa zadaćom, pripremanje za ispite, i virtualno učenje za sve učenike. - Savršen instruktor za bilokoga - Plan učenja stvoren za Vas - Virtualna učionica - Započni danas',
        },
        PRICING: {
            TITLE: 'Cijene visokokvalitetnih online instrukcija | Teorem',
            META: 'Cijene za 1-na-1 50-minutne instrukcije počinju od HRK 60, s prosječnom cijenom od HRK 80, ovisno o instruktoru i planu učenja. Saznajte više o cijenama tako da kontaktirate naše instruktore. ',
        },
        BECOME_TUTOR: {
            TITLE: 'Postani online instruktor | Teorem',
            META: 'Kako postati online instruktor na Teoremu - 1. Registriraj se da postaneš instruktor - 2. Završi kviz o svom predmetu - 3. Dokaži svoj identitet - 4. Započni davat instrukcije',
        },
        TERMS: {
            TITLE: 'Uvjeti korištenja | Teorem',
            META: 'Dobrodošli na Teorem! Ovi uvjeti korištenja definiraju pravila korištenja Teoremove webstranice.',
        },
        PRIVACY: {
            TITLE: 'Politika privatnosti | Teorem',
            META: 'Jedan od naših glavnih prioriteta u Teoremu je privatnost naših posjetitelja. Ova Politika privatnosti sadrži tipove podataka koji su skupljani i spremani od strane Teorema, te na koji ih način koristimo.',
        },
        TAX: {
            TITLE: 'Porez | Teorem',
            META: 'Jedan od naših glavnih prioriteta u Teoremu je privatnost naših posjetitelja. Ova Politika privatnosti sadrži tipove podataka koji su skupljani i spremani od strane Teorema, te na koji ih način koristimo.',
        },
        LEGAL_CENTER: {
            TITLE: 'Pravni centar | Teorem',
            META: 'Jedan od naših glavnih prioriteta u Teoremu je privatnost naših posjetitelja. Ova Politika privatnosti sadrži tipove podataka koji su skupljani i spremani od strane Teorema, te na koji ih način koristimo.',
        },
        SCHOOL: {
            TITLE: 'Teorem | Postani online instruktor L#001',
            META: 'Teorem | Postani online instruktor L#001',
        },
    },
    LANGUAGE_MODAL: {
        WELCOME: 'Dobrodošli na Teorem!',
        ABOUT: 'Molimo odaberite Vašu lokaciju.',
    },
    TOKEN_EXPIRED: {
        RESEND_ACTIVATION_MESSAGE: 'RESEND_ACTIVATION_MESSAGE',
        WELCOME: 'WELCOME',
        DESCRIPTION: 'DESCRIPTION',
    },
    COUNTRY: {
        PL: 'Poljska',
        US: 'Sjedinjene Američke Države',
        HR: 'Hrvatska',
    },
    LANGUAGE: {
        EN: 'Engleski',
        HR: 'Hrvatski',
        PL: 'Poljski',
    },
};
