export const EN_US = {
    MAIN_TITLE: 'Teorem',
    WATERMARK: '© Teorem',
    BIRTH_DATE_FORMAT: 'MM/DD/YYYY',
    DATE_FORMAT: 'Do MMM YYYY',
    ERROR_HANDLING: {
        UNHANDLED_ERROR: 'Unhandled error occured!',
        SUPPORT: 'Something gone wrong, please contact the support',
        CREDIT_CARD_MISSING: 'You are missing a credit card. Please add payment method before creating a booking.',
        DEFAULT_CARD_MISSING: 'You don`t have default payment method. Please select one of your credit cards.',
        NO_CHILD_USERNAME: 'There is no child with that username',
        REGISTERED_SUCCESSFULLY: 'You are registered successfully.',
    },
    TRIAL_POPUP: {
        WELCOME: 'Welcome to Teorem',
        DESCRIPTION: 'Your profile is now ready to use. Fill out the agenda, sit back and relax, wait for students to reach out to you!',
        EXPIRATION: 'Your free trial will expire in 30 days!',
        REMIND: 'Don’t worry, we’ll remind you on time.',
        CONFIRM_BUTTON: 'Got it',
    },
    EMAIL_CONFIRMATION_POPUP: {
        WELCOME: 'Welcome to Teorem!',
        DESCRIPTION: 'Please confirm your e-mail address by clicking the link we sent you.',
        EXPIRATION: 'Resend confirmation email',
        RESEND_TIMEOUT: 'Try again in',
        CONFIRM_BUTTON: 'Got it',
    },
    ONBOARDING: {
        NAVIGATION: {
            TITLE: 'Welcome to Teorem!',
            DESCRIPTION: "Please follow the onboarding process to finish up your profile. It'll take only few minutes.",
            STEP_ONE: {
                TITLE: 'Personal information',
                DESCRIPTION: 'Let us get to know you a little bit better',
            },
            TUTOR: {
                STEP_TWO: {
                    TITLE: 'Card Details',
                    DESCRIPTION: "It's never too much information",
                },
            },
            PARENT: {
                STEP_TWO: {
                    TITLE: "Child's List",
                    DESCRIPTION: 'Fill in information for your child and set his/her login credentials.',
                },
            },
        },
        EDIT_CHILD_DETAILS: "Edit child's details",
    },
    FORM_VALIDATION: {
        INVALID_EMAIL: 'Invalid email',
        INVALID_IMAGE: 'Image has to be either jpg,png,jpeg or svg and less than 2MB in size.',
        IMAGE_TYPE: 'Image has to be either jpg,png,jpeg or svg',
        IMAGE_SIZE: 'Image has to be less than 2MB in size.',
        TOO_SHORT: 'Too Short!',
        TOO_LONG: 'Too Long!',
        TOO_BIG: 'Number is too big',
        REQUIRED: 'This field is required',
        LOWERCASE: 'Include at least one lowercase letter (a-z)',
        UPPERCASE: 'Include at least one uppercase letter (A-Z)',
        NUMBER: 'Include at least one number (0-9)',
        MIN_CHARACTERS: 'Be a minimum of 8 characters',
        SPECIAL_CHAR: 'Include at least one special character (@, $, !, %, *, ?, &...)',
        PASSWORD_MUST: 'Password must',
        PASSWORD_MATCH: 'Passwords must match',
        PASSWORD_STRENGTH: 'Password must meet the conditions',
        PRICE: 'Price can`t be lower than ',
        FUTURE_DATE: 'Date can`t be in the future',
        VALID_DATE: 'Date is invalid',
        PHONE_SHORT: 'Phone number must have at least 10 digits',
        PHONE_NUMBER: 'Invalid phone number',
        TUTOR_AGE: 'Tutor must have more than 18 years old',
        WRONG_REQUIREMENTS: 'You didn`t fulfill all field requirements',
        NEGATIVE: 'Can`t be a negative number',
        USERNAME_EXIST: 'Username already exists',
        EMAIL_EXISTS: 'This email is already in use. Please log in.',
        TOAST: {
            UPDATE: 'You successfully updated the child information',
            CREATE: 'You successfully created a child',
            DELETE: 'You successfully deleted a child',
        },
        LEVEL_REQUIRED: 'Level is required',
        SUBJECT_REQUIRED: 'Subject is required',
        CHILD_REQUIRED: 'Child is required',
        EMAIL_IN_PASSWORD: "Can't contain your email address",
        NAME_IN_PASSWORD: "Can't contain your name",
        NAME_EMAIL_NOT_IN_PASSWORD: "Can't contain your name or email address",
    },
    NAVIGATION: {
        MY_BOOKINGS: 'Calendar',
        MY_REVIEWS: 'My reviews',
        SEARCH_TUTORS: 'Search tutors',
        TUTOR_MANAGMENT: 'Tutor managment',
        MY_PROFILE: 'My Profile',
        COMPLETED_LESSONS: 'Completed lessons',
        CHAT: 'Chat',
        DASHBOARD: 'Dashboard',
        EARNINGS: 'Earnings',
        SHARE_PROFILE: 'Share profile',
        TEXT_COPIED: 'Profile copied to clipboard!',
    },
    SEARCH_TUTORS: {
        AVAILABILITY: {
            TIME_OF_DAY: {
                LABEL: 'Time of day',
                BEFORE_NOON: 'Pre 12 pm',
                NOON_TO_FIVE: '12 - 5 pm',
                AFTER_FIVE: 'After 5 pm',
            },
            DAY_OF_WEEK: {
                LABEL: 'Day of week',
                MON: 'mon',
                TUE: 'tue',
                WED: 'wed',
                THU: 'thu',
                FRI: 'fri',
                SAT: 'sat',
                SUN: 'sun',
            },
        },
        PLACEHOLDER: {
            LEVEL: 'Level',
            SUBJECT: 'Subject',
            AVAILABILITY: 'All availability',
        },
        NO_OPTIONS_MESSAGE: 'Please select level first',
        TITLE: 'Search tutors',
        VIEW_PROFILE: 'View profile',
        BOOK_LESSON: 'Book a lesson',
        RESET_FILTER: 'Reset search',
        TUTOR_AVAILABLE: 'Tutors Available',
        COMPLETED_LESSONS: 'completed lessons',
        SUBJECT_LIST: {
            MORE: 'more',
        },
        NO_RESULT: {
            TITLE: 'No results',
            DESC: ' Hmm, looks like there are no tutors for this specific search.',
        },
        NOT_FILLED: 'This information is not filled out yet',
        YEARS_OF_EXPERIENCE: 'Years of experience: ',
        NUMBER_OF_REVIEWS: 'reviews',
        NO_REVIEWS: 'No reviews yet',
        NO_COMPLETED_LESSONS: 'No completed lessons yet',
        NO_VIDEO: 'Tutor did not upload a video yet',
        TUTOR_PROFILE: {
            ABOUT_ME: 'About me',
            ABOUT_TEACHINGS: 'About subjects',
            EMPTY_STATE_ABOUT: 'This tutor has not filled out about me section',
            EMPTY_STATE_LESSON: 'This tutor has not filled out his profile about subjects',
            ADDITIONAL_INFORMATION_TITLE: 'About me',
            ADDITIONAL_INFORMATION_DESC: 'Edit and update your about me section of the profile.',
            UPDATE_ADDITIONAL_INFO_SUCCESS: 'Additional information updated!',
            UPDATE_TEACHINGS_SUCCESS: 'Subjects updated!',
            LESSON_LENGTH: '50 min lesson',
            FORM: {
                ABOUT_TUTOR_LABEL: 'Tell us more about yourself*',
                ABOUT_TUTOR_PLACEHOLDER: 'What describes you best, what are your hobbies, approach...',
                ABOUT_TUTOR_REQUEST: 'Minimum 50 words.',
                ABOUT_TUTOR_TOOLTIP: 'In detail describe yourself, your interests, experience and hobbies.',
                TOOLTIP_EFFECTIVE: 'Most visited profiles contain 100+ words in this category.',
                ABOUT_LESSONS_LABEL: 'Tell us more about your lessons*',
                ABOUT_LESSONS_PLACEHOLDER: 'Describe your lessons, approach, way of teaching...',
                ABOUT_EFFECTIVE: 'Most visited profiles contain 100+ words in this category.',
                ABOUT_LESSONS_TOOLTIP: 'In detail describe how you work with students.',
                ABOUT_LESSONS_TOOLTIP_2: 'Describe which methods of tutoring to you use (visual, auditory, kinesthetic, etc.) ',
                SUBMIT_BTN: 'Save',
            },
            NO_PRICE: 'There is no price',
            HOUR: 'hr',
        },
        PRICE_SORT: 'Sort by',
        SORT_LOW: 'Low price',
        SORT_HIGH: 'High price',
        SORT_NONE: 'None',
    },
    TUTOR_MANAGMENT: {
        TITLE: 'Tutor list',
        UNPROCESSED: 'UNPROCESSED',
        APPROVED: 'APPROVED',
        DENIED: 'DENIED',
        SUBJECT_LIST: {
            MORE: 'more',
        },
        NO_RESULT: {
            TITLE: 'No results',
            DESC: ' Hmm, looks like there are no tutors for this specific search.',
        },
        TABLE: {
            FIRST_NAME: 'FIRST NAME',
            LAST_NAME: 'LAST NAME',
            EMAIL: 'EMAIL',
            COUNTRY: 'COUNTRY',
            USERNAME: 'USERNAME',
            DATE_OF_BIRTH: 'DATE OF BIRTH',
            PREVIEW_PROFILE: 'Preview profile',
            PHONE_NUMBER: 'PHONE NUMBER',
        },
        ACTIONS: {
            APPROVE: 'Approve',
            DECLINE: 'Decline',
            DELETE: 'Delete',
            APPROVE_TUTOR: 'Approve Tutor',
            DECLINE_TUTOR: 'Decline Tutor',
            DELETE_TUTOR: 'Delete Tutor',
            EDIT_NOTE: 'Edit note',
            CANCEL: 'Cancel',
        },
        NOTE: 'Note',
        NOTE_PLACEHOLDER: 'Enter a note here...',
        DETAILS: ' DETAILS',
        SEARCH_PLACEHOLDER: 'Search tutors',
    },
    CHAT: {
        PLACEHOLDER: 'Send a message to start a conversation',
        SEARCH_PLACEHOLDER: 'Search chat',
        BOOK_SESSION: 'Book a session',
        FREE_CONSULTATION: 'Start a free call',
        ACCEPT_CONSULTATION: 'Accept',
        DENY_CONSULTATION: 'Deny',
        DENY_FREE_CONSULTATION: 'Cancel call',
        CHAT_REQUEST_TITLE: 'Incoming video call',
        CHAT_MISSED_CALL: '<span class="chat-missed-incoming-message">Missed call from userInsert={user}</span>',
    },
    ROLE_SELECTION: {
        TITLE: 'Register',
        ACTION: 'I want to register as a',
        ACCOUNT: 'Already have an account?',
        LOG_IN: 'Log in',
        STUDENT_TITLE: 'Student',
        STUDENT_DESCRIPTION: 'Here to expand my knowledge.',
        PARENT_TITLE: 'Parent / Guardian',
        PARENT_DESCRIPTION: 'Here to help my child learn.',
        TUTOR_TITLE: 'Tutor',
        TUTOR_DESCRIPTION: 'Here to teach students what I know.',
    },
    RESET_PASSWORD: {
        TITLE: 'Forgot Password',
        FORM: {
            EMAIL: 'Email',
            SUBMIT_BTN: 'Reset Password',
        },
        SUCCESS: 'Reset email has been sent.',
        BACK_BTN: 'Back to Login',
    },
    LOGIN: {
        TITLE: 'Log in',
        FORM: {
            EMAIL: 'Email',
            PASSWORD: 'Enter your Password',
            SUBMIT_BTN: 'Log in',
        },
        FORGOT_PASSWORD: 'Forgot Password?',
        ACCOUNT: 'Dont have an account?',
        REGISTER: 'Register',
    },
    BACKEND_ERRORS: {
        BASE: {
            UNAUTHORIZED: 'You are not authorized.',
            FORBIDDEN: 'You do not have permission.',
            NOT_FOUND: 'Resource not found',
            CONFLICT: 'Resource already exists',
            INTERNAL_ERROR: 'Server error',
            BAD_REQUEST: 'Bad request',
        },
        USER: {
            EMAIL_CONFLICT: 'User with this email already exists',
            LOGIN_WRONG_FORM_DATA: {
                BODY: 'Invalid login credentials. Please try again.',
                TITLE: "Let's try that again",
            },
            NOT_FOUND: 'User not found',
            USERNAME_CONFLICT: 'Username already exists',
            PROFILE_NOT_ACTIVE: {
                SENT_AGAIN: 'Please confirm your e-mail address.',
            },
        },
        LEVEL: {
            NOT_FOUND: 'Level not found',
        },
        FILE: {
            NOT_FOUND: 'File not found',
        },
        SUBJECT: {
            NOT_FOUND: 'Subject not found',
        },
        TUTOR: {
            NOT_FOUND: 'Tutor not found',
        },
        TUTOR_AVAILABILITY: {
            CONFLICT: 'Tutor availability has already been set',
            NOT_FOUND: 'Tutor availability not found',
        },
        BOOKING: {
            NOT_FOUND: 'Booking not found',
        },
        ROLE: {
            NOT_FOUND: 'Role not found',
        },
        REVIEW: {
            CONFLICT: 'You have already written a review',
        },
        INPUT_FAILED: 'Wrong input data',
        INVALID_IMAGE: 'Invalid image',
    },
    NOT_FOUND: {
        TITLE: 'Page not found',
        SUBTITLE: ' Sorry,looks like we sent you the wrong way. Let us guide you back!',
        BACK_BUTTON: 'Back to home',
    },
    UPCOMING_LESSONS: {
        TITLE: 'UPCOMING EVENTS',
        EMPTY: 'There are no upcoming lessons',
    },
    REGISTER: {
        TITLE: 'Sign up',
        FORM: {
            BUTTONS_SUBTITLE: 'Account type', 
            BUTTON_PARENT: 'Parent',
            BUTTON_STUDENT: 'Learner',
            BUTTON_TUTOR: 'Tutor',
            NAME_SUBTITLE: 'Legal name',
            FIRST_NAME: 'First Name on ID',
            FIRST_NAME_PLACEHOLDER: 'Enter First Name',
            LAST_NAME: 'Last Name on ID',
            LAST_NAME_PLACEHOLDER: 'Enter Last Name',
            LAST_NAME_INFO: 'Make sure this matches the name on your government ID.',
            DATE_OF_BIRTH_SUBTITLE: 'Date of birth',
            DATE_OF_BIRTH: 'Birthdate',
            DATE_OF_BIRTH_PLACEHOLDER: 'Enter your birthdate',
            DATE_OF_BIRTH_INFO: 'To sign up on Teorem, you need to be at least 18 years old.',
            CONTACT_INFO_SUBTITLE: 'Contact info',
            EMAIL: 'Email',
            EMAIL_PLACEHOLDER: 'Enter Email',
            PHONE_NUMBER: 'Phone number',
            PHONE_INFO: "Your contact details will not be visible to the public. We'll email you lesson confirmations and receipts.",
            PASSWORD_SUBTITLE: 'Password',
            PASSWORD: 'Password',
            PASSWORD_PLACEHOLDER: 'Type your password',
            CONFIRM_PASSWORD: 'Repeat Password',
            CONFIRM_PASSWORD_PLACEHOLDER: 'Type your password',
            AGREE: "By clicking Agree and continue, I agree to Teorem's Terms of Service and Privacy Policy.",
            SUBMIT_BUTTON: 'Agree and continue',
            MARKETING_DISCLAIMER:
                'Teorem will send you special deals, marketing emails and push notifications. You can opt out of receiving these at any time in your account settings or directly from the marketing notification.',
            MARKETING_DISCLAIMER_CHECKBOX: "I don't want to receive marketing messages from Teorem.",

            COUNTRY: 'Country*',
            COUNTRY_PLACEHOLDER: 'Choose your country',
            PROFILE_IMAGE: 'Profile Image',
            CHILD_NAME: "Child's Name",
            CHILD_NAME_PLACEHOLDER: "Enter Child's Name",
            CHILD_DATE_OF_BIRTH: "Child's Date of Birth*",
            USERNAME: 'Username*',
            EMAIL_CONFLICT: 'Email already in use',
            TERMS_AND_CONDITIONS:
                '<div>By clicking <strong>Agree and continue</strong>, I agree to Teorem\'s <a href="https://teorem.co/en/terms" target="_blank">Terms of Service</a> and <a href="https://teorem.co/en/privacy-policy" target="_blank">Privacy Policy</a>.</div>',
        },
        CARD_DETAILS: {
            FIRST_NAME: 'First Name*',
            LAST_NAME: 'Last Name*',
            CARD_NUMBER: 'Card Number*',
            EXPIRY_DATE: 'Expiry date*',
            EXPIRY_PLACEHOLDER: 'MM / YY',
            CVV: 'CVV*',
            ZIP_CODE: 'ZIP / Postal Code*',
            ZIP_CODE_PLACEHOLDER: 'Enter ZIP / Postal Code',
        },
        BACK_BUTTON: 'Back to selection',
        NEXT_BUTTON: 'Next',
        BACK_TO_REGISTER: 'Back to register',
        FINISH: 'Finish',
        BACK_TO_STEP_ONE: 'Back to step 1',
        BACK_TO_STEP_TWO: 'Back to step ',
        SAVE_BUTTON: 'Save',
        DELETE_BUTTON: 'Delete',
        BACK_TO_LIST: 'Back to list',
        NAVIGATION: {
            STEP1: {
                TITLE: 'Personal information',
                DESC: 'Let us get to know you a little bit better',
            },
            STEP2: {
                TITLE: 'Child`s List',
                DESC: 'Fill in information for your child and set his/her login credentials.',
            },
        },
    },
    ADD_CHILD: {
        TITLE: 'Add new Child',
        DESCRIPTION: 'Select to Add new Child',
        SAVE: 'Save Child',
    },
    MY_BOOKINGS: {
        TITLE: 'Calendar',
        NOTIFICATION_PART_1: 'You have ',
        NOTIFICATION_PART_2: ' Lesson(s) today!',
        UNAVAILABLE: 'Unavailable',
        UNAVAILABILITY: 'Unavailability',
        UNABLE_MESSAGE: 'Can`t add event before current time and 3 hours after now',
        TIME_AFTER: 'Start time can`t be after end time',
        TIME_SAME: 'Start time and end time can`t be the same',
        CALENDAR: {
            TITLE: 'CALENDAR',
        },
        MODAL: {
            DELETED_USER: 'Deleted user',
            ACCEPT: 'Accept',
            PROPOSE: 'Propose a new time',
            DELETE: 'Delete',
            DENY: 'Deny',
            TUTOR_CALENDAR: 'Tutor calendar',
            TIME: 'Time',
            DATE: 'Date',
            WHOLE_DAY: 'Whole day',
            SET_UNAVAILABILITY: 'Set unavailability',
            CANCEL: 'Cancel',
        },
        INFORMATION: {
            TITLE: 'BOOKING INFORMATION',
            CARD1: {
                TITLE: 'How to book a slot?',
                DESC: 'You can book a slot with a tutor by visiting their profile and clicking "Book Now". You will be able to see the tutor`s availability and select a time that works for both of you.',
            },
            CARD2: {
                TITLE: 'How to cancel a booking?',
                DESC: 'You can cancel a booking in your calendar by clicking on the booking and clicking "Cancel". If you cancel a booking 24 hours in advance, you will receive a full refund.',
            },
        },
    },
    BOOK: {
        TITLE: 'Book a Slot',
        FORM: {
            LEVEL: 'Level',
            LEVEL_PLACEHOLDER: 'Select Level',
            SUBJECT: 'Subject',
            CHILD: 'Child',
            CHILD_PLACEHOLDER: 'Select a child',
            TIME: 'Time* (Session length is 50min)',
            TIME_PLACEHOLDER: 'Time',
            SUBMIT: 'Book',
            UPDATE: 'Update booking',
            CANCEL: 'Cancel',
        },
        JOIN: 'Join',
    },
    MY_REVIEWS: {
        TITLE: 'My Reviews',
        COUNT_TITLE: 'REVIEWS',
        AVG_SCORE: 'Review score',
        PUBLISHED: 'Published',
        STAR: {
            1: '1 Star',
            2: '2 Stars',
            3: '3 Stars',
            4: '4 Stars',
            5: '5 Stars',
        },
        NO_RESULT: {
            TITLE: 'No results',
            DESC: 'You don`t have any reviews yet.',
            DESC_ADMIN: 'No reviews yet.',
        },
        DATE_AGO: {
            BEFORE: '',
            YEAR: ' year ago',
            YEARS: ' years ago',
            MONTH: ' month ago',
            MONTHS: ' months ago',
            WEEK: ' week ago',
            WEEKS: ' weeks ago',
            DAY: ' day ago',
            DAYS: ' days ago',
            TODAY: ' today',
        },
    },
    COMPLETED_LESSONS: {
        TITLE: 'Completed Lessons',
        TUTORS_AVAILABLE: 'Tutors Available',
        LESSONS_AVAILABLE: 'Lessons available',
        EMPTY_LESSONS_LIST: 'There is no completed lessons available',
        EMPTY_LESSONS_TITLE: 'No Lessons',
        LEAVE_REVIEW: 'Leave review',
        VIEW_CALENDAR: 'View Calendar',
        VIDEOS_TITLE: 'Play / Download Lessons',
        EMPTY_VIDEOS: 'There is no completed lessons available to select',
        LINK: 'Go to Calendar',
        COUNT_EXTENSION: 'Lessons',
    },
    WRITE_REVIEW: {
        SECTION_TITLE: 'Write a review',
        TITLE: 'Title',
        RATING: 'Rating',
        REVIEW: 'Review',
        SUBMIT: 'Post',
        CANCEL: 'Cancel',
        HEADING_PLACEHOLDER: 'Write title of your review',
        TEXT_PLACEHOLDER: 'Describe your overall experience with this lesson',
    },
    COMPLETE_PROFILE: {
        TITLE: 'Complete my profile',
        DESCRIPTION: 'FIll out the missing information to make your profile complete',
        PROFILE_SETTINGS: 'Profile Settings',
        GENERAL_AVAILABILITY: 'Availability',
        MY_TEACHINGS: 'Subjects',
        ABOUT_ME: 'About me',
        ACCOUNT: 'Account',
        CHILD_INFO: 'Child information',
        EARNINGS: 'Earnings',
    },
    COMPLETE_TUTOR_PROFILE_CARD: {
        TITLE: 'Complete your profile!',
        DESCRIPTION: 'Please complete the rest of your profile data to start tutoring.',
    },
    ACCOUNT: {
        CHANGE_PASSWORD: {
            TITLE: 'Change password',
            DESCRIPTION: 'Confirm your current password, then enter a new one.',
            CURRENT_PASSWORD: 'Current Password',
            CURRENT_PASSWORD_PLACEHOLDER: 'Enter your current password',
            NEW_PASSWORD: 'Enter your new password',
            NEW_PASSWORD_PLACEHOLDER: 'Enter your new password',
            CONFIRM_PASSWORD: 'Confirm Password',
            CONFIRM_PASSWORD_PLACEHOLDER: 'Enter your new password',
        },
        CARD_DETAILS: {
            TITLE: 'Card details',
            DESCRIPTION: 'Select default payment method or add new one.',
            TITLE_TUTOR: 'Payment details',
            DESCRIPTION_TUTOR: 'Select how you would like to get paid for tutoring.',
            ADD_NEW: 'Add new Card',
            ADD_NEW_DESC: 'Select to add new Card',
        },
        NEW_CARD: {
            ADD: 'ADD NEW CARD',
            NAME: 'First Name*',
            NAME_PLACEHOLDER: 'Enter First Name',
            SURNAME: 'Last Name*',
            SURNAME_PLACEHOLDER: 'Enter Last Name',
            CITY: 'City*',
            CITY_PLACEHOLDER: 'Enter City Name',
            ADDRESS1: 'Address 1*',
            ADDRESS1_PLACEHOLDER: 'Enter first address',
            ADDRESS2: 'Address 2',
            ADDRESS2_PLACEHOLDER: 'Enter second address',
            CARD_NUMBER: 'Card Number*',
            CARD_NUMBER_PLACEHOLDER: '**** **** **** ****',
            EXPIRY: 'Expiry date*',
            EXPIRY_PLACEHOLDER: 'MM / YY',
            CVV: 'CVV*',
            CVV_PLACEHOLDER: '***',
            ADD_BUTTON: 'Add New Card',
            CANCEL_BUTTON: 'Cancel',
            ZIP: 'Zip',
            ZIP_PLACEHOLDER: '12345',
            COUNTRY: 'Country',
            COUNTRY_PLACEHOLDER: 'Choose your country',
        },
        SUBMIT: 'Save',
    },
    MY_PROFILE: {
        TITLE: 'My Profile',
        PREVIEW: 'Preview profile',
        ABOUT_ME: {
            OCCUPATION: 'Yor current Occupation*',
            OCCUPATION_PLACEHOLDER: 'What’s your current Occupation',
            YEARS: 'Years of professional experience (optional)',
            YEARS_PLACEHOLDER: 'How many years of professional experience you have',
            ABOUT_YOURSELF: 'Tell us more about yourself*',
            ABOUT_LESSON: 'Tell us more about your lessons*',
        },
        MY_TEACHINGS: {
            TITLE: 'My Subjects',
            DESCRIPTION: 'Edit and update your subjects information',
            ADD_NEW: 'Add new Subject',
            ADD_DESC: 'Select to add new Subject',
            DELETED: 'Subject is deleted',
            CREATED: 'Subject is created',
            EDIT_TITLE: 'EDIT SUBJECT DETAILS',
            ADD_TITLE: 'ADD NEW SUBJECT',
            SUBJECT: 'Select subject you teach',
            LEVEL: 'Select levels that you are able to teach',
            PRICING: 'Pricing',
            PRICING_PLACEHOLDER: '',
            HOUR_ABRV: 'hr',
            SAVE: 'Save information',
            DELETE: 'Delete',
            CANCEL: 'Cancel',
        },
        GENERAL_AVAILABILITY: {
            TITLE: 'Availability',
            DESCRIPTION: 'Edit and update your availability information',
            UPDATED: 'Availability updated',
            CREATED: 'Availability created',
        },
        PROFILE_SETTINGS: {
            TITLE: 'Personal Information',
            DESCRIPTION: 'Edit and update your personal information',
            FIRST_NAME: 'First Name*',
            FIRST_NAME_PLACEHOLDER: 'Enter your first name',
            LAST_NAME: 'Last Name*',
            LAST_NAME_PLACEHOLDER: 'Enter your first name',
            PHONE: 'Phone Number*',
            COUNTRY: 'Country*',
            BIRTHDAY: 'Date of Birth*',
            IMAGE: 'Profile Image*',
            UPLOAD_IMAGE: 'Drag and drop to upload',
        },
        PROFILE_ACCOUNT: {
            STRIPE: 'Go to Stripe',
            STRIPE_ALREADY_CONNECTED: 'Stripe account  has already been connected, if you wish to connect to other account, then press again',
            STRIPE_CONNECTED: 'Connected',
            STRIPE_DISCONNECTED: 'Disconnected',
            STRIPE_CARD_DECLINED: 'Error creating Stripe account',
            SUCCESS_PASSWORD: 'You successfully changed a password',
            STRIPE_DEFAULT_PAYMENT_METHOD_UPDATED: 'Default payment method is updated',
        },
        CHILD: {
            ADD_TITLE: 'ADD NEW CHILD',
            EDIT_TITLE: 'EDIT A CHILD',
            FIRST_NAME: 'First Name',
            FIRST_NAME_PLACEHOLDER: 'Enter Child Name',
            LAST_NAME: 'Last Name',
            LAST_NAME_PLACEHOLDER: 'Enter Child Last name',
            USERNAME: 'Username',
            USERNAME_PLACEHOLDER: 'Enter username',
            PASSWORD: 'Enter a new password',
            PASSWORD_PLACEHOLDER: 'Type your password',
            PASSWORD_OPTIONAL: 'This field is optional, if you want to change current child`s password you can do it in field below.',
            DELETE: 'Delete',
            CANCEL: 'Cancel',
        },
        TRANSLATION: {
            TITLE: 'Translation',
            SUBTITLE: 'Choose your language for the application.',
        },
        SUBMIT: 'Save',
        TUTOR_DISABLE: {
            TITLE: 'Profile visibility',
            SUBTITLE: 'Profile is visible',
            YES: 'Yes',
            NO: 'No',
        },
    },
    DASHBOARD: {
        TITLE: 'Dashboard',
        SCHEDULE: {
            TITLE: 'TODAY`S SCHEDULE',
            BUTTON: 'Join',
            EMPTY: 'There are no scheduled bookings today',
        },
        MESSAGES: {
            TITLE: 'UNREAD MESSAGES',
            BUTTON: 'Chat',
            EMPTY: 'No unread messages',
        },
        BOOKINGS: {
            TOTAL: 'Total',
            EMPTY: 'No bookings',
            EMPTY_SUBTITLE: 'Hmm, looks like there are no bookings yet.',
        },
        NOTIFICATIONS: {
            TITLE: 'NOTIFICATIONS',
            CLEAR: 'Mark as read',
            ALL: 'See all',
            EMPTY: 'There is no unread notifications',
        },
        COMING_SOON: {
            TITLE: 'Coming soon',
            SUBTITLE: 'We are working hard to get this up and running',
        },
    },
    NOTIFICATIONS: {
        TITLE: 'All Notifications',
        EMPTY: 'There are no notifications',
        BOOKING_CLASS_STARTING: {
            DESCRIPTION: 'Class starting soon',
            TITLE: 'Booking starting soon',
        },
        CHAT_MISSED_CALL: {
            DESCRIPTION: 'Missed call from',
            TITLE: 'Missed call',
        },
        BOOKING: {
            STUDENT: 'Student',
            PARENT: 'Parent',
            TUTOR: 'Tutor',
        },
        BOOKING_NEW_BOOKING: {
            TITLE: 'New booking',
            DESCRIPTION: '',
            MADE_BOOKING_TEXT: 'made new booking for',
        },
        BOOKING_RESCHEDULE_PROPOSITION: {
            TITLE: 'Reschedule proposition',
            DESCRIPTION: '',
            PROPOSING_NEW_TIME_TEXT: 'wants to propose new time for booking',
        },
        BOOKING_ACCEPTED: {
            TITLE: 'Booking accepted',
            DESCRIPTION: 'accepted booking for',
        },
        BOOKING_CANCELED: {
            TITLE: 'Booking canceled',
            DESCRIPTION: '',
            CANCEL_TEXT: 'canceled booking for',
        },
        BOOKING_DELETED: {
            TITLE: 'Deleted unaccepted booking',
            DESCRIPTION: {
                PART1: 'Booking is deleted because',
                PART2: "didn't accept it.",
            },
        },
        CHAT_HAS_MISSED_CALL: ' has missed your call',
    },
    EARNINGS: {
        TITLE: 'Earnings',
        GENERAL: {
            TITLE: 'GENERAL INFORMATION',
            BOOKINGS: 'Total bookings',
            STUDENTS: 'Total students',
            REVIEWS: 'Total reviews',
            REVENUE: 'Total revenue',
            CURRENCY: '€',
        },
        REVENUE: {
            TITLE: 'REVENUE',
            GRAPH_LEGEND: 'Income',
        },
        DETAILS: {
            TITLE: 'DETAILS',
            TABLE: {
                MONTH: 'Month',
                BOOKINGS: 'Bookings',
                STUDENTS: 'Students',
                REVIEWS: 'Reviews',
                REVENUE: 'Revenue',
                EMPTY: 'There is no details',
            },
        },
    },
    ROUTER_MODAL: {
        TITLE: 'Save changes?',
        DESC: 'Do you want to save your changes before leaving the page?',
        SAVE: 'Save',
        NO: 'No',
    },
    TUTOR_PROFILE: {
        AVAILABILITY_EMPTY: 'Tutor did not fill out the availability table',
        PRICING: 'Pricing',
        RATING_TITLE: 'Rating',
        COMPLETED_LESSONS: 'Completed lessons',
        BOOK: 'Book a lesson',
        SEND: 'Send a message',
        EMPTY: 'User not found',
        SUBJECTS: {
            TITLE: 'Subjects',
            SUBJECT: 'Subject',
            QUALIFICATION: 'Qualification',
            PRICE: 'Price',
            HOUR_ABRV: 'hr',
            EMPTY: 'There are no subjects offered',
        },
        RATING: {
            TITLE: 'Ratings & reviews',
            TOTAL: 'reviews total',
        },
        PRE12: 'Pre 12 pm',
        ON12: '12 - 5 pm',
        AFTER5: 'After 5 pm',
    },
    LANDING: {
        HEADER: {
            NAV_1: 'How it works',
            NAV_2: 'Pricing',
            NAV_3: 'Become a tutor',
            NAV_4: 'Blog',
            SIGN_IN: 'Sign in',
            GET_STARTED: 'Get started',
        },
        FOOTER: {
            TITLE_TEXT: 'If you have additional questions, feel free to contact us via email ',
            APP_NAME: 'Teorem Ltd',
            ADDRESS: '',
            MOBILE: '',
            EMAIL: 'support@teorem.co',
            LEGAL: {
                TITLE: 'About Teorem',
                LEGAL: 'Terms of Service',
                PRIVACY: 'Privacy policy',
                REFUND: 'Refund and Payment Policy',
                CONDUCT: 'User Code of Conduct',
                TUTOR: 'Become a tutor',
                LEGAL_CENTER: 'Legal center',
            },
            PAYMENT: {
                TITLE: 'Payment methods',
            },
        },
        LOCALE: {
            TITLE: 'Please select',
            TITLE_COUNTRY: 'Country',
            TITLE_LANGUAGE: 'Language',
            SEARCH: 'Search',
            SUBMIT_COUNTRY: 'Save preferred country',
            SUBMIT_LANGUAGE: 'Save preferred language',
        },
    },
    HOW_IT_WORKS: {
        HERO: {
            TITLE: 'Online tutoring that releases potential',
            SUBTITLE: 'Private One-on-One 50-minute online lessons uniquely tailored for each student. All school subjects and age groups covered.',
        },
        SUBTITLE_1: 'As featured around the world',
        SUBTITLE_2: 'Frequently asked questions',
        TEXT_CARD_1: {
            TITLE: 'Large circle of vetted and reviewed high-quality tutors',
            SUBTITLE:
                'Our tutors are all vetted to ensure they have experience in tutoring. They are reviewed by parents and students after each lesson.',
        },
        TEXT_CARD_2: {
            TITLE: 'Chat with any tutor for free before you book.',
            SUBTITLE:
                'Share any files or assignments with the tutor and have a free video call to make sure the tutor you cohose is the perfect fit for you.',
        },
        TEXT_CARD_3: {
            TITLE: 'Our virtual classroom lets you rewatch lessons.',
            SUBTITLE:
                'Our lessons are so much more than just video calls. Our virtual classroom contains a digital whiteboard, recording function, screen sharing and much more.',
        },
        TEXT_CARD_4: {
            TITLE: 'Only pay for what you use.',
            SUBTITLE:
                'Teorem won’t charge you anything until you’ve found your perfect tutor, had a chat with them and booked your first lesson. No sign-up fees, no subscriptions, just plain pay-as-you-go. Safe and secure payment gateway accepting a wide range of options.',
        },
        REVIEW_1: {
            TEXT: "Teorem helped my son improve his physics marks! I love that it's all online and that I no longer need to drive him to the other side of the city!",
            NAME: 'Sandra B.',
            OCCUPATION: "Luke's mother",
        },
        REVIEW_2: {
            TEXT: 'The complimentary video call has allowed me to chat with several tutors before deciding on one that best fits my child’s needs. I couldn’t be happier!',
            NAME: 'Renata J.',
            OCCUPATION: "Thea and Mark's mother",
        },
        REVIEW_3: {
            TEXT: 'With Teorem’s virtual classroom I can finally learn through interactive exercises and fun design. I love the fact that I can rewatch the lessons!',
            NAME: 'John K.',
            OCCUPATION: 'Economics student',
        },
        BUTTON_1: 'Get started',
        FAQ: {
            QUESTION_1: 'Why One-to-one tutoring?',
            ANSWER_1:
                'One-to-one online tutoring gives kids the opportunity to learn at their own pace and in a way that matches their learning style. Teens are often too shy to put their hand up in class – especially if they’re struggling. The reassurance of one-to-one tutoring means they can ask all the questions they want, and go over topics as much as they need until they get it.',
            QUESTION_2: 'What are the benefits of online tutoring?',
            ANSWER_2:
                'Teorem’s online tutoring lets kids unleash their full potential. Our digital whiteboard allows tutors to explain complex concepts in an easy and thoughtful manner. Whatever your child needs help with, their tutor will guide them through. In addition, the online model removes geographic constraints of finding a high-quality tutor.',
            QUESTION_3: 'What qualifications do the tutors have?',
            ANSWER_3:
                'Applicants undergo multiple tests before becoming tutors on our platform. They are tested on their subject knowledge and personal and teaching skills. A very small number of applicants who apply are selected.',
            QUESTION_4: 'How do online lessons work?',
            ANSWER_4:
                'We have our own virtual lesson space including video chat, messaging and an interactive whiteboard which makes it easy for students and tutors to communicate, discuss tricky concepts and do practice questions together. With the live video chat, they can have a natural back-and-forth conversation – just like on FaceTime, WhatsApp and other apps teens use all the time. All our lessons last 50 minutes.',
        },
    },
    PRICING: {
        HERO: {
            TITLE: 'Get the most value for your money',
            SUBTITLE: 'Teorem helps students of all ages to achieve their academic goals.',
            LABEL_1: '1-on-1 lectures with individually selected teachers',
            LABEL_2: 'Lecture by lecture or long-term learning plan',
            LABEL_3: 'Flexible scheduling and rescheduling of appointments',
        },
        COMPARE_PRICES: 'Compare prices of tutors for ',
        SUBTITLE_1: 'Starting is as simple as 1,2,3!',
        SUBTITLE_4: "Monitor your child's progress",
        SUB_SUBTITLE_4: "After each lesson, you will receive a report on the material covered and your child's activities",
        SUBTITLE_2: 'Teorem benefits',
        SUBTITLE_3: 'Frequently asked questions',
        BUTTON_1: 'Get started',
        TEXT_CARD_1: {
            TITLE: 'How much does online tutoring cost?',
            SUBTITLE: 'Teorem’s tutors set their own prices, based on their experience and availability. Lesson prices start from EUR 10.',
        },
        FAQ: {
            QUESTION_1: 'How much does a tutor cost?',
            ANSWER_1:
                'Our tutors set their own prices based on their experience, qualifications and availability. Prices start at $XX. You can see all the tutors who match your budget with the handy price filter on our Tutor Search.',
            QUESTION_2: 'How to find a tutor?',
            ANSWER_2:
                'Finding a tutor is easy. After you register an account with Teorem, you can search and filter by subject, level, price, experience and more.',
            QUESTION_3: 'Can I change a tutor if I am unhappy?',
            ANSWER_3: 'Of course! You are free to change a tutor anytime. We’ll take care of the admin, so you don’t have to.',
            QUESTION_4: 'Can I get a refund?',
            ANSWER_4: 'Yes, if you cancel up to 24 hours in advance, we will issue you a full refund for your lesson.',
        },
        STEP_1: {
            TITLE: 'Initial Assessment',
            SUBTITLE:
                'During an initial call, Teorem’s tutor will determine the student’s needs and choose the ideal study based on the student’s personality, learning style and requirements.',
        },
        STEP_2: {
            TITLE: 'Trial Lesson',
            SUBTITLE: 'A trial lesson gives you a chance to see how the tutor teaches and how the Teorem platform works.',
        },
        STEP_3: {
            TITLE: 'Collaboration',
            SUBTITLE:
                'Teorem’s tutoring is straightforward and hassle-free. Once you’re registered, you’ll find everything you need on our platform. You can monitor performance, change lesson times, book new lessons, cancel them, or even contact your tutor, all in just a few clicks.',
        },
        CARD_1: {
            TITLE: 'Secure payment process',
            SUBTITLE: 'Easy, flexible, scheduling adjusted to you.',
        },
        CARD_2: {
            TITLE: 'Carefully selected and verified tutors',
            SUBTITLE: 'Available on all devices, with a built-in recording function, digital whiteboard, and screen and file sharing.',
        },
        CARD_3: {
            TITLE: 'Secure platform for online learning',
            SUBTITLE: 'Secure, safe payment gateway provided by Stripe. No hidden fees, just pay for lessons. Refunds provided.',
        },
        CARD_4: {
            TITLE: 'Customer Support',
            SUBTITLE: '24/7 access to support. Our dedicated support team is available to you at a moment’s notice.',
        },
    },
    LEGAL_CENTER: {
        TITLE: 'Legal center',
        SUBTITLE: 'The Teorem Terms of Use are comprised of the following documents:',
        TAX_TITLE: 'Tax informations (Tutors)',
    },
    TAX: {
        TITLE: 'Tutor the right way',
        SUBTITLE: "Realize valuable benefits by reporting your digital income through a 'Pausalni Obrt' (Legal entity)",
        BENEFITS: {
            TITLE: 'Your Benefits:',
            CREDIT_HISTORY: 'Start building credit history ',
            MORTGAGE: 'Ability to get a mortgage on your income',
            HEALTH: 'Ability to secure health insurance',
            PENSION: 'Pension plan',
        },
        TABLE: {
            TITLE: 'Secure these benefits by contributing as little as 1.8%* of your income',
            INCOME_CLASS: 'Income Class',
            ANNUAL_REPORTED_INCOME: 'Annual Reported Income (EUR)',
            ANNUAL_TAX_OBLIGATION: 'Annual Tax Obligation',
            PERCENT_OF_INCOME: '% Of Income*',
            DISCLAIMER_1: '* Percentage tax share estimated based on figure at the top end of each income class.',
            DISCLAIMER_2:
                '** The above table reflects income contributions for the city of Zagreb. Income contributions for other counties in Croatia may be higher or lower.',
        },
        STEPPER: {
            TITLE: "Opening a 'Paušalni obrt'",
            STEP_1: {
                TITLE: 'Chose name, area of work and headquarters',
                SUBTITLE:
                    'The first step to opening a “pausalni obrt” is picking its name, business specialization, in this case providing education services, and choosing a headquarter. This can also be your home address.',
            },
            STEP_2: {
                TITLE: 'Visit webpage ',
                SUBTITLE:
                    'Opening a ‘pausalni obrt’ has never been easier thanks to the online E-Obrt portal where you can start your own obrt in less than 15 minutes. All you’ll need is your ID and mobile banking app.',
            },
            STEP_3: {
                TITLE: 'Open a bank account',
                SUBTITLE:
                    'Now that your obrt is set up, you can open a bank account in any bank you like. You will receive all payouts onto this bank account.',
            },
            STEP_4: {
                TITLE: 'Start tutoring!',
                SUBTITLE:
                    'You’re all set up! You can now focus on what you like doing most – tutoring. If you need us to change some of your settings related to payouts, you can always reach out to us.',
            },
            LAST_STEP_1: 'Opening a pausalni obrt is completely free. Please visit ',
            LAST_STEP_2: ' for more details.',
        },
    },
    BECOME_A_TUTOR: {
        HERO: {
            TITLE: 'Start tutoring online',
            SUBTITLE: 'Give lessons whenever you want, from the comfort of your home.',
        },
        TEXT_CARD_1: {
            TITLE: 'Teorem’s online tutoring makes your life easier',
            SUBTITLE: 'We’ll provide you with everything you need to teach online. We help you find students and manage lessons.',
        },
        TEXT_CARD_2: {
            TITLE: 'Overview of your earnings and fast payouts',
            SUBTITLE: 'Payouts to IBAN of your choice every Thursday. 15% commission deducted.',
        },
        REVIEW_1: {
            TEXT: "With Teorem I created a stable monthly income for myself and don't need to worry about all of my expenses anymore!",
            NAME: 'Anthony',
            OCCUPATION: 'Physics tutor',
            NUMBER_OF_LESSONS: '12 completed lessons',
        },
        REVIEW_2: {
            TEXT: 'Teorem has allowed me to move to a completely remote location and still tutor my students living in the city!',
            NAME: 'Sarah',
            OCCUPATION: 'German tutor',
            NUMBER_OF_LESSONS: '32 completed lessons',
        },
        SUBTITLE_1: 'We are here to help you grow!',
        SUBTITLE_2: 'How to become an online tutor on Teorem',
        SUBTITLE_3: 'Frequently asked questions',
        BUTTON_1: 'Get started',
        FAQ: {
            QUESTION_1: 'How do I become an online tutor?',
            ANSWER_1:
                'If you want to know how to get into tutoring, you’ve come to the right place! Just create an account, book your interview and we’ll help you get set up!',
            QUESTION_2: 'What are the requirements for tutors?',
            ANSWER_2:
                'We ideally require our tutors to commit a minimum of 6 lessons per week. You must be qualified at high school diploma level or above. You need to have a laptop (or PC) and a stable internet connection to teach online.',
            QUESTION_3: 'How long does it take before I can start teaching?',
            ANSWER_3:
                'As the registration process consists of a few simple steps, it usually only takes a day until you are verified and can begin teaching.',
            QUESTION_4: 'What does the employment relationship look like?',
            ANSWER_4:
                'You offer independent tutoring via Teorem’s platform. There is no employment relationship. You are responsible for any taxes and National Insurance contributions.',
            QUESTION_5: 'Do I need to pay any tax or report my income to the government?',
            ANSWER_5: 'You can find more information on taxes and income on <1> this page</1>',
            LINK_5: '/en/tax',
            QUESTION_6: 'What makes Teorem different from other portals?',
            ANSWER_6: 'We guarantee you a steady stream of students. We also take care of everything so that you can focus 100% on teaching.',
            QUESTION_7: 'How does Teorem make money?',
            ANSWER_7: 'Teorem charges a 15% commission to the tutor.',
        },
        CARD_1: {
            TITLE: 'Fully remote',
            SUBTITLE: 'All you need is a laptop and wifi',
        },
        CARD_2: {
            TITLE: 'Tools you need',
            SUBTITLE: 'Booking system, chat, video calls & more.',
        },
        CARD_3: {
            TITLE: 'Secure Income',
            SUBTITLE: 'We make sure you get paid.',
        },
        CARD_4: {
            TITLE: 'Set Your Price',
            SUBTITLE: 'No limitations on what you can earn.',
        },
        CARD_5: {
            TITLE: '24/7 Support',
            SUBTITLE: 'We make sure all your issues are solved.',
        },
        CARD_6: {
            TITLE: 'Improve Your Teaching',
            SUBTITLE: 'We take care of administration, you just focus on the lessons.',
        },
        STEP_1: 'Create your account and fill in the information.',
        STEP_2: 'Our Tutor Success Team contacts you to verify your identity.',
        STEP_3: 'Start tutoring!',
    },
    SCHOOL: {
        HERO: {
            TITLE: 'Zaradite do EUR 3,000 mjesečno',
            SUBTITLE: 'Držite online instrukcije kada god Vi to želite, iz udobnosti vlastitog doma',
        },
        TEXT_CARD_1: {
            TITLE: 'Teorem’s online tutoring makes your life easier',
            SUBTITLE: 'We’ll provide you with everything you need to teach online. We help you find students and manage lessons.',
        },
        TEXT_CARD_2: {
            TITLE: 'Set your own price and availability!',
            SUBTITLE: 'Use our tools to track how much you’re making and increase your client base!',
        },
        REVIEW_1: {
            TEXT: "With Teorem I created a stable monthly income for myself and don't need to worry about all of my expenses anymore!",
            NAME: 'Anthony',
            OCCUPATION: 'Physics tutor',
        },
        REVIEW_2: {
            TEXT: 'Teorem has allowed me to move to a completely remote location and still tutor my students living in the city!',
            NAME: 'Sarah',
            OCCUPATION: 'German tutor',
            NUMBER_OF_LESSONS: '32 completed lessons',
        },
        SUBTITLE_1: 'We are here to help you grow!',
        SUBTITLE_2: 'How to become an online tutor on Teorem',
        SUBTITLE_3: 'Frequently asked questions',
        BUTTON_1: 'Get started',
        FAQ: {
            QUESTION_1: 'How do I become an online tutor?',
            ANSWER_1:
                'If you want to know how to get into tutoring, you’ve come to the right place! Just create an account, book your interview and we’ll help you get set up!',
            QUESTION_2: 'What are the requirements for tutors?',
            ANSWER_2:
                'We ideally require our tutors to commit a minimum of 6 lessons per week. You must be qualified at high school diploma level or above. You need to have a laptop (or PC) and a stable internet connection to teach online.',
            QUESTION_3: 'How long does it take before I can start teaching?',
            ANSWER_3:
                'As the registration process consists of a few simple steps, it usually only takes a day until you are verified and can begin teaching.',
            QUESTION_4: 'What does the employment relationship look like?',
            ANSWER_4:
                'You offer independent tutoring via Teorem’s platform. There is no employment relationship. You are responsible for any taxes and National Insurance contributions.',
            QUESTION_5: 'Do I need to pay any tax or report my income to the government?',
            ANSWER_5: 'You can find more information on taxes and income on <1> this page</1>',
            LINK_5: '/en/tax',
            QUESTION_6: 'What makes Teorem different from other portals?',
            ANSWER_6: 'We guarantee you a steady stream of students. We also take care of everything so that you can focus 100% on teaching.',
            QUESTION_7: 'How does Teorem make money?',
            ANSWER_7: 'Teorem charges a 15% commission to the tutor.',
        },
        CARD_1: {
            TITLE: 'Fully remote',
            SUBTITLE: 'All you need is a laptop and wifi',
        },
        CARD_2: {
            TITLE: 'Tools you need',
            SUBTITLE: 'Booking system, chat, video calls & more.',
        },
        CARD_3: {
            TITLE: 'Secure Income',
            SUBTITLE: 'We make sure you get paid.',
        },
        CARD_4: {
            TITLE: 'Set Your Price',
            SUBTITLE: 'No limitations on what you can earn.',
        },
        CARD_5: {
            TITLE: '24/7 Support',
            SUBTITLE: 'We make sure all your issues are solved.',
        },
        CARD_6: {
            TITLE: 'Improve Your Teaching',
            SUBTITLE: 'We take care of administration, you just focus on the lessons.',
        },
        STEP_1: 'Create your account and fill in the information.',
        STEP_2: 'Our Tutor Success Team contacts you to verify your identity.',
        STEP_3: 'Start tutoring!',
    },
    EMAIL_CONFIRMED: {
        WELCOME: 'Welcome to Teorem!',
        DESCRIPTION: 'Your email is confirmed.',
        BUTTON: 'Log in',
    },
    STRIPE_CONNECTED: {
        WELCOME: 'Success!',
        DESCRIPTION: 'Stripe connected successfully.',
        BUTTON: 'Continue',
    },
    STRIPE_FAIL: {
        WELCOME: 'Something went wrong.',
        DESCRIPTION: 'Stripe connection failed, please try again.',
        BUTTON: 'Continue',
    },
    STRIPE_CONNECT: {
        TITLE: 'Payments',
        LINE_1: 'Address line 1',
        LINE_2: 'Address line 2',
        POST_CODE: 'Post code',
        CITY: 'City',
        IBAN: 'IBAN',
        IBAN_CONFIRM: 'IBAN confirmation',
        SAVE: 'Submit',
        CANCEL: 'Cancel',
        TERMS: '<div>By clicking on the "Submit" button, you agree to the <a href="https://stripe.com/legal/connect-account" target="_blank">Terms of Service</a> and <a href="https://stripe.com/privacy" target="_blank">Privacy Policy</a></div>',
        SUCCESS: 'Payment account connected',
    },
    SUBJECTS: {
        biology: 'Biology',
        chemistry: 'Chemistry',
        economics: 'Economics',
        english: 'English',
        croatian: 'Croatian',
        geography: 'Geography',
        german: 'German',
        history: 'History',
        it: 'IT',
        italian: 'Italian',
        maths: 'Maths',
        music: 'Music',
        physics: 'Physics',
        polish: 'Polish',
        psychology: 'Psychology',
        religion: 'Religion',
        russian: 'Russian',
        sociology: 'Sociology',
        spanish: 'Spanish',
        businessstudies: 'Business Studies',
        classicalgreek: 'Classical Greek',
        computing: 'Computing',
        designandtechnology: 'Design & Technology',
        drama: 'Drama',
        environmentalstudies: 'Environmental Studies',
        french: 'French',
        governmentandpolitics: 'Government and Politics',
        ict: 'ICT',
        latin: 'Latin',
        science: 'Science',
        art: 'Art',
        accounting: 'Accounting',
        architecture: 'Architecture',
        chemicalengineering: 'Chemical Engineering',
        law: 'Law',
        medicine: 'Medicine',
        philosophy: 'Philosophy',
        politics: 'Politics',
        statistics: 'Statistics',
    },
    ROLES: {
        student: 'Student',
        parent: 'Parent',
        child: 'Child',
        tutor: 'Tutor',
        admin: 'Admin',
    },
    LEVELS: {
        primaryschool: 'Primary School',
        highschool: 'High School',
        ib: 'IB (International Baccalaureate)',
        maturaprep: 'Matura Prep',
        university: 'University',
    },
    CONSTANTS: {
        MONTHS_SHORT: {
            JAN: 'Jan',
            FEB: 'Feb',
            MAR: 'Mar',
            APR: 'Apr',
            MAY: 'May',
            JUN: 'Jun',
            JUL: 'Jul',
            AUG: 'Aug',
            SEP: 'Sep',
            OCT: 'Oct',
            NOV: 'Nov',
            DEC: 'Dec',
        },
        MONTHS_LONG: {
            JAN: 'January',
            FEB: 'February',
            MAR: 'March',
            APR: 'April',
            MAY: 'May',
            JUN: 'June',
            JUL: 'July',
            AUG: 'August',
            SEP: 'September',
            OCT: 'October',
            NOV: 'November',
            DEC: 'December',
        },
        DAYS_SHORT: {
            MON: 'Mon',
            TUE: 'Tue',
            WED: 'Wed',
            THU: 'Thu',
            FRI: 'Fri',
            SAT: 'Sat',
            SUN: 'Sun',
        },
        DAYS_LONG: {
            MON: 'Monday',
            TUE: 'Tuesday',
            WED: 'Wednesday',
            THU: 'Thursday',
            FRI: 'Friday',
            SAT: 'Saturday',
            SUN: 'Sunday',
        },
    },
    PATHS: {
        REGISTER: '/en/register',
        FORGOT_PASSWORD: '/en/forgot-password',
        RESET_PASSWORD: '/en/reset-password',
        LOGIN: '/en/login',
        MY_BOOKINGS: '/en/my-bookings',
        SEARCH_TUTORS: '/en/search-tutors',
        SEARCH_TUTORS_TUTOR_PROFILE: '/en/profile/:tutorSlug',
        SEARCH_TUTORS_TUTOR_BOOKINGS: '/en/search-tutors/bookings/:tutorSlug',
        ONBOARDING: '/en/onboarding',
        MY_REVIEWS: '/en/my-reviews',
        COMPLETED_LESSONS: '/en/completed-lessons',
        CHAT: '/en/chat',
        DASHBOARD: '/en/dashboard',
        NOTIFICATIONS: '/en/dashboard/notifications',
        EARNINGS: '/en/earnings',
        TERMS: '/en/terms',
        REFUND: '/en/refund-and-payment-policy',
        COOKIE: '/en/cookie-policy',
        CONDUCT: '/en/user-code-of-conduct',
        PRIVACY: '/en/privacy-policy',
        TAX: '/en/tax',
        LEGAL_CENTER: '/en/legal-center',
        TUTOR_MANAGMENT: '/en/tutor-managment',
        TUTOR_MANAGMENT_TUTOR_PROFILE: '/en/tutor-managment/profile/:tutorSlug',
        LANDING_PATHS: {
            HOW_IT_WORKS: '/en/online-tutoring',
            BECOME_TUTOR: '/en/become-tutor',
            PRICING: '/en/pricing',
            TERMS: '/en/terms',
            REFUND: '/en/refund-and-payment-policy',
            COOKIE: '/en/cookie-policy',
            CONDUCT: '/en/user-code-of-conduct',
            PRIVACY: '/en/privacy-policy',
            TAX: '/en/tax',
            LEGAL_CENTER: '/en/legal-center',
            SCHOOL: '/hr/skole',
            BLOG: 'https://start.teorem.co/hr',
        },
        PROFILE_PATHS: {
            MY_PROFILE: '/en/my-profile',
            MY_PROFILE_INFO: '/en/my-profile/info',
            MY_PROFILE_INFO_PERSONAL: '/en/my-profile/info/personal',
            MY_PROFILE_INFO_AVAILABILITY: '/en/my-profile/info/availability',
            MY_PROFILE_INFO_TEACHINGS: '/en/my-profile/info/teachings',
            MY_PROFILE_INFO_ADDITIONAL: '/en/my-profile/info/additional',
            MY_PROFILE_ACCOUNT: '/en/my-profile/account',
            MY_PROFILE_CHILD_INFO: '/en/my-profile/childs',
        },
        EMAIL_CONFIRMED: '/en/account-activated',
        STRIPE_CONNECTED: '/en/stripe-connected',
        STRIPE_FAIL: '/en/stripe-failed',
        RESEND_ACTIVATION_TOKEN: '/en/reset-token',
    },
    SEO: {
        FALLBACK: {
            TITLE: 'Teorem',
            META: 'Teorem provides 1-to-1 private tutoring, homework help, test prep, and virtual learning for all students. - The perfect tutor for everyone - A tailor-made learning plan - Virtual classroom - Start today',
        },
        HOW_IT_WORKS: {
            TITLE: 'Tailored 1-to-1 online tutoring & homework help - Teorem',
            META: 'Teorem provides 1-to-1 private tutoring, homework help, test prep, and virtual learning for all students. - The perfect tutor for everyone - A tailor-made learning plan - Virtual classroom - Start today',
        },
        PRICING: {
            TITLE: 'High quality online tutoring prices | Teorem',
            META: 'Prices for 1-to-1 50-minute lessons start at $12.50, with the average price of $25.40, depending on the tutor and learning plan. Get more pricing info by contacting a tutor.',
        },
        BECOME_TUTOR: {
            TITLE: 'Become an online tutor | Teorem',
            META: 'How to become an online tutor with Teorem - 1. Register to become a tutor - 2. Complete your subject quiz - 3. Prove your identity - 4. Start tutoring',
        },
        TERMS: {
            TITLE: 'Terms of Service | Teorem',
            META: "Welcome to Teorem! These terms of service outline the rules and regulations for the use of Teorem's website.",
        },
        REFUND: {
            TITLE: 'Refund and Payment Policy | Teorem',
            META: 'Your purchase of and payment for lessons/group classes shall be regulated by this Refund and Payment Policy.',
        },
        CONDUCT: {
            TITLE: 'User Code of Conduct | Teorem',
            META: 'In using the Teorem Marketplace, you hereby acknowledge your understanding of this User Code of Conduct and agree to it.',
        },
        PRIVACY: {
            TITLE: 'Privacy Policy | Teorem',
            META: 'One of our main priorities at Teorem is the privacy of our visitors. This Privacy Policy  contains types of information that is collected and recorded by Teorem and how we use it.',
        },
        TAX: {
            TITLE: 'Tax | Teorem',
            META: 'Jedan od naših glavnih prioriteta u Teoremu je privatnost naših posjetitelja. Ova Politika privatnosti sadrži tipove podataka koji su skupljani i spremani od strane Teorema, te na koji ih način koristimo.',
        },
        LEGAL_CENTER: {
            TITLE: 'Legal center | Teorem',
            META: 'Jedan od naših glavnih prioriteta u Teoremu je privatnost naših posjetitelja. Ova Politika privatnosti sadrži tipove podataka koji su skupljani i spremani od strane Teorema, te na koji ih način koristimo.',
        },
    },
    LANGUAGE_MODAL: {
        WELCOME: 'Welcome to Teorem’s online tutoring platform!',
        ABOUT: 'Please select your country.',
    },
    TOKEN_EXPIRED: {
        RESEND_ACTIVATION_MESSAGE: 'RESEND_ACTIVATION_MESSAGE',
        WELCOME: 'WELCOME',
        DESCRIPTION: 'DESCRIPTION',
    },
    COUNTRY: {
        PL: 'Poland',
        US: 'United States',
        HR: 'Croatia',
    },
    LANGUAGE: {
        EN: 'English',
        PL: 'Polish',
        HR: 'Croatian',
    },
};
