import i18n from 'i18next';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { matchPath } from 'react-router-dom';

interface ISeoProps {
    routes: { lang: string }[];
}

export default function SEO({ routes }: Readonly<ISeoProps>) {
    const { t } = useTranslation();

    const routesWithData: string[] = ['HOW_IT_WORKS', 'PRICING', 'BECOME_TUTOR', 'TERMS', 'PRIVACY', 'SCHOOL'];

    let currentRoute: any = routes.find((route: any) => matchPath(location.pathname, route) && i18n.language == route.lang);

    if (typeof currentRoute === 'undefined' || !routesWithData.includes(currentRoute.key))
        currentRoute = {
            key: 'FALLBACK',
        };

    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{t(`SEO.${currentRoute.key}.TITLE`)}</title>
            <meta lang={currentRoute.lang} name="description" content={t(`SEO.${currentRoute.key}.META`)} />
        </Helmet>
    );
}
