import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import calendar from '../../../../assets/images/calendar.png';
import earnings from '../../../../assets/images/earnings.png';
import icon1 from '../../../../assets/images/TEOREM_IKONE-01.jpg';
import icon3 from '../../../../assets/images/TEOREM_IKONE-03.jpg';
import icon4 from '../../../../assets/images/TEOREM_IKONE-04.jpg';
import icon5 from '../../../../assets/images/TEOREM_IKONE-05.jpg';
import icon6 from '../../../../assets/images/TEOREM_IKONE-06.jpg';
import icon8 from '../../../../assets/images/TEOREM_IKONE-08.jpg';
import { Canvas3D } from '../../../components/Canvas3D';
import { getHostName } from '../../../utils/utilFunctions';
import { ButtonPrimaryGradient } from '../components/ButtonPrimaryGradient';
import FAQGroup from '../components/FAQGroup';
import LandingWrapper from '../components/LandingWrapper';
import ReviewCard from '../components/ReviewCard';
import TextCard from '../components/TextCard';
import IFAQItem from '../interfaces/IFAQItem';
import profileIllustration1 from './../../../../assets/images/instruktor-ante.jpg';
import profileIllustration2 from './../../../../assets/images/instruktorica-dunja.jpg';
import { setRegistrationModalOpen } from '../../../store/slices/auth/authSlice';
import { useAppDispatch } from '../../../store/hooks';

const BecomeTutor = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const cardText1 = {
        text: t('BECOME_A_TUTOR.REVIEW_1.TEXT'),
        name: t('BECOME_A_TUTOR.REVIEW_1.NAME'),
        role: t('BECOME_A_TUTOR.REVIEW_1.OCCUPATION'),
        numberOfLessons: t('BECOME_A_TUTOR.REVIEW_1.NUMBER_OF_LESSONS'),
    };
    const cardText2 = {
        text: t('BECOME_A_TUTOR.REVIEW_2.TEXT'),
        name: t('BECOME_A_TUTOR.REVIEW_2.NAME'),
        role: t('BECOME_A_TUTOR.REVIEW_2.OCCUPATION'),
        numberOfLessons: t('BECOME_A_TUTOR.REVIEW_2.NUMBER_OF_LESSONS'),
    };

    const FAQContent: IFAQItem[] = [
        {
            id: 'faq-2',
            question: t('BECOME_A_TUTOR.FAQ.QUESTION_2'),
            answer: t('BECOME_A_TUTOR.FAQ.ANSWER_2'),
        },
        {
            id: 'faq-3',
            question: t('BECOME_A_TUTOR.FAQ.QUESTION_3'),
            answer: t('BECOME_A_TUTOR.FAQ.ANSWER_3'),
        },
        {
            id: 'faq-4',
            question: t('BECOME_A_TUTOR.FAQ.QUESTION_4'),
            answer: t('BECOME_A_TUTOR.FAQ.ANSWER_4'),
        },
        {
            id: 'faq-5',
            question: t('BECOME_A_TUTOR.FAQ.QUESTION_5'),
            answer: t('BECOME_A_TUTOR.FAQ.ANSWER_5'),
            link: t('BECOME_A_TUTOR.FAQ.LINK_5'),
            key: 'BECOME_A_TUTOR.FAQ.ANSWER_5',
        },
        {
            id: 'faq-7',
            question: t('BECOME_A_TUTOR.FAQ.QUESTION_7'),
            answer: t('BECOME_A_TUTOR.FAQ.ANSWER_7'),
        },
    ];

    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const isMobile = window.innerWidth < 800;
    return (
        <>
            {!isMobile && <Canvas3D />}
            <LandingWrapper>
                {/* Content */}

                <div className="landing__hero">
                    <div
                        dangerouslySetInnerHTML={{ __html: t('BECOME_A_TUTOR.HERO.TITLE') }}
                        className="landing__hero__title landing--fluid--title w--550--max"
                    ></div>
                    <div className="landing__hero__subtitle landing--fluid--sm type--color--secondary w--400--max">
                        {t('BECOME_A_TUTOR.HERO.SUBTITLE')}
                    </div>
                </div>
                <div className="landing__section w--100">
                    <img src={calendar} alt="calendar" className="landing__img" />
                </div>
                <TextCard className="mt-30" title={t('BECOME_A_TUTOR.TEXT_CARD_1.TITLE')} desc={t('BECOME_A_TUTOR.TEXT_CARD_1.SUBTITLE')} />
                <div className="mt-30">
                    <div className="row row--adaptive">
                        <div className="col col-6 col-md-6 col-lg-4">
                            <div className="landing__grid__card">
                                <img className="image-icon" src={icon3} alt="grid" />
                                <div className="type--lg type--wgt--bold mb-4 landing-title-fs">{t('SCHOOL.CARD_1.TITLE')}</div>
                                <div className="type--color--tertiary type--md landing-subtitle-fs">{t('SCHOOL.CARD_1.SUBTITLE')}</div>
                            </div>
                        </div>

                        <div className="col col-6 col-md-6 col-lg-4">
                            <div className="landing__grid__card">
                                <img className="image-icon" src={icon1} alt="grid" />
                                <div className="type--lg type--wgt--bold mb-4 landing-title-fs">{t('SCHOOL.CARD_2.TITLE')}</div>
                                <div className="type--color--tertiary type--md landing-subtitle-fs">{t('SCHOOL.CARD_2.SUBTITLE')}</div>
                            </div>
                        </div>

                        <div className="col col-6 col-md-6 col-lg-4">
                            <div className="landing__grid__card">
                                <img className="image-icon" src={icon5} alt="grid" />
                                <div className="type--lg type--wgt--bold mb-4 landing-title-fs">{t('SCHOOL.CARD_3.TITLE')}</div>
                                <div className="type--color--tertiary type--md landing-subtitle-fs">{t('SCHOOL.CARD_3.SUBTITLE')}</div>
                            </div>
                        </div>

                        <div className="col col-6 col-md-6 col-lg-4">
                            <div className="landing__grid__card">
                                <img className="image-icon" src={icon6} alt="grid" />
                                <div className="type--lg type--wgt--bold mb-4 landing-title-fs">{t('SCHOOL.CARD_4.TITLE')}</div>
                                <div className="type--color--tertiary type--md landing-subtitle-fs">{t('SCHOOL.CARD_4.SUBTITLE')}</div>
                            </div>
                        </div>

                        <div className="col col-6 col-md-6 col-lg-4">
                            <div className="landing__grid__card">
                                <img className="image-icon" src={icon8} alt="grid" />
                                <div
                                    dangerouslySetInnerHTML={{ __html: t('SCHOOL.CARD_5.TITLE') }}
                                    className="type--lg type--wgt--bold mb-4 landing-title-fs"
                                ></div>
                                <div className="type--color--tertiary type--md landing-subtitle-fs">{t('SCHOOL.CARD_5.SUBTITLE')}</div>
                            </div>
                        </div>

                        <div className="col col-6 col-md-6 col-lg-4">
                            <div className="landing__grid__card">
                                <img className="image-icon" src={icon4} alt="grid" />
                                <div className="type--lg type--wgt--bold mb-4 landing-title-fs">{t('SCHOOL.CARD_6.TITLE')}</div>
                                <div className="type--color--tertiary type--md landing-subtitle-fs">{t('SCHOOL.CARD_6.SUBTITLE')}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <ReviewCard className="mt-30" data={cardText1} img={profileIllustration1} />

                <TextCard className="mt-30" title={t('BECOME_A_TUTOR.TEXT_CARD_2.TITLE')} desc={t('BECOME_A_TUTOR.TEXT_CARD_2.SUBTITLE')} />
                <div className="landing__section mt-30 w--100">
                    <img src={earnings} alt="calendar" className="landing__img" />
                </div>
                <p className="landing__title">{t('BECOME_A_TUTOR.SUBTITLE_1')}</p>
                <ReviewCard className="mt-30" data={cardText2} img={profileIllustration2} />
                <p dangerouslySetInnerHTML={{ __html: t('BECOME_A_TUTOR.SUBTITLE_2') }} className="landing__title"></p>
                <div className="mt-20">
                    <div className="flex flex--jc--start">
                        <div className="landing__steps__item">1</div>
                        <div className="mt-3 ml-6 type--color--secondary become-a-tutor-step">{t('BECOME_A_TUTOR.STEP_1')}</div>
                    </div>
                    <div className="flex flex--jc--start">
                        <div className="landing__steps__item">2</div>
                        <div className="mt-3 ml-6 type--color--secondary become-a-tutor-step">{t('BECOME_A_TUTOR.STEP_2')}</div>
                    </div>
                    <div className="flex flex--jc--start">
                        <div className="landing__steps__item last">3</div>
                        <div className="mt-3 ml-6 type--color--secondary become-a-tutor-step">{t('BECOME_A_TUTOR.STEP_3')}</div>
                    </div>
                </div>
                <div className="landing__section mt-30">
                    <a id="zapocni-danas-become-a-tutor-1" className="type--color--white" onClick={() => dispatch(setRegistrationModalOpen(true))}>
                        <ButtonPrimaryGradient className={'btn btn--l type--md'}>{t('BECOME_A_TUTOR.BUTTON_1')}</ButtonPrimaryGradient>
                    </a>
                </div>
                <p className="landing__title">{t('BECOME_A_TUTOR.SUBTITLE_3')}</p>
                <FAQGroup data={FAQContent} />
            </LandingWrapper>
        </>
    );
};

export default BecomeTutor;
