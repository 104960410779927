import { useEffect } from 'react';

import LandingWrapper from '../components/LandingWrapper';

const Privacy_HR = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <LandingWrapper>
            <div className="privacy">
                <h1>TEOREM POLITIKA PRIVATNOSTI</h1>
                <p>
                    Teorem se zalaže za zaštitu Vaših osobnih podataka. Pokušali smo napisati ovu Politiku privatnosti na što jasnijem i
                    jednostavnijem jeziku. Isto tako, pokušali smo vam pružiti naše obveze privatnosti na transparentan i lako dostupan način. Ipak,
                    nadamo se da ćete dobiti sve potrebne pojedinosti kako bismo bili sigurni da su vaši osobni podaci kod nas sigurni.
                </p>
                <p>
                    Prije nego što nam date privolu za obradu Vaših osobnih podataka ili nam dostavite svoje osobne podatke u bilo kojem obrascu na
                    našoj Web-stranici, molimo Vas da pažljivo pročitate ovu Politiku privatnosti, kako bismo bili sigurni da u potpunosti razumijete
                    uvjete pod s kojim bismo surađivali.
                </p>
                <p>
                    Ova Pravila o privatnosti primjenjuju se na vaše korištenje Usluga Teorema, kako je definirano u našim
                    <a href={'/hr/uvjeti-koristenja'}> Uvjetima pružanja usluge</a>. Ova Pravila o privatnosti objašnjavaju kada, gdje i zašto
                    obrađujemo osobne podatke ljudi koji koriste Teorem Marketplace, kako ih koristimo, uvjete pod kojima ih možemo otkriti drugima,
                    vaša prava u odnosu na vaše osobne podatke, kao i kako ga čuvamo sigurnim.
                </p>
                <h2>OPĆENITO</h2>
                <p>
                    Pristup Teorem Marketplaceu (uključujući Web-stranicu, povezani softver i aplikaciju za mobilni uređaj Teorem) dostupan je sa ili
                    bez potrebe za registracijom računa. Našoj Web-stranici možete pristupiti bez registracije. Da biste mogli koristiti aplikaciju za
                    mobilne uređaje Teorem, prvo morate registrirati račun. Stoga je ograničeno korištenje Usluga uvijek moguće bez pružanja vaših
                    osobnih podataka (na primjer, pregledati Web-stranicu, provjeriti kako Teorem radi, pregledati popis Instruktora, pročitati Teorem
                    blog, pregledati raznolikost usluga itd.). Međutim, kad god pristupite Web-stranici, prikupljamo podatke iz dnevnika i stavljamo
                    kolačiće na vaš uređaj pomoću kojih pratimo vašu aktivnost na Web-stranici. Uvijek možete isključiti kolačiće osim onih bez kojih
                    Web-stranica ne može raditi.
                </p>
                <p>
                    Kada ste registrirani korisnik, Teorem može prikupljati od vas i obrađivati identifikatore kolačića i podatke koje ste nam posebno
                    dali prilikom registracije, uključujući ime, prezime, e-poštu, telefonski broj, Skype ID ili ID društvene mreže (ako su
                    dodijeljeni putem Facebook ili Google+ ili ako pristupate Google kalendaru), vremensku zonu, jezik sučelja, IP adresu i vrstu
                    uređaja.
                </p>
                <p>
                    Konkretno, ako ste Instruktor, također možemo prikupljati i obrađivati, osim gore navedenih podataka, i druge podatke koje ste
                    dali, uključujući poštanski broj, spol, godinu rođenja, grad prebivališta i obrazovno iskustvo.
                </p>
                <p>
                    Registrirani Korisnici mogu dodatno dati profilne slike kao i odabranu valutu za izvođenje radnji u skladu s našom
                    <a href={'/hr/politika-povrata-i-placanja'}> Politikom povrata novca i plaćanja</a>.
                </p>
                <p>
                    Više o konkretnim slučajevima prikupljanja podataka i svrhama obrade takvih podataka možete saznati u posebnom odjeljku ovih
                    Pravila privatnosti.
                </p>
                <p>
                    S vremena na vrijeme možemo promijeniti ovu Politiku privatnosti, stoga provjerite ovu stranicu kako biste bili sigurni da ste
                    zadovoljni promjenama. Objavit ćemo te promjene na ovoj stranici i ažurirati datum izmjene Pravila o privatnosti na vrhu ove
                    stranice. Dodatne obavijesti o svim izmjenama i dopunama bit će poslane na vašu e-poštu.
                </p>
                <p>
                    Također možemo povremeno eksperimentirati s različitim značajkama Teorem Marketplacea koje mogu utjecati na opseg, svrhe ili druge
                    detalje obrade osobnih podataka. U takvim ćemo slučajevima dati prethodnu obavijest i, ako je potrebno, dobiti vaš pristanak.
                </p>
                <p>
                    Sva pitanja u vezi s ovom Politikom i našim uslugama treba poslati na <a href={'mailto:legal@teorem.co'}> legal@teorem.co</a>.
                </p>
                <ul>
                    <h3>1. Što su osobni podaci i obrada osobnih podataka? Glavne definicije</h3>
                </ul>
                <p>
                    <b>Osobni podaci</b> su sve informacije koje se odnose na vas koje same ili u kombinaciji s drugim informacijama omogućuju osobi
                    koja prikuplja i obrađuje takve informacije da vas identificira kao pojedinca.
                </p>
                <p>
                    <b>Obrada osobnih podataka</b> znači svaku radnju s vašim osobnim podacima, bilo automatiziranim ili neautomatskim sredstvima, kao
                    što je prikupljanje, snimanje, organiziranje, strukturiranje, pohrana, prilagodba ili izmjena, dohvaćanje, konzultacija, uporaba,
                    otkrivanje prijenosom, širenje ili drugačija izrada dostupnost, poravnanje ili kombinacija, ograničenje, brisanje ili uništenje.
                </p>
                <p>
                    <b>Ispitanik</b> je svaka fizička osoba koja je identificirana ili se može identificirati čije osobne podatke obrađuje voditelj
                    obrade odgovoran za obradu; znači vas kao korisnika Teorema.
                </p>
                <p>
                    <b>Privola ispitanika/korisnika </b> je svaka slobodno dana, konkretna, informirana i nedvosmislena oznaka želja
                    nositelja/korisnika kojom on ili ona, izjavom ili jasnom potvrdnom radnjom, označava suglasnost s obradom osobnih podataka koji se
                    odnose na njemu ili njoj.
                </p>
                <ul>
                    <h3>2. Teoremove zakonske osnove za obradu</h3>
                </ul>
                <p>Teorem obrađuje Vaše osobne podatke samo kada za to imamo zakonske osnove. Konkretno, Teorem obrađuje Vaše osobne podatke ako:</p>
                <ul>
                    <li>
                        se obrada temelji na privoli koju ste dali. Kao takvi, naši bilteni, prikupljanje, analiza i objava povratnih informacija i
                        recenzija korisnika, analiza video lekcija, vaše sudjelovanje u našim događajima i webinarima te postavljanje različitih vrsta
                        kolačića i objavljivanje pobjednika nagradne igre temelji se na vašem pristanku;
                    </li>
                    <li>
                        je obrada neophodna kako bismo vam pružili naše usluge u skladu s našim{' '}
                        <a href={'/hr/uvjeti-koristenja'}> Uvjetima pružanja usluge </a>
                        koji čine ugovor između vas i nas u vezi s informacijskim uslugama Teorema;
                    </li>
                    <li>
                        je obrada nužna u svrhu naših legitimnih interesa. Ti legitimni interesi mogu uključivati, ali nisu ograničeni na:
                        <ul>
                            <li>usklađenost s hrvatskim financijskim i računovodstvenim zakonodavstvom;</li>
                            <li>procjenu poslovne učinkovitosti Teorema analizom prometnih i financijskih pokazatelja Teorem Marketplacea;</li>
                            <li>
                                promicanje Teorema putem mreža društvenih medija, medijskih kuća, sudaca i suradnika, kao što su blogeri ili
                                influenceri; ovo se posebno odnosi na pravo Teorema da koristi mentorovo ime, profilnu sliku i druge detalje za
                                marketinške, reklamne ili promotivne svrhe u skladu s<a href={'/hr/uvjeti-koristenja'}> Uvjetima usluge</a>;
                            </li>
                            <li>kontrolu korisničkih sesija i sprječavanje prijevara, osiguranje odgovarajuće zaštite osobnih podataka;</li>
                            <li>provjeru Instruktora i izradu visokokvalitetnih programa učenja s Instruktorima;</li>
                            <li>
                                poboljšanje iskustva učenja za nastavnike i učenike pružanjem personaliziranih preporuka koje pokreće AI, poboljšanje
                                planiranja lekcija i interaktivnih alata za učenje.
                            </li>
                        </ul>
                    </li>
                    <li>gdje je potrebno, usklađenost s primjenjivim zakonodavstvom (npr. obrada zahtjeva nositelja podataka);</li>
                    <li>
                        u iznimnim slučajevima radi zaštite svojih životnih interesa ili vitalnih interesa druge fizičke osobe; ili za obavljanje
                        zadaće koja se provodi u javnom interesu ili u izvršavanju službenih ovlasti koje su nam povjerene.
                    </li>
                </ul>
                <p>
                    U svim slučajevima, obavijestit ćemo vas o primijenjenoj pravnoj osnovi za obradu i drugim relevantnim detaljima prije nego što
                    započnemo obradu vaših osobnih podataka na određenoj pravnoj osnovi, osim ako to nismo u mogućnosti učiniti u skladu s našim
                    obvezama prema bilo kojem primjenjivom zakonodavstvu.
                </p>
                <ul>
                    <h3>3. Podaci koje prikupljamo: Opseg</h3>
                </ul>
                <p>Teorem od vas prikuplja samo sljedeće podatke i samo u dolje navedene svrhe: </p>
                <ul>
                    <h4>1) Račun za Studente</h4>
                </ul>
                <p>
                    Teorem prikuplja i obrađuje osobne podatke koje ste dostavili prilikom registracije i daljnjeg uređivanja postavki na svom Računu.
                    Za potrebe registracije, morate poslati svoje vjerodajnice za prijavu (korisničko ime i lozinku), ime, prezime, državu i e-mail.
                    Također možete urediti svoj račun bilo kada s dodatnim informacijama, kao i sinkronizirati svoje Google i Teorem kalendare. Takve
                    vam dodatne informacije mogu pomoći da pronađete odgovarajući termin s Instruktorom ili da imate veću vidljivost svog rasporeda.
                    Istodobno, niste dužni dati dodatne podatke koji nisu izričito potrebni za registraciju; to je Vaš izbor.
                </p>
                <ul>
                    <h4>2) Račun za Instruktore</h4>
                </ul>
                <p>
                    Teorem prikuplja i obrađuje osobne podatke koje dostavite prilikom registracije i daljnjeg uređivanja svog profila. Za potrebe
                    registracije, morate poslati svoje vjerodajnice za prijavu (korisničko ime i lozinku), ime, prezime, državu i e-mail. Također
                    možete uređivati svoj profil bilo kada s dodatnim informacijama, kao i sinkronizirati Google i Teorem kalendare. Takve vam dodatne
                    informacije mogu pomoći da pronađete odgovarajući termin sa Studentom ili da imate veću vidljivost svog rasporeda. Istodobno, od
                    vas se ne zahtijeva striktno davanje dodatnih informacija koje nisu izričito potrebne za registraciju. Dakle, na vama je da
                    odlučite hoćete li u svoj profil uključiti dodatne podatke, kao što su poštanski broj, znanje jezika, obrazovanje i radno
                    iskustvo.
                </p>
                <p>
                    Neki osobni podaci, kao što su imena korisnika i lokacija koji su navedeni u vašim korisničkim profilima ili izvedeni iz IP
                    adresa, mogu biti javno vidljivi drugim korisnicima na Uslugama ili na webu. Možemo implementirati značajku koja vam omogućuje da
                    odaberete koje će informacije biti javne promjenom vaše postavke privatnosti u "Uredi moj profil" unutar usluge.
                </p>
                <ul>
                    <h4>3) Pretplata na Teorem blog</h4>
                </ul>
                <p>
                    Možete se odlučiti pretplatiti na Teorem Blog kako biste primali biltene i ažuriranja s Teorem Bloga. Za to tražimo da nam date
                    svoju adresu e-pošte.
                </p>
                <ul>
                    <h4>4) Objavljivanje informacija </h4>
                </ul>
                <p>
                    Obrađujemo osobne podatke koji su vam slobodno dostavljeni na Teoremu. To može biti anketa i mrežni obrazac koji ispunite na
                    Teoremu, pitanje ili komentar ostavljen u odjeljku Pitanja i odgovori na našoj Web-stranici ili povratna informacija koju nam
                    dostavite.
                </p>
                <p>
                    Teorem također može obrađivati informacije koje ste dali (uključujući sadržaj i poruke) koje objavite na/kroz Teorem Marketplace,
                    uključujući osobne podatke. Time potvrđujete da će sve informacije koje podijelite putem Usluga biti dostupne Teoremu i drugim
                    Korisnicima Usluga (Studentima/Instruktorima). Također potvrđujete da se dijelovi vašeg sadržaja ne mogu izbrisati iz Usluge jer
                    mogu biti povezani s lekcijama koje ste vi izradili i koristili drugi Korisnici (Studenti/Instruktori).
                </p>
                <p>
                    S naše strane i osim ako ne odustanete, mi zadržavamo ove podatke koliko je potrebno za rješavanje sporova, pružanje korisničke
                    podrške i rješavanje problema kao što je dopušteno zakonom. Zadržavamo pravo ukinuti račune posjetitelja koji koriste ove usluge
                    na način koji nije u skladu s Uvjetima usluge.
                </p>
                <ul>
                    <h4>5) Korištenje usluge, kolačići i druge slične tehnologije</h4>
                </ul>
                <p>Kada koristite naše Usluge, pratimo i analiziramo web promet i pratimo vaše ponašanje.</p>
                <p>
                    Za dodatne pojedinosti o našem korištenju kolačića i sličnih tehnologija pogledajte
                    <a href={'/hr/politika-kolacica'}> Politiku kolačića</a>.
                </p>
                <ul>
                    <h4>6) Podaci o naplati</h4>
                </ul>
                <p>Obrađujemo detalje transakcija koje provodite putem Teorem Marketplacea (kao ID, datum i vrijeme transakcije).</p>
                <ul>
                    <h4>7) Osjetljivi podaci</h4>
                </ul>
                <p>Osim ako se izričito ne slažete, Teorem ne obrađuje vaše osjetljive podatke.</p>
                <ul>
                    <h4>8) Stranice na društvenim mrežama</h4>
                </ul>
                <p>
                    Održavamo službene stranice Teorema na društvenim mrežama, kao što su Facebook, TikTok, Instagram i LinkedIn. Kada komunicirate sa
                    službenim stranicama Teorema na društvenim mrežama, kao što je pretplata na stranice ili ostavljanje komentara ili reakcija (npr.
                    'lajkajte' naše objave), možemo analizirati te podatke kako bismo izmjerili svoju učinkovitost i proveli analizu našeg marketinga
                    na društvenim mrežama kampanje u našim legitimnim interesima.
                </p>
                <ul>
                    <h4>9) Zajednica nastavnika (samo za Instruktore) </h4>
                </ul>
                <p>
                    Održavamo Zajednicu nastavnika kojoj se može pristupiti putem naše Web-stranice putem jedinstvene prijave (SSO). Kako bismo
                    ispunili svrhe navedene u pododjeljku (9) odjeljka (4) ovih Pravila o privatnosti, također ćemo prikupljati i obrađivati podatke o
                    vašoj interakciji i podatke o korištenju unutar zajednice Instruktora.
                </p>
                <ul>
                    <h4>10) Akademija za Instruktore (samo za Instruktore)</h4>
                </ul>
                <p>
                    Ako se registrirate kao Instruktor, moći ćete pristupiti našoj Akademiji za Instruktore i pohađati razne tečajeve kako biste
                    stekli nove vještine, poboljšali svoju metodologiju, poboljšali svoj rang i izvukli najviše iz Teorem Marketplacea. Prikupit ćemo
                    i obraditi vaše podatke o aktivnostima kao i vaše komentare, recenzije i rezultate anketa kako bismo optimizirali i poboljšali
                    naše tečajeve i učinili ih privlačnijim i učinkovitijim.
                </p>
                <ul>
                    <h4>11) Podaci potrebni za podnošenje porezne dokumentacije</h4>
                </ul>
                <p>
                    Kako bismo bili u skladu s važećim poreznim i računovodstvenim zakonima, od nas se može tražiti da dostavimo različitu poreznu
                    dokumentaciju koja sadrži vaše osobne podatke. Za podnošenje porezne dokumentacije možemo obrađivati vaše osobne podatke
                    uključujući, ali ne ograničavajući se na vaše puno ime, državu državljanstva, adresu stalnog prebivališta, poštansku adresu, datum
                    rođenja, osobni identifikacijski broj ili strani porezni identifikacijski broj.
                </p>
                <ul>
                    <h4>12) Podaci potrebni za marketing i promociju</h4>
                </ul>
                <p>
                    Možemo prikupiti poštansku adresu Instruktora kako bismo promovirali usluge podučavanja koje pružate putem Teorem Marketplacea i
                    pomogli vam da dođete do više potencijalnih Studenata koji traže usluge podučavanja unutar određenog geografskog područja.
                </p>
                <ul>
                    <h4>13) Analiza govorne aktivnosti</h4>
                </ul>
                <p>
                    Kada dajete/uzimate lekcije na Teorem Marketplaceu, možemo analizirati vašu aktivnost mikrofona i mjeriti glasnoću zvuka. Činimo
                    to kako bismo vam pružili pregled vaše govorne aktivnosti i kako biste znali koliko ste bili aktivni tijekom lekcije. Budite
                    sigurni da ne snimamo vaš glas, riječi ili sadržaj vaših razgovora.
                </p>
                <ul>
                    <h4>14) Procjena</h4>
                </ul>
                <p>
                    Vaše osobne podatke obrađujemo kako bismo procijenili vašu razinu znanja u određenom predmetu. U tu svrhu možemo dodatno obraditi
                    vaše ime, adresu e-pošte i rezultate vaših procjena. Takva se procjena može provesti prije ili nakon što se registrirate na Teorem
                    Marketplace.
                </p>
                <ul>
                    <h4>15) Video lekcije, prodajni pozivi, događaji i webinari </h4>
                </ul>
                <p>
                    Možemo obraditi vašu sličnost i interakcije u videozapisima kada vodite/pohađate grupnu nastavu na Teorem Marketplaceu,
                    sudjelujete u prodajnim pozivima ili prisustvujete našim webinarima i događajima. Nećemo vas snimati bez prethodne obavijesti i
                    traženja vašeg pristanka ako je potrebno.
                </p>
                <ul>
                    <h4>16) Informacije koje obrađuju alati koje pokreće AI</h4>
                </ul>
                <p>
                    Integrirali smo određene alate trećih strana koje pokreće AI kako bismo poboljšali vaše iskustvo kada koristite Teorem
                    Marketplace. Na primjer, možemo upotrijebiti vaše tekstualne upute i, ako date privolu, prijepise vaših lekcija kako bismo pružili
                    personalizirane preporuke za učenje, generirali planove lekcija, ponudili objašnjenja gramatike, obogatili kartice ili poruke s
                    vokabularom, predložili vježbe i domaću zadaću, procijenili razine znanja jezika i omogućili funkcionalnost chatbota za vježbu
                    vokabulara i upite o gramatici.
                </p>
                <ul>
                    <h3>4. Podaci koje prikupljamo: Svrhe</h3>
                </ul>
                <p>
                    Osobne podatke koje ste nam dobrovoljno dali možemo obrađivati u svrhe definirane u nastavku. Iz naše perspektive, obrađujemo vaše
                    osobne podatke kako bismo personalizirali korištenje Teorema u najvećoj mogućoj mjeri i kako bismo vam pružili visokokvalitetne
                    usluge prema <a href={'/hr/uvjeti-koristenja'}> Uvjetima pružanja usluge</a>.
                </p>
                <ul>
                    <h4>1) Pružanje usluga</h4>
                </ul>
                <p>
                    Prije svega, obrađujemo vaše osobne podatke kako bismo autorizirali pristup našim Uslugama i kako bismo ispunili svoje obveze
                    prema <a href={'/hr/uvjeti-koristenja'}> Uvjetima pružanja usluga</a>. Konkretno, koristimo vaše osobne podatke nakon registracije
                    kako bismo pomogli drugima (bilo Instruktoru ili Studentu) da pronađu vaš račun ili profil, rasporede, daju i/ili uzimaju lekcije
                    i prate vaš napredak u učenju.
                </p>
                <p>
                    Kako biste mogli koristiti Usluge, morat ćete dati podatke o plaćanju (npr. podatke o kreditnoj kartici) i podatke o naplati putem
                    naših partnera – trećih pružatelja usluga plaćanja, koji obrađuju vaše osobne podatke šifrirano i sigurno. Više informacija
                    potražite u našim <a href={'/hr/politika-povrata-i-placanja'}> Pravilima povrata novca i plaćanja</a>.
                </p>
                <p>Također vas možemo zamoliti da pristupite testu procjene kako bismo utvrdili vašu razinu znanja iz predmeta.</p>
                <p>
                    Imajte na umu da bez informacija koje ste nam dali prema našim <a href={'/hr/uvjeti-koristenja'}> Uvjetima pružanja usluge</a>,
                    nećemo vam moći pružiti funkcionalnost Teorem Marketplacea, povezati vas s drugim korisnicima, omogućiti vam da s njima
                    komunicirate i pohađate/dajete lekcije.
                </p>
                <ul>
                    <h4>2) Usluge plaćanja</h4>
                </ul>
                <p>
                    Koristimo pružatelje plaćanja treće strane za naplatu putem online plaćanja računa umjesto izravne obrade podataka vaše
                    kreditne/debitne kartice. Vaš plaćeni saldo za jednu ili više lekcija siguran je na vašem računu jer koristimo integrirani iframe
                    za naplatu od naših partnera-pružatelja plaćanja (Braintree, Stripe, PayPal), a učitelji dobivaju plaćanje za svaku potvrđenu
                    lekciju na siguran način putem trećih pružatelja usluga plaćanja (Braintree, Stripe, PayPal, TransferWise). Sukladno tome, sve su
                    transakcije osigurane budući da naši partneri-pružatelji usluga plaćanja koriste zaštitu SSL enkripcijom. Sve podatke o plaćanju
                    šifrirano i sigurno pohranjuje Braintree, PayPal usluga. Vaše podatke o plaćanju ne pohranjujemo na našim poslužiteljima.
                </p>
                <p>
                    Također možemo koristiti podatke o transakciji vaše platne kartice koje dijele pružatelji usluga trećih strana kako bismo
                    potvrdili da se dogodila određena transakcija i da ispunjavate uvjete za promotivnu ponudu; na primjer, datum i iznos vaše kupnje
                    i posljednje 4 znamenke broja vaše kartice kako bismo mogli potvrditi vašu kupnju u našoj evidenciji.
                </p>
                <ul>
                    <h4>3) Komunikacije</h4>
                </ul>
                <p>
                    Možemo vas kontaktirati putem e-pošte i na druge načine, uključujući putem javno dostupnih obavijesti, u svrhe povezane s Uslugama
                    (pismo dobrodošlice, sigurnosne i pravne obavijesti, najave, ažuriranja Teorema, podsjetnici, prijedlozi itd.). Također možemo
                    koristiti vaše podatke kako bismo vam omogućili komunikaciju s drugim korisnicima putem Teorema, uključujući razmjenu poruka,
                    vođenje video poziva i/ili ostavljanje povratnih informacija o drugim korisnicima.
                </p>
                <p>
                    Također možete primati određene push obavijesti putem aplikacije za mobilni uređaj Teorem. Takve obavijesti možete prilagoditi
                    pomoću internih postavki vašeg pametnog telefona.
                </p>
                <p>
                    Nakon što primite našu komunikaciju, uvijek se možete isključiti iz takve komunikacije u svakoj poruci, isključujući pravne
                    obavijesti, bitne podsjetnike/obavijesti ili promjene korisničkih postavki na Teoremu.
                </p>
                <ul>
                    <h4>4) Istraživanje korisnika</h4>
                </ul>
                <p>
                    Vaši kontakt podaci (uključujući e-poštu, telefonski broj itd.) također se mogu koristiti za prikupljanje povratnih informacija.
                    Možemo vas kontaktirati kako bismo sudjelovali u istraživanju kako bismo poboljšali korisničko iskustvo našeg proizvoda.
                    Istraživanje će se koristiti samo u interne svrhe, uključujući:
                    <ul>- Razvoj značajki i ažuriranje proizvoda; </ul>
                    <ul>- Marketinške aktivnosti, uključujući njihovo objavljivanje na Teoremu na drugim izvorima informacija;</ul>
                    <ul>- Ažuriranja korisničke podrške;</ul>
                    <ul>- Ostala ažuriranja usluge za poboljšanje vašeg iskustva.</ul>
                </p>
                <p>
                    Ispitivač neće snimati vaše povratne informacije video zapisom, audio zapisom niti emitirati putem interneta bez prethodnog vašeg
                    pristanka. Isto vrijedi i za vaš glasovni i/ili slikovni zapis.
                </p>
                <ul>
                    <h4>5) Marketing i promicanje Teorema</h4>
                </ul>
                <p>
                    Možemo obraditi vaše ime, prezime i adresu e-pošte na našoj listi za slanje e-pošte ili za bilten te vam poslati poruke s nekim
                    komercijalnim ili promotivnim informacijama o našim Uslugama. Ali ne brinite. Prvo ćemo zatražiti vaš pristanak, a uvijek se
                    možete isključiti iz naših marketinških poruka pomoću mehanizama opisanih pod odgovarajućom poveznicom u svakoj poruci i putem
                    korisničkih postavki dostupnih na Teoremu.
                </p>
                <p>
                    Ako nam date svoj telefonski broj, možemo vas također kontaktirati s nekim komercijalnim ili promotivnim informacijama o našim
                    Uslugama.
                </p>
                <p>
                    Kako bismo promovirali Teorem Marketplace putem bloga i kanala utjecaja, dopuštamo pojedincima da sudjeluju u affiliate programu
                    na našoj web stranici. Kada primimo informacije za sudjelovanje u affiliate programu, obrađujemo te podatke kako bismo vas
                    registrirali kao affiliate i dalje pratili napredak promocije Teorem Marketplacea. Možemo koristiti poštansku adresu prikupljenu
                    od vas uz vaš pristanak u vezi s marketingom i optimizacijom tražilica.
                </p>
                <p>
                    Također, obratite pozornost na to da podatke koje nam dostavite možemo koristiti kao povratnu informaciju. Potonje, prema odjeljku
                    12 <a href={'/hr/uvjeti-koristenja'}> Uvjeta usluge</a>, Teorem može koristiti bez ikakve dodatne naknade vama.
                </p>
                <ul>
                    <h4>6) Bihevioralni marketing i marketing na društvenim mrežama</h4>
                </ul>
                <p>
                    Vašu adresu e-pošte možemo podijeliti s Googleom i Metom u svrhu isporuke prilagođenih oglasa i uključivanja u bihevioralni
                    marketing i marketing na društvenim mrežama. Provodit ćemo ove marketinške aktivnosti na temelju našeg legitimnog interesa i
                    vodeći računa o vašim interesima i privatnosti.
                </p>
                <ul>
                    <h4>7) Identificiranje utjecajnih osoba za potencijalnu suradnju </h4>
                </ul>
                <p>
                    Možemo koristiti usluge trećih strana, kao što su Captiv8, Inc. i LiveRamp Holdings, Inc. kako bismo analizirali informacije koje
                    ste nam dali u trenutku registracije (kao što su vaše ime i drugi identifikatori), izvršili vježbu uparivanja i otkrili našim
                    najutjecajnijim studentima za potrebe predlaganja suradnje s nama. Pritom možemo primiti neke vaše osobne podatke od Captiv8, Inc.
                    (kao što su vaši profili na društvenim mrežama) i uspostaviti zajedničku kontrolu nad vašim podacima. U slučaju da otkrijemo da
                    ste influencer i odlučimo predložiti suradnju, poslat ćemo vam kratku obavijest o privatnosti koja detaljno objašnjava ovu
                    aktivnost.
                </p>
                <ul>
                    <h4>8) Analitika i razvoj</h4>
                </ul>
                <p>
                    Kako bismo uspostavili nove načine poboljšanja Teorem Marketplacea, pronalaženja točaka interesa u našim Uslugama i za potrebe
                    analitike, pratimo i analiziramo osobne podatke koje nam dajete, uključujući podatke koje dajete prilikom registracije, web
                    promet, kolačiće i podatke o korištenju, kao što su dijelovi naših usluga koje posjećujete, datum i trajanje vašeg posjeta, vaša
                    lokacija, vrsta uređaja koji ste koristili tijekom vašeg posjeta i drugi relevantni podaci o korištenju Teorem Marketplacea. U te
                    svrhe koristimo usluge kao što su Google Analytics, Hotjar HeatMaps & Recordings, Amplitude Analytics, Branch.io, Braze i
                    Intercom, čija upotreba osigurava usklađenost s našim obvezama o privatnosti.
                </p>
                <p>
                    U gore navedene svrhe, također možemo provoditi povremene istraživačke aktivnosti, uključujući personalizirane "A/B" testove.
                    Tijekom testiranja korisnicima ćemo prikazati različite sadržaje na temelju različitih kriterija, poput njihove zemlje. Svrha
                    takvih testova je razumjeti angažman korisnika i zadovoljstvo određenim značajkama kako bismo poboljšali korisničko iskustvo,
                    razvili nove korisne značajke i pojednostavili naše Usluge.
                </p>
                <p>
                    Također možemo provoditi ankete i istraživanja putem Teorema koristeći vanjske usluge usklađene s GDPR-om. Niste obvezni na njih
                    odgovarati i možete se odjaviti od takvih anketa putem korisničkih postavki. Kako bismo vas informirali, optimizirali i poslužili
                    vam relevantno oglašavanje na temelju vašeg prethodnog korištenja naših usluga, možemo obraditi vaše podatke o korištenju i
                    kolačiće. Molimo, slijedite našu
                    <a href={'/hr/politika-kolacica'}> Politiku kolačića</a> za dodatne informacije.
                </p>
                <ul>
                    <h4>9) Verifikacija mentora i priprema programa</h4>
                </ul>
                <p>
                    Kako bismo osigurali visoku kvalitetu lekcija koje Instruktori daju na Teoremu, prikupljamo i obrađujemo vjerodajnice nastavnika
                    kako bismo potvrdili njihovu podobnost za poučavanje predmeta koje sami zatraže. Osim toga, surađujemo s Instruktorima i koristimo
                    njihove informacije kako bismo im pomogli u razvoju programa učenja.
                </p>
                <p>
                    Za potrebe analitike dodatno obrađujemo podatke o registraciji Instruktora kako bismo automatizirali i poboljšali proces
                    verifikacije Instruktora.
                </p>
                <ul>
                    <h4>10) Poboljšanje algoritma za rangiranje nastavnika </h4>
                </ul>
                <p>
                    Prikupljamo i koristimo različite podatke temeljene na metrici kako bismo poboljšali algoritam rangiranja Instruktora i poboljšali
                    vaše iskustvo na temelju našeg legitimnog interesa. Ovi se podaci također mogu sastojati od osobnih podataka koje Instruktori i
                    Studeni daju na Teorem Marketplace. Možete pogledati odjeljak 3.6 naših
                    <a href={'/hr/uvjeti-koristenja'}> Uvjeta pružanja usluge</a> kako biste saznali više o kategorijama podataka koje možemo
                    prikupljati.
                </p>
                <ul>
                    <h4>11) Upravljanje zajednicom Instruktora</h4>
                </ul>
                <p>
                    Obrađujemo podatke o e-pošti, interakciji i korištenju Instruktora koji sudjeluju u Zajednici Instruktora kako bismo im omogućili
                    da se okupe i podijele svoje najbolje prakse, dobiju službene ažurne informacije i komuniciraju međusobno i s Teoremom na temelju
                    našeg legitimnog interesa za izgradnju zajednice. Prikupljene podatke također koristimo za potrebe moderiranja i statistike te za
                    poboljšanje korisničkog iskustva Instruktora.
                </p>
                <p>
                    Budite uvjereni da će pristup osobnim podacima koje ste dostavili Zajednici nastavnika biti ograničen na pružatelje usluga i
                    zaposlenike prema potrebi.
                </p>
                <ul>
                    <h4>12) Podrška i nadzor platforme</h4>
                </ul>
                <p>
                    U slučaju bilo kakvih sporova u vezi s Teoremom i drugim problemima povezanim s Uslugama, možemo koristiti podatke (uključujući
                    komunikaciju) za rješavanje takvih pitanja, nadgledanje ponašanja korisnika na Teorem Marketplaceu i odgovoriti na sve pritužbe
                    ili zahtjeve.
                </p>
                <ul>
                    <h4>13) Događaji i webinari koje provodi Teorem</h4>
                </ul>
                <p>
                    Obrađujemo određene podatke o plaćanju kako bismo bili u skladu s primjenjivim zakonskim zahtjevima u području financija i/ili
                    računovodstva. Također možemo obrađivati podatke prikupljene na Teorem Marketplaceu u vezi s pravnim zahtjevima koje primamo od
                    vas ili bilo koje druge nadležne osobe i/ili tijela, a te podatke možemo koristiti za ostvarivanje, obranu i/ili uspostavljanje
                    naših zakonskih prava.
                </p>
                <ul>
                    <h4>14) Sprečavanje prijevara</h4>
                </ul>
                <p>
                    Određene informacije, kao što su podaci o vašem dnevniku, podaci o plaćanju i vaše ponašanje na Teorem Marketplaceu podliježu
                    našem nadzoru kako bismo spriječili lažne aktivnosti i pomogli vam u upravljanju vašim korisničkim sesijama.
                </p>
                <ul>
                    <h4>15) Promjene</h4>
                </ul>
                <p>
                    S vremena na vrijeme možemo promijeniti Politiku privatnosti Teorema. Nakon bilo kakvih izmjena ove Politike, vaša prava ne mogu
                    biti smanjena osim ako ne date izričit pristanak. Najnovija verzija objavljena je na ovoj stranici. Ako napravimo značajne
                    promjene, obavijestit ćemo vas slanjem e-pošte.
                </p>
                <ul>
                    <h3>5. Kako se dijele osobni podaci? </h3>
                </ul>
                <p>
                    Kako bi pružio visokokvalitetne usluge, Teorem zapošljava ljude, sklapa ugovore s neovisnim izvođačima kao i surađuje s drugim
                    pružateljima usluga, tvrtkama i organizacijama strogo prema ugovorima o obradi podataka (DPA) ili standardnim ugovornim klauzulama
                    (SCC). Iz tih razloga neki od vaših osobnih podataka mogu biti proslijeđeni navedenim osobama. Teorem koristi samo sigurna mjesta
                    za pohranu, poput onih koje pruža Microsoft Azure, sa serverima koji se nalaze u Njemačkoj i Sjedinjenim Državama.
                </p>
                <ul>
                    <h4>1) Kategorije pružatelja usluga trećih strana</h4>
                </ul>
                <ul>
                    <li>Usluge analitike:</li>
                    <p>
                        Osobni podaci koji se dijele s ovom kategorijom pružatelja usluga trećih strana mogu uključivati informacije opisane u
                        pododjeljcima 5 i 14 odjeljka 3 ovih Pravila privatnosti.
                    </p>
                    <li>Platforme društvenih mreža i tražilice:</li>
                    <p>
                        Osobni podaci podijeljeni s ovom kategorijom pružatelja usluga trećih strana mogu uključivati informacije opisane u
                        pododjeljku 7 odjeljka 4 i pododjeljku 2 odjeljka 5 ovih Pravila privatnosti.
                    </p>
                    <li>Rješenja za komunikaciju s klijentima:</li>
                    <p>
                        Osobni podaci podijeljeni s ovom kategorijom pružatelja usluga trećih strana mogu uključivati informacije opisane u
                        pododjeljku 4 odjeljka 3 ovih Pravila privatnosti.
                    </p>
                    <li>Alati za istraživanje korisnika:</li>
                    <p>
                        Osobni podaci podijeljeni s ovom kategorijom pružatelja usluga trećih strana mogu uključivati informacije opisane u
                        pododjeljku 5 odjeljka 4 ovih Pravila privatnosti.
                    </p>
                    <li>Usluge za upravljanje e-poštom i slanje push obavijesti:</li>
                    <p>
                        Osobni podaci koji se dijele s ovom kategorijom pružatelja usluga trećih strana mogu uključivati vaše podatke za kontakt i
                        osobne identifikatore koje nam dostavite.
                    </p>
                    <li>SMS pružatelji usluga za mobilni marketing i obavijesti:</li>
                    <p>
                        Osobni podaci koji se dijele s ovom kategorijom pružatelja usluga trećih strana mogu uključivati vaše podatke za kontakt i
                        osobne identifikatore koje nam dostavite.
                    </p>
                    <li>Pružatelji usluga platnog prometa:</li>
                    <p>
                        Osobni podaci koji se dijele s ovom kategorijom pružatelja usluga trećih strana mogu uključivati informacije opisane u
                        pododjeljku 6 odjeljka 3 ovih Pravila o privatnosti.
                    </p>
                    <li>Pružatelji usluga koje pokreće AI:</li>
                    <p>
                        Osobni podaci koji se dijele s ovim kategorijama pružatelja usluga trećih strana mogu uključivati informacije opisane u
                        pododjeljcima 16 i 17 odjeljka 3 ove Politike privatnosti.
                    </p>
                    <li>Pružatelji alata za videokonferencije, pretvaranje govora u tekst i analitiku razgovora:</li>
                    <p>
                        Osobni podaci podijeljeni s ovim kategorijama pružatelja usluga trećih strana mogu uključivati informacije opisane u
                        pododjeljku 16 odjeljka 3 ovih Pravila o privatnosti.
                    </p>
                    <li>Pružatelji rješenja za utjecajni marketing za prepoznavanje i angažiranje utjecajnih osoba:</li>
                    <p>
                        Osobni podaci koji se dijele s ovom kategorijom pružatelja usluga trećih strana mogu uključivati vaše ime i druge
                        identifikatore opisane u pododjeljcima 1 i 2 odjeljka 3 ovih Pravila o privatnosti.
                    </p>
                    <li>Alati za procjenu jezika:</li>
                    <p>
                        Osobni podaci koji se dijele s ovom kategorijom pružatelja usluga trećih strana mogu uključivati informacije opisane u
                        pododjeljku 15 odjeljka 3 ovih Pravila o privatnosti.
                    </p>
                    <li>Alati za optimizaciju tražilica i marketing:</li>
                    <p>
                        Osobni podaci podijeljeni s ovom kategorijom pružatelja usluga trećih strana mogu uključivati informacije opisane u
                        pododjeljku 13 odjeljka 3 ovih Pravila o privatnosti.
                    </p>
                    <li>Usluge za upravljanje Akademijom za Instruktore:</li>
                    <p>
                        Osobni podaci podijeljeni s ovom kategorijom pružatelja usluga trećih strana mogu uključivati informacije opisane u
                        pododjeljku 11 odjeljka 3 ovih Pravila o privatnosti.
                    </p>
                    <li>Usluge za upravljanje zajednicom nastavnika:</li>
                    <p>
                        Osobni podaci koji se dijele s ovom kategorijom pružatelja usluga trećih strana mogu uključivati informacije opisane u
                        pododjeljku 10 odjeljka 3 i pododjeljku 10 odjeljka 4 ovih Pravila privatnosti.
                    </p>
                </ul>
                <ul>
                    <h4>2) Korištenje platformi društvenih mreža</h4>
                </ul>
                <p>
                    Koristimo platforme društvenih mreža kao što su Facebook, Instagram, LinkedIn i TikTok za održavanje Teoremovih stranica na
                    društvenim mrežama.
                </p>
                <p>
                    Napominjemo da LinkedIn djeluje i kao izvršitelj obrade kojeg angažira Teorem i kao neovisni voditelj obrade vaših osobnih
                    podataka. Osim toga, TikTok i Teorem neovisno obrađuju vaše osobne podatke kao neovisni voditelji obrade.
                </p>
                <p>
                    Što se tiče stranica Teorema na Facebooku i Instagramu, Meta Platforms, Inc. (“Meta”) zajednički je voditelj obrade osobnih
                    podataka. Konkretno, Meta i Teorem su zajednički kontrolori za podatke o uvidu u stranice i statistiku, vaše reakcije i komentare
                    ispod naših postova i druge informacije koje stavljate na raspolaganje Teoremu nakon što nas kontaktirate.
                </p>
                <p>
                    Više informacija o Meta dogovoru o zajedničkom nadzoru možete pronaći na sljedećoj poveznici:
                    <a href={'https://www.facebook.com/legal/terms/page_controller_addendum'}> Informacije o Page Insightsu</a>.
                </p>
                <p>
                    Možete nam se obratiti s bilo kakvim pitanjima vezanim uz vašu aktivnost na Teoremovim stranicama društvenih medija na gore
                    navedenim platformama društvenih mreža ili našu obradu vaših osobnih podataka.
                </p>
                <p>Pružatelji usluga spomenuti u pododjeljcima 1 i 2 pomoći će nam osigurati sigurnost vaših podataka unutar Teorema.</p>
                <p>
                    Teorem ima izvođače i podružnice u drugim zemljama, uključujući one izvan Europskog gospodarskog prostora, koji mogu imati pristup
                    vašim osobnim podacima. Istodobno, u slučajevima kada Vaše osobne podatke prosljeđujemo drugim osobama, osiguravamo da su Vaši
                    osobni podaci zaštićeni i da se koriste samo u svrhe navedene u ovoj Politici privatnosti. To se postiže korištenjem samo
                    certificiranih usluga i proizvoda, potpisivanjem ugovora o obradi podataka za zaštitu osobnih podataka s izvođačima i partnerima
                    (uključujući Standardne ugovorne klauzule koje je usvojila Europska komisija i usklađene sa zakonima EU o zaštiti podataka), kao i
                    preuzimanjem tehničke mjere kako bi se osiguralo da su informacije zaštićene prilikom pohranjivanja, korištenja te tijekom obrade
                    i prijenosa.
                </p>
                <ul>
                    <h4>3) Prijenos podataka u slučaju promjene kontrole ili raspolaganja imovinom</h4>
                </ul>
                <p>
                    U slučaju bilo kakvog spajanja, akvizicije, prodaje ili promjene kontrole, nastavit ćemo osiguravati povjerljivost vaših osobnih
                    podataka i obavijestiti pogođene korisnike prije nego što se osobni podaci prenesu ili postanu podložni drugačijim pravilima o
                    privatnosti.
                </p>
                <ul>
                    <h4>4) Teorem Neovisni izvođači </h4>
                </ul>
                <p>
                    Surađujemo s neovisnim izvođačima koji nam pomažu u pružanju naših usluga. Konkretno, možemo angažirati takve izvođače da nam
                    pomognu u analizi podataka i razvoju softvera, korisničkoj podršci, promociji, marketingu, istraživanju korisnika, kao i
                    financijskim i pravnim pitanjima. Ti će izvođači imati pristup vašim osobnim podacima samo na temelju potrebe da znaju i pod
                    strogim obvezama privatnosti i sigurnosti.
                </p>
                <ul>
                    <h4>5) Ostali korisnici Teorem Marketplacea</h4>
                </ul>
                <p>
                    Vaši osobni podaci također mogu biti vidljivi posjetiteljima i korisnicima Teorem Marketplacea. To se može dogoditi putem osobnih
                    kontakata (između Studenta i Instruktora) ili u vezi s društvenim značajkama Teorem Marketplacea. Na primjer, vaša profilna slika
                    i ime mogu biti vidljivi ako ste se prijavili za grupni tečaj ili ako ste ostavili recenziju Instruktora.
                </p>
                <ul>
                    <h4>6) Koliko dugo će Teorem obrađivati i čuvati osobne podatke?</h4>
                </ul>
                <p>Vaše osobne podatke Teorem neće čuvati dulje nego što je potrebno za svrhe za koje se osobni podaci obrađuju.</p>
                <p>
                    Općenito, Teorem zadržava vaše osobne podatke sve dok je vaš račun aktivan. Ako je vaša posljednja aktivnost na Teoremu bila prije
                    više od 3 godine, smatrat će se da je vaš račun istekao. U tom slučaju ili kada odlučite izbrisati svoj Teorem račun, obvezujemo
                    se ukloniti vaše osobne podatke iz naših aktivnih sustava i poslužitelja u roku od 90 dana ili ranije.
                </p>
                <p>
                    Međutim, u slučaju konfliktnih situacija u tijeku, Teorem može zadržati vaše osobne podatke dulje kako bi mogao uspostaviti,
                    ostvariti ili obraniti pravne zahtjeve. Nakon razrješenja konfliktne situacije svi osobni podaci se brišu ako je isteklo
                    standardno razdoblje čuvanja.
                </p>
                <p>
                    Također možemo zadržati neke vaše osobne podatke dulje ako su nam potrebni radi usklađivanja s primjenjivim zakonskim,
                    regulatornim, poreznim, računovodstvenim ili drugim zahtjevima.
                </p>
                <p>
                    Osobni podaci prikupljeni kada ste se registrirali na našu mailing listu ili za newsletter bit će pohranjeni sve dok ste
                    zainteresirani za primanje naše e-pošte. Smatramo da ste zainteresirani za ovo sve dok se ne odjavite s popisa za slanje e-pošte
                    ili newslettera klikom na odgovarajuću poveznicu koja se nalazi u svakoj našoj e-poruci.
                </p>
                <p>
                    Ako ste se registrirali za bilo koji od naših događaja ili webinara koristeći vjerodajnice vašeg računa, te ćemo informacije
                    spojiti i pohraniti zajedno s podacima vašeg računa. Ako ste koristili druge podatke za kontakt, izbrisat ćemo ih u roku od 30
                    dana nakon održavanja događaja ili webinara.
                </p>
                <p>
                    Nakon isteka roka čuvanja, Teorem će prekinuti obradu Vaših osobnih podataka osim ako mjerodavno zakonodavstvo ne određuje
                    drugačije.
                </p>
                <ul>
                    <h4>7) Vaša prava kao subjekta podataka u vezi s obradom vaših osobnih podataka Teorem</h4>
                </ul>
                <b>1. Pravo potvrde</b>
                <p>Od Teorema možete dobiti potvrdu da li se obrađuju osobni podaci koji se odnose na Vas.</p>
                <b>2. Pravo pristupa</b>
                <p>Možete pristupiti svojim osobnim podacima koje obrađuje Teorem.</p>
                <b>3. Pravo na ispravak</b>
                <p>Možete zatražiti od Teorema dopunu nepotpunih osobnih podataka koji zahtijevaju ispravke.</p>
                <b>4. Pravo na brisanje (pravo na zaborav)</b>
                <p>
                    Možete zatražiti od Teorema brisanje osobnih podataka. U većini slučajeva, Teorem će ga izbrisati osim ako nije drugačije
                    propisano zakonom.
                </p>
                <b>Da biste izbrisali svoje osobne podatke, možete:</b>
                <li>
                    Kontaktirati nas e-poštom na <a href={'mailto:support@teorem.co'}> support@teorem.co</a>. Odgovorit ćemo na vaš zahtjev za
                    brisanjem vaših podataka u roku od 30 dana i obavijestiti vas o ishodu.
                </li>
                <li>Izbrisati određene stavke sa svog računa (kao što je način plaćanja itd.) odlaskom na stranicu "Postavke" vašeg računa.</li>
                <b>5. Pravo na ograničenje obrade </b>
                <p>
                    Možete osporiti točnost Vaših osobnih podataka ili u slučaju da Teorem više nije zainteresiran za obradu Vaših osobnih podataka,
                    ali želite da Teorem to učini iz različitih razloga, na primjer, da podnese tužbu protiv nekoga i umjesto brisanja informacija,
                    njihova će obrada biti ograničena.
                </p>
                <b>6. Pravo na prenosivost podataka</b>
                <p>
                    Vaši osobni podaci mogu se izravno prenijeti od jednog voditelja obrade do drugog kada je to tehnički izvedivo, a kada to ne
                    utječe negativno na prava i slobode drugih.
                </p>
                <b>7. Pravo na prigovor na obradu Vaših osobnih podataka od strane Teorema</b>
                <p>
                    Možete se usprotiviti našoj obradi Vaših osobnih podataka kada je obrada povezana s obavljanjem naše zadaće u javnom interesu ili
                    izvršavanjem službenih ovlasti koje su nam dodijeljene. Drugi slučaj je ako obrađujemo vaše podatke u svrhu legitimnih interesa za
                    kojima težimo mi ili treća strana, a vi smatrate da su vaši interesi ili temeljna prava i slobode nadjačani nad takvim interesima.
                    Ako podnesete zahtjev s prigovorom na obradu, više nećemo obrađivati osobne podatke osim ako ne možemo dokazati uvjerljive
                    legitimne razloge za obradu.
                </p>
                <b>8. Pravo na povlačenje privole u bilo kojem trenutku kontaktiranjem s nama</b>
                <p>
                    Nakon što mi u Teoremu zaprimimo zahtjev za povlačenje od vas, obradit ćemo ga pravovremeno, te više nećemo obrađivati vaše osobne
                    podatke osim ako nije drugačije određeno zakonom.
                </p>
                <p>
                    Ako postoji jedan od gore navedenih razloga, obratite nam se prema uputama na kraju ovih Pravila privatnosti. Odmah ćemo osigurati
                    da se zahtjevu odmah udovolji. Ovi zahtjevi su besplatni.
                </p>
                <p>Ako smatrate da naše korištenje Vaših podataka krši Vaša prava, možete podnijeti pritužbu nadležnom tijelu za zaštitu podataka.</p>
                <p>
                    Imajte na umu da kada se obrada Vaših osobnih podataka provodi u svrhe izravnog marketinga, imate pravo u bilo kojem trenutku
                    uložiti prigovor na takvu obradu bez navođenja ikakvog obrazloženja i mi više nećemo obrađivati Vaše podatke u takve svrhe
                    izravnog marketinga.
                </p>
                <ul>
                    <h4>8) Ostale obveze u vezi s privatnošću</h4>
                </ul>
                <b>1. Prekogranični transferi </b>
                <p>
                    Vaše osobne podatke obrađujemo unutar i izvan Europske unije. Iako zemlje u kojima obrađujemo podatke mogu imati različite zakone,
                    poduzimamo mjere kako bismo osigurali visoke standarde privatnosti podataka i usklađenosti (naime, kako je navedeno u odjeljku 5
                    ovih Pravila o privatnosti).
                </p>
                <b>2. Vrijeme za odgovor i reakciju</b>
                <p>
                    Teorem će najduže u roku od mjesec dana od primitka zahtjeva dostaviti informacije o radnjama koje su poduzete po Vašem zahtjevu
                    vezano uz Vaša gore navedena prava. To se razdoblje može produžiti za dodatna dva mjeseca ako je Teorem preopterećen brojem
                    zahtjeva ili je sporni zahtjev kompliciran i zahtijeva puno radnji. Teorem će vas obavijestiti o svakom takvom produženju u roku
                    od mjesec dana od primitka zahtjeva, zajedno s razlozima takvog kašnjenja.
                </p>
                <b>3. Sigurnost</b>
                <p>
                    Teorem poduzima tehničke i organizacijske mjere kako bi osigurao da se informacije obrađuju na način koji osigurava odgovarajuću
                    sigurnost informacija, uključujući zaštitu od neovlaštene ili nezakonite obrade i slučajnog gubitka, uništenja ili oštećenja. Na
                    primjer, Teorem koristi provjerene izvođače koji bi mogli imati pristup podacima s kojima su potpisani odgovarajući ugovori o
                    obradi podataka.
                </p>
                <b>4. Pristup računu radi pružanja podrške</b>
                <p>
                    U iznimnim okolnostima, kao što je potrebna korisnička podrška, naši stručnjaci za podršku možda će morati pristupiti vašem
                    računu. Kada se takva prilika dogodi, oni će moći vidjeti osobne podatke poslane na vaš račun, uključujući vaše zakazane osobne
                    događaje, ako su vaš Google kalendar i Teorem kalendar povezani.
                </p>
                <p>
                    Obvezujemo se da ćemo uvijek tražiti vaše dopuštenje za pristup vašem računu prije nego počnemo rješavati probleme. Prije nego što
                    nam date svoje dopuštenje, također možete razmisliti o uređivanju postavki privatnosti Google kalendara pomoću centra za pomoć
                    Google kalendara kako biste sakrili svoje osobne događaje od nas.
                </p>
                <p>
                    Imajte na umu da ako ne želite dopustiti pristup svom računu, to može utjecati na vrijeme i kvalitetu pružanja korisničke podrške.
                </p>
                <b>5. Privatnost djece</b>
                <p>
                    Privatnost djece jedna je od naših briga. Teorem usluge nisu namijenjene djeci mlađoj od 18 godina bez nadzora roditelja ili
                    zakonskog skrbnika. Ako ste mlađi od 18 godina, morat ćete zamoliti svog roditelja/zakonskog skrbnika da postavi račun na Teorem
                    Marketplaceu koji možete koristiti pod njihovim nadzorom.
                </p>
                <p>
                    Ne prikupljamo svjesno podatke od djece mlađe od 13 godina i obeshrabrujemo djecu mlađu od ove dobi da koriste Teorem usluge.
                    Potičemo roditelje i zakonske skrbnike da nadziru korištenje interneta od strane svoje djece i da pomognu u provođenju naših
                    Pravila o privatnosti upućujući svoju djecu da nikada ne daju informacije na Teorem Marketplace bez njihova dopuštenja.
                </p>
                <b>6. Obavijesti o kršenju </b>
                <p>
                    Ako postoji povreda osobnih podataka koja će vjerojatno dovesti do visokog rizika od negativnog utjecaja na vaša prava kao
                    subjekta podataka, obavijestit ćemo vas i odgovarajuće agencije za zaštitu podataka o nesrećama bez nepotrebnog odgađanja. Također
                    bismo dali sve od sebe da svedemo takve rizike na minimum.
                </p>
                <b>7. Zahtjevi "Ne prati" </b>
                <p>
                    Teorem ne dijeli osobne podatke s trećim stranama u svrhu njihovog izravnog marketinga. Također, naše usluge ne podržavaju
                    zahtjeve "Ne prati".
                </p>
                <b>8. Automatizirano donošenje odluka i profiliranje</b>
                <p>
                    Kao dio funkcionalnosti Teorem Marketplacea, obrađujemo osobne podatke naših mentora i studenata na način koji se može smatrati
                    profiliranjem.
                </p>
                <p>
                    Konkretno, koristimo se algoritmima strojnog učenja koji analiziraju različite podatke temeljene na metrici i određuju rang
                    nastavnika na Teorem Marketplaceu i njihovu vidljivost studentima. To je učinjeno kako bi se povećala učinkovitost usklađivanja
                    između učenika i nastavnika na Teorem Marketplaceu i omogućilo mentorima da se povežu s optimalnim brojem učenika koji najbolje
                    odgovaraju njihovom načinu rada.
                </p>
                <p>
                    Imajte na umu da je ova vrsta obrade neophodna za pružanje naših Usluga. Da biste saznali više o ovom procesu i uključenoj logici,
                    pogledajte odjeljak 3.6 naših <a href={'/hr/uvjeti-koristenja'}> Uvjeta pružanja usluge</a>.
                </p>
                <p>
                    Ako želite zatražiti ljudsku intervenciju, izraziti svoje stajalište ili osporiti odluke koje donose algoritmi, pošaljite nam
                    e-poruku na <a href={'mailto:legal@teorem.co'}> legal@teorem.co</a>.
                </p>
                <b>9. Prodaja osobnih podataka</b>
                <p>Ne prodajemo Vaše osobne podatke i osobne podatke bilo kojih maloljetnika mlađih od 16 godina.</p>
                <ul>
                    <h4>9) Pravna pitanja</h4>
                </ul>
                <p>
                    Teorem smatra vašu upotrebu usluga privatnom. Međutim, možemo otkriti vaše osobne podatke pohranjene u našim bazama podataka kako
                    bismo:
                </p>
                <ul>1) Postupili u skladu sa zakonom ili pravnim postupkom koji nam je dostavljen;</ul>
                <ul>
                    2) Proveli i istražili potencijalna kršenja ovih Pravila privatnosti; uključujući korištenje Usluga za sudjelovanje ili
                    olakšavanje aktivnosti koje krše zakon;
                </ul>
                <ul>3) Istražili potencijalne prijevarne aktivnosti; ili</ul>
                <ul>4) Zaštitili prava, imovinu ili sigurnost Teorema, njegovih zaposlenika, kupaca ili javnosti.</ul>
                <p>
                    Nadamo se da će vam ova Pravila o privatnosti pomoći da razumijete kako će Teorem postupati s vašim osobnim podacima. Vodit ćemo
                    brigu o vašim osobnim podacima kako biste mogli koristiti naše usluge i ne brinuti se za svoju privatnost. Ako imate pitanja,
                    pošaljite nam e-poštu na <a href={'mailto:support@teorem.co'}> support@teorem.co</a>.
                </p>
                <h3>Vaš Teorem tim</h3>
            </div>
        </LandingWrapper>
    );
};

export default Privacy_HR;
