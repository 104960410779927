import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';

import logo from '../../../../assets/images/logo.svg';
import logoWhite from '../../../../assets/images/logoWhite.svg';
import LocaleSelector from '../../localization/components/LocaleSelector';
import { useAppDispatch } from '../../../store/hooks';
import { setLoginModalOpen, setRegistrationModalOpen } from '../../../store/slices/auth/authSlice';
import { ButtonPrimaryGradient } from './ButtonPrimaryGradient';

const Navigation = () => {
    const [showSidebar, setShowSidebar] = useState(false);
    const landingHeaderRef = useRef<HTMLDivElement>(null);
    const [onTop, setOnTop] = useState<boolean>(true);
    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [windowSize, setWindowSize] = useState({
        width: undefined as number | undefined,
        height: undefined as number | undefined,
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, [window.innerWidth]);

    useEffect(() => {
        if (showSidebar) {
            document.getElementsByTagName('html')[0].style.overflow = 'hidden';
        } else {
            document.getElementsByTagName('html')[0].style.overflow = 'auto';
        }
    }, [showSidebar]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return function () {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const isMobile = windowSize.width ? windowSize.width < 1201 : false;

    const handleScroll = (e: any) => {
        const scrollTop = e.currentTarget?.pageYOffset;
        const landingHeaderElement = landingHeaderRef.current as HTMLDivElement;
        //debugger;
        if (scrollTop === 0) {
            landingHeaderElement.classList.add('active');
            setOnTop(true);
        } else {
            landingHeaderElement.classList.remove('active');
            setOnTop(false);
        }
    };

    return (
        <div
            ref={landingHeaderRef}
            className={`layout__header--background active ${
                history.location.pathname === '/pricing' ? (onTop ? 'layout__header--background--primary' : '') : ''
            }`}
        >
            <div className="layout__header--landing">
                <div className="flex--primary">
                    {isMobile ? (
                        <div className="flex--col language__mobile w--100">
                            <div className="flex flex--jc--space-between align--items--center">
                                <NavLink className="landing__navigation__logo d--b flex--shrink" to={t('PATHS.LANDING_PATHS.HOW_IT_WORKS')}>
                                    {history.location.pathname === '/pricing' ? (
                                        onTop ? (
                                            <img src={logoWhite} alt="logo" />
                                        ) : (
                                            <img src={logo} alt="logo" />
                                        )
                                    ) : (
                                        <img src={logo} alt="logo" />
                                    )}
                                </NavLink>

                                <div>
                                    <ButtonPrimaryGradient
                                        className="get-started"
                                        style={{ marginRight: '8px', padding: '8px' }}
                                        onClick={() => dispatch(setRegistrationModalOpen(true))}
                                    >
                                        {t('LANDING.HEADER.GET_STARTED')}
                                    </ButtonPrimaryGradient>
                                    <div
                                        style={{ top: -15 }}
                                        className={`d--ib pos--rel landing__navigation__hamburger ${history.location.pathname === '/pricing' ? (onTop ? 'pricing' : '') : ''} ${
                                            showSidebar ? 'active' : ''
                                        }`}
                                        onClick={() => setShowSidebar(!showSidebar)}
                                    >
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="landing__navigation__items">
                            <NavLink className="landing__navigation__logo d--b flex--shrink" to={t('PATHS.LANDING_PATHS.HOW_IT_WORKS')}>
                                {history.location.pathname === '/pricing' ? (
                                    onTop ? (
                                        <img src={logoWhite} alt="logo" />
                                    ) : (
                                        <img src={logo} alt="logo" />
                                    )
                                ) : (
                                    <img src={logo} alt="logo" />
                                )}
                            </NavLink>

                            <div className="flex flex--grow flex--jc--center">
                                <NavLink to={t('PATHS.LANDING_PATHS.HOW_IT_WORKS')} className="nav-link--landing">
                                    <div>{t('LANDING.HEADER.NAV_1')}</div>
                                </NavLink>
                                <NavLink to={t('PATHS.LANDING_PATHS.PRICING')} className="nav-link--landing">
                                    <div>{t('LANDING.HEADER.NAV_2')}</div>
                                </NavLink>
                                <NavLink to={t('PATHS.LANDING_PATHS.BECOME_TUTOR')} className="nav-link--landing">
                                    <div>{t('LANDING.HEADER.NAV_3')}</div>
                                </NavLink>
                                <a href={t('PATHS.LANDING_PATHS.BLOG')} target="_self" className="nav-link--landing">
                                    <div>{t('LANDING.HEADER.NAV_4')}</div>
                                </a>
                            </div>
                            <div className="flex--primary">
                                <div className="flex">
                                    <LocaleSelector onTop={onTop} />
                                    <button
                                        onClick={() => dispatch(setLoginModalOpen(true))}
                                        className="btn btn--secondary btn--ghost--landing type--wgt--bold"
                                    >
                                        {t('LANDING.HEADER.SIGN_IN')}
                                    </button>
                                    <ButtonPrimaryGradient
                                        className="get-started"
                                        style={{ marginLeft: '8px' }}
                                        onClick={() => dispatch(setRegistrationModalOpen(true))}
                                    >
                                        {t('LANDING.HEADER.GET_STARTED')}
                                    </ButtonPrimaryGradient>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div>
                    {/*MODAL THAT OPENS ON HAMBURGER PRESS*/}
                    <div className={`landing__navigation ${showSidebar ? 'active' : ''}`}>
                        <div className="flex--grow mt-8">
                            <NavLink to={t('PATHS.LANDING_PATHS.HOW_IT_WORKS')} className="d--b landing__navigation__item">
                                <div className="flex flex--center">
                                    <i className="icon icon--base icon--how-it-works icon--primary-gradient"></i>
                                    <span className="d--ib ml-2">{t('LANDING.HEADER.NAV_1')}</span>
                                </div>
                            </NavLink>
                            <NavLink to={t('PATHS.LANDING_PATHS.PRICING')} className="d--b landing__navigation__item">
                                <div className="flex flex--center">
                                    <i className="icon icon--base icon--pricing-item icon--primary-gradient"></i>
                                    <span className="d--ib ml-2">{t('LANDING.HEADER.NAV_2')}</span>
                                </div>
                            </NavLink>
                            <NavLink to={t('PATHS.LANDING_PATHS.BECOME_TUTOR')} className="d--b landing__navigation__item">
                                <div className="flex flex--center">
                                    <i className="icon icon--base icon--become-a-tutor icon--primary-gradient"></i>
                                    <span className="d--ib ml-2">{t('LANDING.HEADER.NAV_3')}</span>
                                </div>
                            </NavLink>

                            <a href={t('PATHS.LANDING_PATHS.BLOG')} target="_self" className="d--b landing__navigation__item">
                                <div className="flex flex--center">
                                    <i className="icon icon--base icon--blog icon--primary-gradient"></i>
                                    <span className="d--ib ml-2">{t('LANDING.HEADER.NAV_4')}</span>
                                </div>
                            </a>

                            <div style={{ position: 'fixed', bottom: '0', left: '0', right: '0', textAlign: 'center' }}>
                                {isMobile ? (
                                    <div>
                                        <button
                                            onClick={() => dispatch(setLoginModalOpen(true))}
                                            className="btn btn--ghost btn--ghost--landing type--wgt--bold"
                                            style={{ width: '80%', margin: '10px' }}
                                        >
                                            {t('LANDING.HEADER.SIGN_IN')}
                                        </button>
                                        <ButtonPrimaryGradient
                                            className="get-started"
                                            style={{ width: '80%' }}
                                            onClick={() => dispatch(setRegistrationModalOpen(true))}
                                        >
                                            {t('LANDING.HEADER.GET_STARTED')}
                                        </ButtonPrimaryGradient>
                                    </div>
                                ) : null}

                                <div className="d--b landing__navigation__item">
                                    <LocaleSelector color="#5c3ee8" onTop={onTop} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Navigation;
