import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import Fuse from 'fuse.js';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../../../../components/Modal';
import { useLocaleContext } from '../../providers/LocaleProvider';
import styles from './LocaleSelectModal.module.scss';
import TabButton from '../../../../components/TabButton';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { ICountry } from '../../../../store/slices/countryMarket/countryService';
import { setSelectedCountry } from '../../../../store/slices/countryMarket/countryMarketSlice';
import CtaButton from '../../../../components/CtaButton';
import { ILanguage } from '../../../../store/slices/lang/langService';
import { setSelectedLang } from '../../../../store/slices/lang/langSlice';

export default function LocaleSelectModal() {
    const [activeTab, setActiveTab] = useState<'country' | 'language'>('country');
    const { langModalOpen, setLangModalOpen } = useLocaleContext();
    const { countries: countryOptions, selectedCountry: activeCountry } = useAppSelector((state) => state.countryMarket);
    const { languages: languageOptions, selectedLanguage: activeLanguage } = useAppSelector((state) => state.lang);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [pickedCountry, setPickedCountry] = useState<ICountry | null>(activeCountry);
    const [pickedLanguage, setPickedLanguage] = useState<ILanguage | null>(activeLanguage);
    const [countryFilter, setCountryFilter] = useState<string>('');
    const [languageFilter, setLanguageFilter] = useState<string>('');

    const displayedCountryOption = useMemo(() => {
        if (!countryFilter) return countryOptions;

        const fuse = new Fuse(countryOptions, {
            keys: ['label', 'path'],
        });

        return fuse.search(countryFilter).map((o) => o.item);
    }, [countryFilter, countryOptions]);

    const displayedLanguageOption = useMemo(() => {
        if (!languageFilter) return languageOptions;

        const fuse = new Fuse(languageOptions, {
            keys: ['label', 'path'],
        });

        return fuse.search(languageFilter).map((o) => o.item);
    }, [languageFilter, languageOptions]);

    useEffect(() => {
        if (!langModalOpen) return;
        setActiveTab('country');
        setPickedCountry(activeCountry);
        setPickedLanguage(activeLanguage);
    }, [langModalOpen]);

    const handleCountryNext = (country?: ICountry) => {
        if (country) return setPickedCountry(country);
        setActiveTab('language');
    };

    const handleLanguageNext = useCallback(
        (language?: ILanguage) => {
            if (language) return setPickedLanguage(language);
            if (!pickedCountry || !pickedLanguage) return;
            dispatch(setSelectedLang(pickedLanguage));
            dispatch(setSelectedCountry(pickedCountry));
            setActiveTab('country');
            setLangModalOpen(false);
        },
        [pickedCountry, pickedLanguage, setLangModalOpen]
    );

    return (
        <Modal
            open={langModalOpen}
            onBackdropClick={() => {
                setLangModalOpen(false);
            }}
            backdropClickDisabled={!pickedLanguage?.id.length || !pickedCountry}
            title={t('LANDING.LOCALE.TITLE')}
        >
            <div className={styles.tabs}>
                <TabButton active={activeTab === 'country'} onClick={() => setActiveTab('country')}>
                    {t('LANDING.LOCALE.TITLE_COUNTRY')}
                </TabButton>
                <TabButton active={activeTab === 'language'} onClick={() => setActiveTab('language')}>
                    {t('LANDING.LOCALE.TITLE_LANGUAGE')}
                </TabButton>
            </div>
            {activeTab === 'country' ? (
                <>
                    <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        className={styles.search}
                        placeholder={t('LANDING.LOCALE.SEARCH')}
                        value={countryFilter}
                        onChange={(e) => setCountryFilter(e.target.value)}
                    />
                    <div className={styles.options}>
                        {displayedCountryOption?.map((o) => {
                            return (
                                <button
                                    key={o.id}
                                    className={clsx(styles.option, {
                                        [styles.selected]: o.id === pickedCountry?.id,
                                    })}
                                    onClick={() => {
                                        handleCountryNext(o);
                                    }}
                                >
                                    {t(o.name)}
                                    <Checkbox sx={{ padding: '4px' }} color="default" checked={o.id === pickedCountry?.id} />
                                </button>
                            );
                        })}
                    </div>
                    <CtaButton fullWidth disabled={!pickedCountry} onClick={() => handleCountryNext()}>
                        {t('LANDING.LOCALE.SUBMIT_COUNTRY')}
                    </CtaButton>
                </>
            ) : (
                <>
                    <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        className={styles.search}
                        placeholder={t('LANDING.LOCALE.SEARCH')}
                        value={languageFilter}
                        onChange={(e) => setLanguageFilter(e.target.value)}
                    />
                    <div className={styles.options}>
                        {displayedLanguageOption?.map((o) => {
                            return (
                                <button
                                    key={o.id}
                                    className={clsx(styles.option, {
                                        [styles.selected]: o.id === pickedLanguage?.id,
                                    })}
                                    onClick={() => {
                                        handleLanguageNext(o);
                                    }}
                                >
                                    {t(o.name)}
                                    <Checkbox sx={{ padding: '4px' }} color="default" checked={o.id === pickedLanguage?.id} />
                                </button>
                            );
                        })}
                    </div>
                    <CtaButton fullWidth onClick={() => handleLanguageNext()} disabled={!pickedLanguage?.id.length || !pickedCountry}>
                        {t('LANDING.LOCALE.SUBMIT_LANGUAGE')}
                    </CtaButton>
                </>
            )}
        </Modal>
    );
}
