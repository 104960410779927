import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiSupport } from 'react-icons/bi';
import { HiDocumentCheck } from 'react-icons/hi2';
import { IoCardOutline } from 'react-icons/io5';
import { TbShieldLockFilled } from 'react-icons/tb';

import completedLessonsEng from '../../../../assets/images/compl_eng.png';
import completedLessonsHrv from '../../../../assets/images/compl_hrv.png';
import icon1 from '../../../../assets/images/TEOREM_IKONE-01.jpg';
import ITutorItem, { getTutorShowcase, ITutorShowcase } from '../../../store/slices/tutor/tutorService';
import useWindowSize from '../../../utils/useWindowSize';
import { getHostName } from '../../../utils/utilFunctions';
import { ButtonPrimaryGradient } from '../components/ButtonPrimaryGradient';
import FAQGroup from '../components/FAQGroup';
import Footer from '../components/Footer';
import HeroSectionPricing from '../components/HeroSectionPricing';
import Navigation from '../components/Navigation';
import TextCard from '../components/TextCard';
import { TutorsContainer } from '../components/TutorsContainer';
import IFAQItem from '../interfaces/IFAQItem';
import icon2 from './../../../../assets/images/TEOREM_IKONE-02.jpg';
import icon3 from './../../../../assets/images/TEOREM_IKONE-03.jpg';
import icon6 from './../../../../assets/images/TEOREM_IKONE-06.jpg';
import icon8 from './../../../../assets/images/TEOREM_IKONE-08.jpg';
import { setRegistrationModalOpen } from '../../../store/slices/auth/authSlice';
import { useAppDispatch } from '../../../store/hooks';

const Pricing = () => {
    const dispatch = useAppDispatch();
    const [data, setData] = useState<ITutorShowcase[]>();
    const { i18n, t } = useTranslation();

    // Determine the image based on the current language
    const imageSrc = i18n.language === 'en' ? completedLessonsEng : completedLessonsHrv;

    const FAQContent: IFAQItem[] = [
        {
            id: 'faq-1',
            question: t('PRICING.FAQ.QUESTION_1'),
            answer: t('PRICING.FAQ.ANSWER_1'),
        },
        {
            id: 'faq-2',
            question: t('PRICING.FAQ.QUESTION_2'),
            answer: t('PRICING.FAQ.ANSWER_2'),
        },
        {
            id: 'faq-3',
            question: t('PRICING.FAQ.QUESTION_3'),
            answer: t('PRICING.FAQ.ANSWER_3'),
        },
        {
            id: 'faq-4',
            question: t('PRICING.FAQ.QUESTION_4'),
            answer: t('PRICING.FAQ.ANSWER_4'),
        },
    ];

    function fetch() {
        getTutorShowcase().then((data: ITutorShowcase[]) => {
            setData(data);
        });
    }

    useEffect(() => {
        fetch();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const isMobile = useWindowSize().width < 766;

    return (
        <>
            <Navigation />
            <div className="landing__hero--primary">
                <div className="landing">
                    <div className="landing__content">
                        <HeroSectionPricing title={t('PRICING.HERO.TITLE')} desc={t('PRICING.HERO.SUBTITLE')} showBtn={false} />
                    </div>
                </div>
            </div>
            <div className="landing">
                <div className="landing__content">
                    {data && (
                        <div className="landing__card--hero" style={{ paddingTop: '30px', padding: isMobile ? '0' : '80px' }}>
                            <TutorsContainer data={data} />
                        </div>
                    )}
                    <p className="landing__title">{t('PRICING.SUBTITLE_1')}</p>
                    <div className="mt-20 landing__steps__wrapper">
                        <div className="flex mb-20">
                            {/*{useWindowSize().width > 850 && <div className='landing__steps__item long'>1</div>}*/}
                            <div className="landing__steps__pricing__item ">1</div>
                            <div className="landing__steps__main" style={{ textAlign: 'start' }}>
                                <div className="type--lg type--wgt--bold mb-6">{t('PRICING.STEP_1.TITLE')}</div>
                                <div className="type--color--secondary type--md">{t('PRICING.STEP_1.SUBTITLE')}</div>
                            </div>
                        </div>
                        <div className="flex mb-20">
                            {/*{useWindowSize().width > 850 && <div className='landing__steps__item long'>2</div>}*/}
                            <div className="landing__steps__pricing__item">2</div>
                            <div className="landing__steps__main" style={{ textAlign: 'start' }}>
                                <div className="type--lg type--wgt--bold mb-6">{t('PRICING.STEP_2.TITLE')}</div>
                                <div className="type--color--secondary type--md">{t('PRICING.STEP_2.SUBTITLE')}</div>
                            </div>
                        </div>
                        <div className="flex">
                            {/*{useWindowSize().width > 850 && <div className='landing__steps__item long last'>3</div>}*/}
                            <div className="landing__steps__pricing__item last">3</div>
                            <div className="landing__steps__main" style={{ textAlign: 'start' }}>
                                <div className="type--lg type--wgt--bold mb-6">{t('PRICING.STEP_3.TITLE')}</div>
                                <div className="type--color--secondary type--md">{t('PRICING.STEP_3.SUBTITLE')}</div>
                            </div>
                        </div>
                    </div>

                    <div className="landing__section mt-20">
                        <a id="zapocni-danas-pricing-1" className="type--color--white" onClick={() => dispatch(setRegistrationModalOpen(true))}>
                            <ButtonPrimaryGradient className={'btn btn--l type--center type--md h--100'}>
                                {t('PRICING.BUTTON_1')}
                            </ButtonPrimaryGradient>
                        </a>
                        {/*<span className='d--b type--center mt-2 type--color--tertiary'>{t('DASHBOARD.COMING_SOON.TITLE')}</span>*/}
                    </div>

                    <p className="landing__title mb-20">{t('PRICING.SUBTITLE_4')}</p>

                    <p className={`landing__text-card__description type--color--secondary landing--fluid--sm type--center`}>
                        {t('PRICING.SUB_SUBTITLE_4')}
                    </p>

                    <div className="landing__section margin-text-mobile w--100">
                        <img src={imageSrc} alt="completed-lessons" className="landing__img" />
                    </div>

                    <p dangerouslySetInnerHTML={{ __html: t('PRICING.SUBTITLE_2') }} className="landing__title mb-20"></p>

                    <div className="row row--adaptive">
                        <div className="col col-6 col-md-6 col-lg-3">
                            <div className="landing__grid__card" style={{ height: '200px' }}>
                                <IoCardOutline className={`${isMobile ? 'mt-10' : ''}`} size={75} color={'#7e6cf2'} />
                                <div className="type--md type--wgt--bold mb-4">{t('PRICING.CARD_1.TITLE')}</div>
                            </div>
                        </div>
                        <div className="col col-6 col-md-6 col-lg-3">
                            <div className="landing__grid__card" style={{ height: '200px' }}>
                                <HiDocumentCheck className={`${isMobile ? 'mt-10' : ''}`} size={75} color={'#7e6cf2'} />
                                <div className="type--md type--wgt--bold mb-4">{t('PRICING.CARD_2.TITLE')}</div>
                            </div>
                        </div>
                        <div className="col col-6 col-md-6 col-lg-3">
                            <div className="landing__grid__card" style={{ height: '200px' }}>
                                <TbShieldLockFilled className={`${isMobile ? 'mt-10' : ''}`} size={75} color={'#7e6cf2'} />
                                <div className="type--md type--wgt--bold mb-4">{t('PRICING.CARD_3.TITLE')}</div>
                            </div>
                        </div>
                        <div className="col col-6 col-md-6 col-lg-3">
                            <div className="landing__grid__card" style={{ height: '200px' }}>
                                <BiSupport className={`${isMobile ? 'mt-10' : ''}`} size={75} color={'#7e6cf2'} />
                                <div className="type--md type--wgt--bold mb-4">{t('PRICING.CARD_4.TITLE')}</div>
                            </div>
                        </div>
                    </div>
                    <p className="landing__title__sm">{t('PRICING.SUBTITLE_3')}</p>
                    <FAQGroup data={FAQContent} />
                </div>
            </div>
            {/* FOOTER */}
            <Footer />
            {/* Content */}
        </>
    );
};

export default Pricing;
