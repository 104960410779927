import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import grid3 from '../../../../assets/images/grid3.jpg';
import grid4 from '../../../../assets/images/grid4.jpg';
import grid8 from '../../../../assets/images/grid8.jpg';
import grid10 from '../../../../assets/images/grid10.jpg';
import useWindowSize from '../../../utils/useWindowSize';
import { ButtonPrimaryGradient } from '../components/ButtonPrimaryGradient';
import Footer from '../components/Footer';
import HeroSectionP from '../components/HeroSection';
import Navigation from '../components/Navigation';
import TextCard from '../components/TextCard';
import { setRegistrationModalOpen } from '../../../store/slices/auth/authSlice';
import { useAppDispatch } from '../../../store/hooks';

const Tax = () => {
    const dispatch = useAppDispatch();

    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="tax">
                <Navigation />
                <div className="landing__hero--primary">
                    <div className="landing">
                        <div className="landing__content">
                            <HeroSectionP title={t('TAX.TITLE')} desc={t('TAX.SUBTITLE')} showBtn={false} />
                        </div>
                    </div>
                </div>
                <div className="landing">
                    <div className="landing__content">
                        <div className="landing__card--hero">
                            <TextCard title={t('TAX.BENEFITS.TITLE')} desc={''} />
                            <div className="row row--adaptive">
                                <div className="col col-12 col-md-6 col-lg-3">
                                    <div className="landing__grid__card">
                                        <img className="mb-10" src={grid8} alt="grid" style={{ height: '120px' }} />
                                        <div className="type--md type--wgt--bold mb-4">{t('TAX.BENEFITS.CREDIT_HISTORY')}</div>
                                    </div>
                                </div>
                                <div className="col col-12 col-md-6 col-lg-3">
                                    <div className="landing__grid__card">
                                        <img className="mb-10" src={grid3} alt="grid" style={{ height: '120px' }} />
                                        <div className="type--md type--wgt--bold mb-4">{t('TAX.BENEFITS.MORTGAGE')}</div>
                                    </div>
                                </div>
                                <div className="col col-12 col-md-6 col-lg-3">
                                    <div className="landing__grid__card">
                                        <img className="mb-10" src={grid4} alt="grid" style={{ height: '120px' }} />
                                        <div className="type--md type--wgt--bold mb-4">{t('TAX.BENEFITS.HEALTH')}</div>
                                    </div>
                                </div>
                                <div className="col col-12 col-md-6 col-lg-3">
                                    <div className="landing__grid__card">
                                        <img className="mb-10" src={grid10} alt="grid" style={{ height: '120px' }} />
                                        <div className="type--md type--wgt--bold mb-4">{t('TAX.BENEFITS.PENSION')}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h1 className={'mt-10'}>{t('TAX.TABLE.TITLE')}</h1>
                        <table>
                            <tr>
                                <th>{t('TAX.TABLE.INCOME_CLASS')}</th>
                                <th>{t('TAX.TABLE.ANNUAL_REPORTED_INCOME')}</th>
                                <th>{t('TAX.TABLE.ANNUAL_TAX_OBLIGATION')}</th>
                                <th>{t('TAX.TABLE.PERCENT_OF_INCOME')}</th>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>0.00 - 11,281.44</td>
                                <td>€ 199.68</td>
                                <td>1.77%</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>11,281.45 - 15,263.12</td>
                                <td>€ 270.16</td>
                                <td>1.77%</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>15,263.13 - 19,842.06</td>
                                <td>€ 351.20</td>
                                <td>1.77%</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>19,842.07 - 30,526.25</td>
                                <td>€ 540.31</td>
                                <td>1.77%</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>30,526.26 - 39,816.84</td>
                                <td>€ 704.76</td>
                                <td>1.77%</td>
                            </tr>
                        </table>
                        <p className={'mt-10'} style={{ fontSize: '17px' }}>
                            {t('TAX.TABLE.DISCLAIMER_1')}
                        </p>
                        <p style={{ fontSize: '17px' }}>{t('TAX.TABLE.DISCLAIMER_2')}</p>
                        <p className="landing__title">{t('TAX.STEPPER.TITLE')}</p>
                        <div className="mt-20 landing__steps__wrapper">
                            <div className="flex mb-20">
                                {useWindowSize().width > 850 && <div className="landing__steps__item long">1</div>}
                                <div className="landing__steps__main">
                                    <div className="type--lg type--wgt--bold mb-6" style={{ textAlign: 'left' }}>
                                        {t('TAX.STEPPER.STEP_1.TITLE')}
                                    </div>
                                    <div className="type--color--secondary type--md" style={{ textAlign: 'left' }}>
                                        {t('TAX.STEPPER.STEP_1.SUBTITLE')}
                                    </div>
                                </div>
                            </div>
                            <div className="flex mb-20">
                                {useWindowSize().width > 850 && <div className="landing__steps__item long">2</div>}
                                <div className="landing__steps__main">
                                    <div className="type--lg type--wgt--bold mb-6" style={{ textAlign: 'left' }}>
                                        {t('TAX.STEPPER.STEP_2.TITLE')}{' '}
                                        <a href={'https://e-obrt.gov.hr/'} target="_blank">
                                            e-obrt.gov.hr
                                        </a>
                                    </div>
                                    <div className="type--color--secondary type--md" style={{ textAlign: 'left' }}>
                                        {t('TAX.STEPPER.STEP_2.SUBTITLE')}
                                    </div>
                                </div>
                            </div>
                            <div className="flex mb-20">
                                {useWindowSize().width > 850 && <div className="landing__steps__item long">3</div>}
                                <div className="landing__steps__main">
                                    <div className="type--lg type--wgt--bold mb-6" style={{ textAlign: 'left' }}>
                                        {t('TAX.STEPPER.STEP_3.TITLE')}
                                    </div>
                                    <div className="type--color--secondary type--md" style={{ textAlign: 'left' }}>
                                        {t('TAX.STEPPER.STEP_3.SUBTITLE')}
                                    </div>
                                </div>
                            </div>
                            <div className="flex">
                                {useWindowSize().width > 850 && <div className="landing__steps__item long last">4</div>}
                                <div className="landing__steps__main">
                                    <div className="type--lg type--wgt--bold mb-6" style={{ textAlign: 'left' }}>
                                        {t('TAX.STEPPER.STEP_4.TITLE')}
                                    </div>
                                    <div className="type--color--secondary type--md" style={{ textAlign: 'left' }}>
                                        {t('TAX.STEPPER.STEP_4.SUBTITLE')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className={'mt-10'} style={{ fontSize: '18px' }}>
                            {' '}
                            {t('TAX.STEPPER.LAST_STEP_1')}{' '}
                            <a href={'https://plaviured.hr/vodici/10-koraka-kod-osnivanja-pausalnog-obrta/'} target="_blank">
                                {' '}
                                VODIČ PLAVOG UREDA{' '}
                            </a>{' '}
                            {t('TAX.STEPPER.LAST_STEP_2')}{' '}
                        </p>
                        <div className="mt-10 flex--primary">
                            <div className="flex">
                                <a onClick={() => dispatch(setRegistrationModalOpen(true))} className="type--color--white">
                                    <ButtonPrimaryGradient className={'btn btn--primary--landing ml-4 h--100'}>
                                        {t('LANDING.HEADER.GET_STARTED')}
                                    </ButtonPrimaryGradient>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Tax;
