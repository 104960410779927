import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import LandingWrapper from '../components/LandingWrapper';

const LegalCenter = () => {
    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <LandingWrapper>
            <div className="legal-center">
                <h1>{t('LEGAL_CENTER.TITLE')}</h1>
                <p>{t('LEGAL_CENTER.SUBTITLE')}</p>
                <br />
                <ul>
                    <li className="cur--pointer nav-link" onClick={() => history.push(t('PATHS.TERMS'))}>
                        {t('LANDING.FOOTER.LEGAL.LEGAL')}
                    </li>
                    <li className="cur--pointer nav-link" onClick={() => history.push(t('PATHS.PRIVACY'))}>
                        {t('LANDING.FOOTER.LEGAL.PRIVACY')}
                    </li>
                    <li className="cur--pointer nav-link" onClick={() => history.push(t('PATHS.TAX'))}>
                        {t('LEGAL_CENTER.TAX_TITLE')}
                    </li>
                    <li className="cur--pointer nav-link" onClick={() => history.push(t('PATHS.REFUND'))}>
                        {t('LANDING.FOOTER.LEGAL.REFUND')}
                    </li>
                    <li className="cur--pointer nav-link" onClick={() => history.push(t('PATHS.CONDUCT'))}>
                        {t('LANDING.FOOTER.LEGAL.CONDUCT')}
                    </li>
                </ul>
            </div>
        </LandingWrapper>
    );
};

export default LegalCenter;
