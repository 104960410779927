import { useEffect } from 'react';

import LandingWrapper from '../components/LandingWrapper';

const RefundPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <LandingWrapper>
            <div className="privacy">
                <h1>
                    <strong>Refund and Payment Policy</strong>
                </h1>
                <p>Your purchase of and payment for lessons/group classes shall be regulated by this Refund and Payment Policy.</p>
                <p>
                    PLEASE READ IT CAREFULLY BEFORE MAKING A PAYMENT AS IT DETERMINES YOUR RIGHTS WITH REGARD TO YOUR PURCHASES, INCLUDING ESSENTIAL
                    RESTRICTIONS AND EXCLUSIONS.
                </p>
                <p>
                    Teorem strives to ensure a clear understanding of financial relations between Students and Tutors with respect to the services we
                    provide. This policy applies to all services and features made available through the Website and any related Teorem mobile device
                    application (collectively, the “Teorem Marketplace”). Capitalized terms used and not defined herein shall have the respective
                    meanings ascribed to them in <a href={'/en/terms'}>Teorem Terms of Service</a>.
                </p>
                <h2>
                    <strong>Tutor-Student Transactions</strong>
                </h2>
                <p>
                    Teorem facilitates contracts between Users (Students and Tutors) by supplying a platform to request, deliver and pay for tutoring
                    services. Teorem is only a platform and in no event shall be a party to any contracts between Tutors and Students, or be
                    responsible for any other service or materials provided by a Tutor. Teorem will in no way be responsible for monitoring any
                    transactions between Students and Tutors.
                </p>
                <h2>
                    <strong>Billing Currency and Foreign Exchange Risk</strong>
                </h2>
                <p>
                    Payment transactions will be processed in one of the available currencies as shown at checkout. Currently, the currencies
                    available to make payments are EUR.
                </p>
                <p>
                    For your convenience prices may also be shown in your local currency other than EUR. In this case, the payment transactions will
                    be processed in EUR. The prices you see in currencies other than EUR are indicative (for guidance only).
                </p>
                <p>
                    Note that if your selected currency is (a) other than EUR, or (b) different from your payment method’s billing currency, your
                    payment may be processed outside of your country of residence. As a result, certain fees may apply, such as international
                    transaction fees and foreign exchange fees, and the amount listed on your bank statement may be different from the amount shown at
                    checkout.
                </p>
                <p>
                    Teorem shall not be responsible for any such fees and currency fluctuations and Teorem hereby disclaims all liability in that
                    regard.
                </p>
                <p>
                    We suggest you contact your bank or credit card company if you have any questions about the fees or the applicable exchange rates.
                </p>
                <h2>Teorem Commission</h2>
                <p>
                    We charge a service fee to the Tutors for the use of our services (“Teorem Commission”). Teorem Commission is charged on each
                    lesson conducted by the Tutor at the rate indicated on the Teorem Marketplace. Teorem reserves the right to change Teorem
                    Commission at any time and will notify the Tutors of any fee changes before they become effective.
                </p>
                <h2>Refunds</h2>
                <p>
                    You are required to send your refund request to <a href={'mailto: support@teorem.co'}>support@teorem.co</a>.
                </p>
                <p>
                    If there are extenuating circumstances that caused you to miss the lesson or cancel it at short notice, these refunds can be made
                    at the Tutor’s discretion, but the Tutor is not obligated to do so.
                </p>
                <p>
                    Teorem is not responsible for any kind of fee charged by banks or third-party payment processors, and will not cover it when
                    initiating a refund.
                </p>
                <p>
                    In case you choose a refund option, its speed and the availability of refunded funds on your bank account will depend solely on
                    the terms and policies of your bank. The exact refund amount will depend on the EUR to your payment method’s currency exchange
                    rate valid at the date the refund is initiated.
                </p>
                <p>You may receive only one refund per request.</p>
                <p>
                    No refund will be issued if the User has lost and/or forgotten their login credentials (username and passwords), which has
                    resulted in the loss of data, temporary loss of access to the User's account, or any other inconveniences for the User.
                </p>
                <p>No refund will be issued in case the User has lost connection due to the absence of Internet access, factory reset, or updates.</p>
                <p>
                    In case you failed to submit a refund request within the stated period, you can still request us to return the credits to your
                    Teorem account balance. Such credits may subsequently be used to schedule lessons with any other Tutor.
                </p>
                <h2>Refund Procedure</h2>
                <p>
                    To request a refund, please email <a href={'mailto: support@teorem.co'}>support@teorem.co</a> with the following information:
                </p>
                <ul>
                    <li>Date and time of the lesson</li>
                    <li>Reason for requested refund</li>
                    <li>Name of your Tutor</li>
                </ul>
                <p>The refund decision shall be made within 30 calendar days.</p>
                <p>To the fullest extent permitted by law, any refunds at any time are at our sole discretion.</p>
                <h2>Chargebacks</h2>
                <p>
                    In the event of a chargeback by banks or third-party payment processors in connection with your purchase of any lesson, you agree
                    that we may suspend access to any and all accounts you have with us.
                </p>
                <p>
                    Fees and expenses, incurred as the result of chargebacks or other payment disputes brought by you, your bank, or a third-party
                    payment processor, shall be covered by you.
                </p>
                <h2>Expired Account</h2>
                <p>
                    In case you haven’t logged into your Teorem account for more than 180 days, your account will be suspended and your remaining
                    balance will expire.
                </p>
                <h2>Deletion of an Account</h2>
                <p>If you decide to delete your Teorem account, your remaining balance will expire.</p>
                <p>
                    In case Teorem suspends or terminates your account due to the breach of any of our policies, you understand and agree that you
                    shall receive no refund or compensation for any unused balance or scheduled lessons.
                </p>
                <h2>Payment Services</h2>
                <p>
                    Payment processing on the Teorem Marketplace is provided by third-party payment processors including, but not limited to
                    Braintree, Stripe, PayPal, Skrill, Payoneer, and Wise, allowing us to (a) bill the Students in lieu of directly processing the
                    credit/debit card information; and (b) enable payouts to the Tutors.
                </p>
                <p>
                    For the avoidance of doubt, payment made by the Student to Teorem shall satisfy the Student’s obligation with respect to the
                    payment to the Tutor for the tutoring services provided via the Teorem Marketplace.
                </p>
                <p>
                    We use an integrated iframe for checkouts from third-party payment processors, and Tutors get payment for each confirmed lesson.
                    Correspondingly all transactions are secured as third-party payment processors use SSL encryption protection.
                </p>
                <h2>Data Provided by You</h2>
                <p>
                    You agree and understand that the billing information, which you provide to make payment for lessons via the Teorem Marketplace,
                    is processed by third-party payment processors only. Teorem does not collect, store or otherwise process your billing information.
                </p>
                <p>
                    You agree that Teorem will not be responsible for any failures of the third party to protect such information adequately. The
                    processing of payments will be subject to the terms, conditions, and privacy policies of third-party payment processors in
                    addition to this policy.
                </p>
                <p>
                    You acknowledge that we may change third-party payment processors and require them to transfer your information to other service
                    providers that encrypt your information using secure socket layer technology (SSL) or other comparable security technology.
                </p>
                <h2>Payments</h2>
                <p>Payment methods available on the Teorem Marketplace are as follows:</p>
                <ul>
                    <li>Visa/Mastercard</li>
                    <li>PayPal</li>
                    <li>Apple Pay</li>
                    <li>Google Pay</li>
                </ul>
                <p>All payments for the use of the Teorem Marketplace and the tutoring services shall be made through the Teorem Marketplace.</p>
                <p>
                    Students are liable for all transaction fees related to the payments for the use of the Teorem Marketplace and the tutoring
                    services.
                </p>
                <h2>Payouts</h2>
                <p>
                    Tutors get payments for each confirmed lesson. Payouts are distributed automatically to Tutors 24 hours after the lesson. Payout
                    for your first lesson will be distributed up to 2 weeks after completing the lesson.
                </p>
                <p>
                    Please note that third-party payment processors charge a fee for every transaction. Such transaction fees shall be deducted from
                    the respective payout amount and will be displayed within the Teorem Marketplace functionality.
                </p>
                <h2>Auto Refills</h2>
                <p>
                    When you purchase a package of hours on the Teorem Marketplace you may be charged on a recurring basis (“Auto Refill”). When you
                    have one or fewer hours left with the Tutor, the same package of hours as previously bought by you will be added to your account
                    balance by charging your saved payment method.
                </p>
                <p>
                    Auto Refill may be canceled at any time. Auto Refill settings may also be changed. You may choose the number of hours you wish to
                    be automatically refilled for each Tutor by changing these settings in the “Payment methods” section of your account settings.
                </p>
                <h2>Other</h2>
                <p>
                    Teorem is not a bank or any type of financial institution and does not provide any financial services or advice. Any payments made
                    via the Teorem Marketplace are made solely for the purpose of access, facilitation, and delivery of the tutoring services between
                    Students and Tutors. Such payments may not be made and used for any fraudulent, criminal or illegal activity as defined by the
                    law.
                </p>
            </div>
        </LandingWrapper>
    );
};

export default RefundPolicy;
