import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import LOCAL_STORAGE_KEYS from '../../../constants/localStorageKeys';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setSelectedLang } from '../../../store/slices/lang/langSlice';

interface ILocaleContextValue {
    initLang: (onLangChange: (lang: string) => void) => Promise<void>;
    langModalOpen: boolean;
    setLangModalOpen: (v: boolean) => void;
}

const LocaleContext = createContext<ILocaleContextValue>({} as ILocaleContextValue);

interface ILocaleProviderProps {
    children: ReactNode;
}

export default function LocaleProvider({ children }: Readonly<ILocaleProviderProps>) {
    const { i18n } = useTranslation();
    const [localeModalOpen, setLocaleModalOpen] = useState(false);
    const { selectedLanguage } = useAppSelector((state) => state.lang);
    const [onSelect, setOnSelect] = useState<(l: string) => void>(() => () => null);
    const dispatch = useAppDispatch();

    const initLang = useCallback(
        async (onLangChange: (lang: string) => void) => {
            setOnSelect(() => (l: string) => {
                onLangChange(l.toLowerCase());
            });

            try {
                const preferredLanguage = localStorage.getItem(LOCAL_STORAGE_KEYS.PREFERRED_LANGUAGE);
                const preferredCountry = localStorage.getItem(LOCAL_STORAGE_KEYS.PREFERRED_COUNTRY);
                if (preferredCountry && preferredLanguage) {
                    try {
                        return onLangChange(JSON.parse(preferredLanguage).abrv);
                    } catch (e) {
                        dispatch(setSelectedLang({ id: '', abrv: 'EN', name: 'English' }));
                    }
                }

                setLocaleModalOpen(true);

                const html = document.getElementsByTagName('html')[0];
                if (html) {
                    html.style.overflow = 'hidden';
                }

                dispatch(setSelectedLang({ id: '', abrv: 'en', name: 'English' }));
                return;
            } catch (error) {
                setLocaleModalOpen(true);
                dispatch(setSelectedLang({ id: '', abrv: 'EN', name: 'English' }));
                onLangChange('en');
            }
        },
        [dispatch]
    );

    useEffect(() => {
        if (selectedLanguage) {
            console.log('selectedLanguage', selectedLanguage);
            i18n.changeLanguage(selectedLanguage.abrv.toLowerCase());
            onSelect(selectedLanguage.abrv.toLowerCase());
        }
    }, [selectedLanguage, i18n, onSelect]);

    const value = useMemo<ILocaleContextValue>(
        () => ({
            initLang,
            onSelect,
            langModalOpen: localeModalOpen,
            setLangModalOpen: setLocaleModalOpen,
        }),
        [initLang, onSelect, localeModalOpen, setLocaleModalOpen]
    );

    return <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>;
}

export function useLocaleContext() {
    const context = useContext(LocaleContext);

    if (!context) {
        throw new Error('useLocaleContext must be used within a LocaleProvider');
    }

    return context;
}
