import { useEffect } from 'react';

import LandingWrapper from '../components/LandingWrapper';

const Conduct_HR = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <LandingWrapper>
            <div className="privacy">
                <h1>
                    <strong>Kodeks ponašanja korisnika</strong>
                </h1>
                <p>
                    Korištenjem Teorem Marketplacea, ovime potvrđujete svoje razumijevanje ovog korisničkog kodeksa ponašanja i prihvaćate sljedeće:
                </p>
                <ul>
                    a) Pridržavati se svih primjenjivih zakona, uključujući, bez ograničenja, zakone o privatnosti, zakone o intelektualnom
                    vlasništvu, zakone protiv spama, porezne zakone itd.;
                </ul>
                <ul>b) Pružiti točne informacije Teoremu i ažurirati ih;</ul>
                <ul>c) Koristiti Usluge i Teorem Marketplace na način koji je zakonit, relevantan i primjeren primjenjivim zakonima;</ul>
                <ul>d) Slijediti standarde ponašanja kako su ovdje navedeni.</ul>
                <p>
                    Svako korištenje Teorem Marketplacea koje Teorem, prema vlastitom nahođenju, smatra neprikladnim i/ili uvredljivim
                    <u>može rezultirati obustavom i/ili ukidanjem korisničkog računa sa ili bez obavijesti</u>.
                </p>
                <p>
                    Suglasni ste da ćete se pridržavati sljedećih standarda ponašanja i slažete se da nećete odobriti ili omogućiti pokušaj druge
                    osobe da:
                </p>
                <ul>1. Pruža ili prihvaća usluge podučavanja od bilo kojeg korisnika Teorema zaobilazeći Teorem Marketplace;</ul>
                <ul>
                    2.{' '}
                    <u>
                        Izravno prima uplate od Studenta ili potiče ili traži plaćanje od Studenta izravno ili putem bilo kojih kanala osim onih koji
                        su dostupni putem Teorem Marketplacea, s ciljem zaobilaženja Teorem Marketplacea ili izbjegavanja plaćanja naknada koje se
                        duguju Teoremu
                    </u>
                    ;
                </ul>
                <ul>3. Manipulira cijenom bilo koje stavke ponuđene unutar Usluga ili ometa oglase drugih Korisnika;</ul>
                <ul>4. Ometa ili na bilo koji drugi način ometa korištenje Usluga bilo kojeg drugog Korisnika;</ul>
                <ul>
                    5. Prenosi bilo koji sadržaj koji je stvorio korisnik koji je nezakonit, štetan, prijeteći, uvredljiv, uznemirujući, klevetnički,
                    vulgaran, uvredljiv, opscen, pornografski, razvratan, lascivan ili na drugi način nepoželjan, kako je određeno Teoremom;
                </ul>
                <ul>
                    6. Zaobilazi ili manipulira strukturom naknada, postupkom naplate ili naknadama koje se duguju Teoremu ili Instruktoru; ne
                    isporučuju plaćanje za artikle koje ste kupili osim ako je Instruktor bitno promijenio usluge podučavanja, napravljena je jasna
                    tipografska pogreška ili ne možete doći do Instruktora;
                </ul>
                <ul>7. Ne isporučiti usluge podučavanja kupljene od vas osim ako je takav neuspjeh uzrokovao Student.</ul>
                <ul>8. Koriste naziv ili jezik koji Teorem, prema vlastitom nahođenju, smatra uvredljivim;</ul>
                <ul>9. Objavljuju lažne, netočne, obmanjujuće, klevetničke ili klevetničke informacije (uključujući osobne podatke);</ul>
                <ul>10. Objavljuju mržnju ili rasno ili etnički nepoželjan sadržaj koji su stvorili korisnici;</ul>
                <ul>11. Objavljuju korisnički generirani sadržaj koji krši tuđa autorska prava, zaštitni znak ili poslovnu tajnu;</ul>
                <ul>
                    12. Otkriju sve povjerljive informacije ili osobne podatke dobivene od drugog korisnika ili tijekom vašeg korištenja Teorem
                    Marketplacea;
                </ul>
                <ul>
                    13. Oglašavaju ili nude prodaju bilo koje robe ili usluga u bilo koju komercijalnu svrhu putem Teorem Marketplacea koji nisu
                    relevantni za usluge podučavanja koje se nude putem Teorem Marketplacea;
                </ul>
                <ul>14. Poduzmu bilo kakvu radnju koja bi mogla potkopati povratne informacije ili sustav ocjenjivanja kada postane dostupan;</ul>
                <ul>
                    15. <u>Uznemiruju, prijete ili namjerno dovode u neugodnu situaciju ili uzrokuju nevolju drugom korisniku, osobi ili entitetu</u>;
                </ul>
                <ul>
                    16. Koriste osobne podatke druge osobe (kao što je ime, adresa e-pošte, identifikacijski broj ili broj kreditne kartice)
                    pretvarajući se da ste ta osoba (imitirati drugu osobu);
                </ul>
                <ul>17. Promiču, traže ili sudjeluju u bilo kakvom višerazinskom marketingu ili piramidalnim shemama;</ul>
                <ul>18. Iskorištavaju djecu mlađu od 18 godina;</ul>
                <ul>19. Uključuju se u ometajuće aktivnosti kao što je slanje više poruka u nastojanju da monopoliziraju svoj položaj;</ul>
                <ul>20. Prilagođavaju, mijenjaju, licenciraju, podlicenciraju ili prevode uslugu za svoju osobnu ili komercijalnu upotrebu;</ul>
                <ul>21. Uvode viruse, crve, trojanske konje i/ili štetni kod na Teorem Marketplace;</ul>
                <ul>22. Dobiju neovlašteni pristup bilo kojem računalnom sustavu putem Teorem Marketplacea;</ul>
                <ul>23. Prenose Teorem račun (uključujući povratne informacije) i vjerodajnice za prijavu na drugu stranu bez pristanka Teorema;</ul>
                <ul>24. Sakupljaju ili na drugi način prikupljaju podatke o Korisnicima, uključujući adrese e-pošte, bez njihovog pristanka;</ul>
                <ul>
                    25. Narušavaju privatnosti bilo koje osobe, uključujući, ali ne ograničavajući se na objavljivanje osobnih ili drugih privatnih
                    informacija o osobi bez njezinog pristanka (ili pristanka roditelja u slučaju djeteta mlađeg od 18 godina);
                </ul>
                <ul>26. Traže osobne podatke od djece mlađe od 18 godina;</ul>
                <ul>27. Krše bilo koji federalni, državni, lokalni ili međunarodni zakon ili propis;</ul>
                <ul>28. Potiču ponašanje koje bi predstavljalo kazneno ili građansko djelo;</ul>
                <ul>
                    29. Se uključuju ili promiču nezakonitu trgovinu ljudima, uključujući nabavu komercijalnih seksualnih radnji i korištenje
                    prisilnog rada u pružanju usluga podučavanja.
                </ul>
                <ul>30. Koriste Usluge u svrhu varanja umjesto olakšavanja učenja;</ul>
                <ul>
                    31. Se raspituju o angažiranju ili najmu Instruktora da dovrše zadatke, napišu radove, rješavaju kvizove ili na neki drugi način
                    obavljaju posao u tuđe ime;
                </ul>
                <ul>
                    32. Koriste usluge u bilo koju svrhu koja krši politiku akademskog poštenja ili drugu politiku ponašanja vaše škole, sveučilišta,
                    akademske ustanove ili radnog mjesta;
                </ul>
                <ul>
                    33. Prodaju, preprodaju, licenciraju, podlicenciraju, distribuiraju, iznajmljuju, ili na neki drugi način omogućuju pristup
                    Uslugama bilo kojoj trećoj strani osim u mjeri u kojoj Teorem izričito pisano ovlasti;
                </ul>
                <ul>34. Zakazuju nastavu bez znanja i potvrde Studenta s ciljem isplate.</ul>
            </div>
        </LandingWrapper>
    );
};

export default Conduct_HR;
